import { Components } from '@ionic/core'
import { isPlatform } from '@ionic/vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

export const isBool = (val: unknown): val is boolean => typeof val === 'boolean'
export const isNumber = (val: unknown): val is number => typeof val === 'number'
export const isString = (val: unknown): val is string => typeof val === 'string'
export const isObject = (val: unknown): val is Record<any, any> =>
  val !== null && typeof val === 'object'


export function getOptionArray(map: Map<string | number, string>) {
  const options = []
  for (const mapElement of map) {
    options.push({
      value: mapElement[0],
      label: mapElement[1],
    })
  }
  return options
}

export function getTimeTips(stamp: number) {
  // eslint-disable-next-line import/no-named-as-default-member
  dayjs.extend(duration)
  const now = Date.now()
  const durationTime = dayjs.duration(now - stamp)

  const asSeconds = durationTime.asSeconds()
  const asMinutes = durationTime.asMinutes()
  const asHours = durationTime.asHours()
  const asDays = durationTime.asDays()
  const asYears = durationTime.asYears()

  if (asSeconds < 60) {
    return `${Math.ceil(asSeconds)}s ago`
  }
  else if (asMinutes < 60) {
    return `${Math.ceil(asMinutes)}m ago`
  }
  else if (asHours < 24) {
    return `${Math.ceil(asHours)}h ago`
  }
  else if (asDays < 3) {
    return `${Math.ceil(asDays)}d ago`
  }
  else if (asYears < 1) {
    return dayjs(stamp).format('MM/DD')
  }
  else {
    return dayjs(stamp).format('MM/DD/YYYY')
  }
}

export function sleep(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

export function openNativeApp() {
  let deeplink
  if (isPlatform('android')) {
    deeplink = `befruitful://${process.env.VUE_APP_APPLINKS_DOMAIN}${process.env.VUE_APP_DEEPLINK_PREFIXPATH}${window.location.search}`
  }
  else {
    deeplink = `https://${process.env.VUE_APP_APPLINKS_DOMAIN}${process.env.VUE_APP_DEEPLINK_PREFIXPATH}${window.location.search}`
  }
  window.location.replace(deeplink)
}

export function openAppStore() {
  let downloadUrl = ''

  const platform = getMobileOperatingSystem()
  if (platform === 'iOS') {
    // do something in iOS
    downloadUrl = 'https://apps.apple.com/app/prayrooms/id1612419544'
  }
  else {
    downloadUrl = `https://play.google.com/store/apps/details?id=${process.env.VUE_APP_PACKAGE_NAME}`
  }

  window.location.replace(downloadUrl)
}

// 受到 ion-content 的影响, dom 的scrollIntoView 在 ios 上没有 smooth 效果, 即便使用第三方库(smoothscroll-polyfill\seamless-scroll-polyfill)也不行
// 连续push页面后, document 中存在多个 ion-content
// 最后一个才是激活的
interface HTMLIonContentElement extends Components.IonContent, HTMLElement {}
function getContent(): HTMLIonContentElement {
  const list = document.querySelectorAll('ion-content')
  return list[list.length - 1]
}

type Block = 'start' | 'center' | 'bottom'
export function scrollIntoView(ele: HTMLElement, block: Block = 'center', duration = 300, offset = 0) {
  const ionContent = getContent()

  let y: number
  switch (block) {
  case 'start':
    y = ele.offsetTop
    break
  case 'center':
    y = ele.offsetTop - ((ionContent.clientHeight - ele.clientHeight) / 2)
    break
  case 'bottom':
    y = ele.offsetTop - (ionContent.clientHeight - ele.clientHeight)
  }
  ionContent.scrollToPoint(0, y + offset, duration)
}