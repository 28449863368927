import html2canvas from 'html2canvas'

/**
 * 2020/7/21
 * 新增对视频的截图下载和获取base64地址
 * @param {boolean} isVideo
 */
export class ScreenShot {
  constructor(elm, fileType = 'png', options = {}, isVideo = false) {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (isVideo) {
      canvas.width = elm.offsetWidth
      canvas.height = elm.offsetHeight
      ctx.drawImage(elm, 0, 0, elm.offsetWidth, elm.offsetHeight)
      this.videoUrl = canvas.toDataURL()
      elm.style.backgroundImage = `url(${this.videoUrl})`
    }
    this.isSupport = !!ctx && !!ctx.getImageData && !!canvas.toDataURL
    this.elm = elm
    this.isInit = false
    this.fileType = fileType
    html2canvas(elm, options).then(cvs => {
      this.shotCanvas = cvs
      this.isInit = true
    })
  }

  waitInit() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.isInit) {
          resolve()
          clearInterval(interval)
        }
      }, 500)
    })
  }

  /**
     * @param {any} type, support png， jpeg, gif, bmp
     * @returns
     */
  convertToImage() {
    if (!this.isSupport) {
      console.error('您的浏览器暂不支持此方法')
      return ''
    }
    const strData = this.getDataURL()
    return ScreenShot.genImage(strData)
  }

  saveAs(filename) {
    return new Promise((resolve) => {
      const id = setInterval(() => {
        if (this.isInit) {
          const link = document.createElement('a')
          link.download = `${filename}.${this.fileType}`
          link.href = this.convertToImage().src
          link.click()
          clearInterval(id)
          resolve()
        }
      }, 500)
    })
  }


  getDataURL() {
    const fixType = ScreenShot.fixType(this.fileType)
    return this.shotCanvas.toDataURL(fixType)
  }

  // 获取视频截图的base64
  getVideoDataURL() {
    return this.videoUrl
  }

  static genImage(strData) {
    const img = document.createElement('img')
    img.src = strData
    return img
  }

  static fixType(type) {
    const fixed = type.toLowerCase().replace(/jpg/i, 'jpeg')
    return `image/${fixed.match(/png|jpeg|bmp|gif/)[0]}`
  }
}