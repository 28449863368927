export enum UseOperationType {
    COPY = 'Copy',
    PRAY_AGAIN = 'Pray again',
    MARK_AS_ANSWERED = 'Mark as Answered',
    DELETE = 'Delete',
    REPORT_PRAYER = 'Report Prayer',
    REPORT_USER = 'Report User',
    REMOVE_FROM_PUBLIC = 'Remove from Public',
    BLOCK = 'Block',
    UN_BLOCK = 'UnBlock',
}