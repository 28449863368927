import {SQLiteDBConnection} from '@capacitor-community/sqlite'
import {capSQLiteSet} from '@capacitor-community/sqlite/dist/esm/definitions'
import {useEventBus} from '@vueuse/core'
import {pick} from 'lodash'
import {onUnmounted, reactive} from 'vue'

import {openDB, saveDB} from '../database'

import {DBAdapter, DBEvent, DBLimit, DBOrder} from './index.type'
import {SQLStatement} from './sql-statement'

function sleep(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export function useDBAdapter<T>(
  tableName: string,
  condition?: Partial<T> | string
): DBAdapter<T> {
  const dataList = reactive<T[]>([])
  const busIdentifier = Math.random().toString()

  // 一个 dbAdapter 对应一个查询条件
  let queryCondition: Partial<T> | string | undefined = condition

  interface BusEvent {
    event: DBEvent
    identifier: string
    values: Partial<T>[]
  }

  const bus = useEventBus<BusEvent>(tableName)
  let modifierFunc: (data: T) => T

  let db: SQLiteDBConnection
  openDB().then((res) => db = res)

  const waitDB = async (time = 0, stop?: boolean): Promise<void> => {
    if (!db && stop) {
      throw new Error('db 初始化超时')
    }
    if (!db) {
      await sleep(time)
      return waitDB(time + 1, time > 100)
    }
  }

  const query = async (
    select: Array<string> | '*',
    order?: DBOrder<T>,
    limit?: DBLimit
  ): Promise<T[]> => {
    await waitDB()
    const sqlStatement = new SQLStatement<T>(tableName)
    const statement = sqlStatement
      .query(select)
      .condition(queryCondition)
      .order(order)
      .limit(limit)
      .value()

    const res = await db.query(statement)
    let values = res.values || []
    if (modifierFunc) {
      values = values.map(item => modifierFunc(item))
    }
    if (limit?.offset && limit?.offset > 0) {
      dataList.push(...values)
    } else {
      dataList.splice(0, dataList.length)
      dataList.push(...values)
    }
    return values
  }

  const setDataModifier = (func: (data: T) => T) => {
    modifierFunc = func
  }

  const insert = async (obj: T): Promise<void> => {
    await waitDB()
    const sqlStatement = new SQLStatement<T>(tableName)
    const statement = sqlStatement.insert(obj).value()

    await db.execute(statement)
    bus.emit({
      event: 'insert',
      identifier: busIdentifier,
      values: [obj],
    })
  }

  const insertSet = async (objs: T[]): Promise<void> => {
    if (objs.length === 0) return
    await waitDB()
    const statements = []
    const sqlStatement = new SQLStatement<T>(tableName)
    for (const obj of objs) {
      sqlStatement.insert(obj, true)
      statements.push({
        statement: sqlStatement.value(),
        values: sqlStatement.values,
      })
    }

    await db.executeSet(statements)
    bus.emit({
      event: 'insertSet',
      identifier: busIdentifier,
      values: objs,
    })
  }

  const upsertSet = async (objs: Partial<T>[], identifier: string[]): Promise<void> => {
    if (objs.length === 0) return
    await waitDB()
    const statements: capSQLiteSet[] = []
    for (const obj of objs) {
      const sqlStatement = new SQLStatement<T>(tableName)
      sqlStatement.upsertSet(obj, identifier)
      statements.push({
        statement: sqlStatement.value(),
        values: sqlStatement.values,
      })
    }
    await db.executeSet(statements)
    bus.emit({
      event: 'upsertSet',
      identifier: busIdentifier,
      values: objs,
    })
  }

  const update = async (
    condition: Partial<T> | string,
    changes: Partial<T>
  ): Promise<void> => {
    await waitDB()
    const sqlStatement = new SQLStatement<T>(tableName)
    const statement = sqlStatement.update(changes).condition(condition).value()
    await db.execute(statement)

    const obj = {}
    if (typeof condition !== 'string') {
      Object.assign(obj, condition)
    }
    bus.emit({
      event: 'update',
      identifier: busIdentifier,
      values: [{
        ...changes,
        ...obj,
      }],
    })
  }

  const deleteOne = async (condition: Partial<T> | string): Promise<void> => {
    await waitDB()
    const sqlStatement = new SQLStatement<T>(tableName)
    const statement = sqlStatement.delete().condition(condition).value()
    await db.execute(statement)

    const obj = {}
    if (typeof condition !== 'string') {
      Object.assign(obj, condition)
    }
    bus.emit({
      event: 'delete',
      identifier: busIdentifier,
      values: [obj],
    })
  }

  // 不匹配 condition 的变更丢弃, 免得触发不必要的事件
  function getChangesCareAbout(values: Partial<T>[]): Partial<T>[] {
    if (!queryCondition || typeof queryCondition === 'string') return values
    return values.filter(value => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return Object.entries(queryCondition!).every(([key, val]) => value[key as keyof T] === val)
    })
  }

  const listenerList: ((event: BusEvent) => void)[] = []
  const watch = (listener: (event: DBEvent, values: Partial<T>[]) => void) => {
    const callback = (event: BusEvent) => {
      // 自己触发的事件, 不能处理, 否则陷入无限循环
      if (event.identifier === busIdentifier) return
      // 不匹配 condition 的变更丢弃
      // const values = getChangesCareAbout(event.values)
      // console.log(values)
      // if (values.length === 0) return
      listener(event.event, event.values)
    }
    listenerList.push(callback)
    bus.on(callback)

    const unwatch = () => {
      bus.off(callback)
    }
    return unwatch
  }

  const store = () => {
    return saveDB()
  }

  const setCondition = (condition: Partial<T> | string) => {
    queryCondition = condition
  }

  onUnmounted(() => {
    if (listenerList.length > 0) {
      listenerList.forEach(listener => {
        bus.off(listener)
      })
    }
  })

  return {
    setCondition,
    getChangesCareAbout,
    dataList,
    query,
    setDataModifier,
    insert,
    insertSet,
    update,
    upsertSet,
    deleteOne,
    watch,
    store,
  }
}
