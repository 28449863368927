import type {
  CreatePrayerGroupReq,
  PrayerGroupDetailParams,
  PrayerGroupInvitationApiReq,
  PrayerGroupInvitationEmailReq,
  PrayerGroupInvitationTokenReq,
  PrayerGroupInvitationTokenRes,
  PrayerGroupListParams,
  PrayerGroupListResult
} from '@/apis/model/prayer-group-model'
import {
  DeletePrayerGroupApiReq,
  EditPrayerGroupApiReq,
  RemovePrayerGroupMemberApiReq
} from '@/apis/model/prayer-group-model'
import {useI18n} from '@/i18n/use-i18n'
import {GroupType} from '@/protobuf/pg'
import {Group} from '@/stores/database/database-schema-v1'
import {http} from '@/utils/http'

const {t} = useI18n()


export const createPrayerGroupApi = async (data: CreatePrayerGroupReq) => http.post<Group>({
  url: '/group/create',
  data,
})

export const getPrayerGroupListApi = (params?: PrayerGroupListParams) => http.get<PrayerGroupListResult>({
  url: '/group/list',
  params,
})

export const getPrayerGroupDetailApi = (params?: PrayerGroupDetailParams) => http.get<Group>({
  url: '/group/detail',
  params,
})

export const getPrayerGroupInvitationCodeApi = (data: PrayerGroupInvitationTokenReq) => http.post<PrayerGroupInvitationTokenRes>({
  url: '/group/invitation/url',
  data,
})
export const getPrayerGroupInvitationDetailApi = (params: { groupId: string }) => http.get({
  url: '/group/invitation/detail',
  params,
})

export const sendPrayerGroupInvitationEmailApi = (data: PrayerGroupInvitationEmailReq) => http.post({
  url: '/group/invitation/email/send',
  data,
})


export const getPublicPrayerGroup = (): Promise<Group> => {
  return Promise.resolve({
    groupId: 'publicGroupId',
    groupName: t('pageContent.prayerGroup.publicAnsweredGroupName'),
    groupAvatar: 'https://befruitful.goodnewslabs.com/public-group-avatar.png',
    groupType: GroupType.GROUP_TYPE_PUBLIC,
    description: t('pageContent.prayerGroup.publicAnsweredGroupDes'),
    creator: t('pageContent.prayerGroup.publicAnsweredGroupCreator'),
    creatorUin: 'system',
    createTime: 0,
  })
}


export const deletePrayerGroupApi = (data: DeletePrayerGroupApiReq) => http.post({
  url: '/group/delete',
  data,
})

export const editPrayerGroupApi = (data: EditPrayerGroupApiReq) => http.post({
  url: '/group/edit',
  data,
})

export const removePrayerGroupMembersApi = (data: RemovePrayerGroupMemberApiReq) => http.post({
  url: '/group/user/remove',
  data,
})

export const PrayerGroupInvitationApi = (data: PrayerGroupInvitationApiReq) => http.post<{groupId: string}>({
  url: '/group/invitation',
  data,
})
export const getGroupManagerCountApi = () => http.get({
  url: '/group/manager/count',
})

export const clearGroupNewInfoTipsApi = (data: { groupId: string }) => http.post({
  url: '/group/update/clear',
  data,
})






