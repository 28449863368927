export enum LaunchCommandType {
  PrayerShare = 0,
  PrayerGroupInvitation = 1
}

export interface LaunchCommand<T> {
  launchType: LaunchCommandType
  launchParams: T
}

export interface InvitationParams {
  token: string
  groupId: string
  uin: string
  name: string
}

export interface PrayerShareParams {
  token: string
  prayerTitle: string
  prayerRoom?: string
}