export enum Start {
    appName = 'PrayRooms',
    title1 = 'But when you ',
    title2 = 'pray, go into ',
    title3 = 'your room, close ',
    title4 = 'the door and ',
    title5 = 'pray to your ',
    title6 = 'Father',
    tips = 'Matthew 6:6 NIV',
    footerTips1 = '注册即表示已同意',
    footerTips2 = '查看',
    footerTips3 = '关于您的',
}