import {useEventBus} from '@vueuse/core'

import { pushRefreshTokenApi } from '@/apis/push'
import {AccountState} from '@/protobuf/pg'
import {useAccountStore} from '@/stores/account'
import {Notice} from '@/stores/database/database-schema-v1'
import {useNoticeProcessor} from '@/uses/notice-processor/use-notice-processor'
const {accountInfo} = useAccountStore()
export const handleToken = async (token: string) => {
  if(accountInfo.value?.state === AccountState.VERIFIED || accountInfo.value?.state === AccountState.NORMAL || accountInfo.value?.state === AccountState.LATTER) {
    await pushRefreshTokenApi({
      pushToken: token,
    })
  }
}

// 被用户点击之后的通知
export const handleClickedNotice = (notice: Notice) => {
  const noticeProcessor = useNoticeProcessor({notice})
  noticeProcessor.onClick && noticeProcessor.onClick()
}

// 收到了通知
export const handleReceivedNotice = (notice: Notice) => {
  const eventBus = useEventBus<string>('PUSH_NOTIFICATION')
  eventBus.emit('received')
}