import { ComponentProps, NavComponent, NavOptions, TransitionDoneFn } from '@ionic/core'
import { Components } from '@ionic/core/dist/types/components.d'
import { Ref, ref, watch } from 'vue'

const ionNav: Ref<Components.IonNav | null> = ref(null)

export function useNav() {
  const setIonNavInstance = (instance: Components.IonNav) => {
    ionNav.value = instance
  }

  const pushToRoot = async (component: any, componentProps?: ComponentProps<any> | null | undefined) => {
    // await ionNav.value?.push(component, componentProps)
    await ionNav.value?.setRoot(component, componentProps)
  }

  const waitNav = async () => {
    return new Promise((resolve) => {
      watch(ionNav, (value) => {
        if (value) {
          resolve(ionNav.value)
        }
      }, {
        immediate: true,
      })
    })
  }

  return {
    waitNav,
    push: async <T extends NavComponent>(component: T, componentProps?: ComponentProps<T> | null | undefined, opts?: NavOptions | null | undefined, done?: TransitionDoneFn | undefined) => {
      if (!ionNav.value) {
        await waitNav()
      }
      if (!ionNav.value) throw new Error('nav is null')
      return ionNav.value.push(component, componentProps, opts, done)
    },
    pushToRoot,
    pop: async (opts?: NavOptions | null | undefined, done?: TransitionDoneFn | undefined) => {
      if (!ionNav.value) {
        await waitNav()
      }
      if (!ionNav.value) throw new Error('nav is null')
      return ionNav.value.pop(opts, done)
    },
    setRoot: async <T extends NavComponent>(component: T, componentProps?: ComponentProps<T> | null | undefined, opts?: NavOptions | null | undefined, done?: TransitionDoneFn | undefined) => {
      if (!ionNav.value) {
        await waitNav()
      }
      if (!ionNav.value) throw new Error('nav is null')
      return ionNav.value.setRoot(component, componentProps, opts, done)
    },
    ionNav,
    setIonNavInstance,
    getActive: async () => {
      if (!ionNav.value) {
        await waitNav()
      }
      if (!ionNav.value) throw new Error('nav is null')
      return ionNav.value.getActive()
    },
    removeIndex: async (startIndex: number, removeCount?: number, opts?: NavOptions | null | undefined, done?: TransitionDoneFn | undefined) => {
      if (!ionNav.value) {
        await waitNav()
      }
      if (!ionNav.value) throw new Error('nav is null')
      return ionNav.value.removeIndex(startIndex, removeCount, opts, done)
    },
  }
}