import {PrayerState} from '@/protobuf/pg'
import {useAccountStore} from '@/stores/account'

const accountStore = useAccountStore()

function canOperation(uin: string | undefined) {
  if (!uin) return false
  return accountStore.accountInfo.value?.uin === uin
}

interface PrayerInfo {
    prayerUin?: string | undefined
    groupUin?: string | undefined
    state?: number | undefined
    isPublicAnswered?: boolean
}

export function useOperationLimitState(prayerInfo: PrayerInfo) {
  const isCanCopy = !canOperation(prayerInfo.prayerUin)
  const isCanEditPrayer = canOperation(prayerInfo.prayerUin) || canOperation(prayerInfo.groupUin)
  const isCanPrayAgain = prayerInfo.state === PrayerState.PRAYER_ANSWERED && canOperation(prayerInfo.prayerUin)
  const isCanRemoveFromPublic = prayerInfo.isPublicAnswered && prayerInfo.state === PrayerState.PRAYER_ANSWERED && (canOperation(prayerInfo.prayerUin) || canOperation(prayerInfo.groupUin))
  const isCanMarkAsAnswered = prayerInfo.state !== PrayerState.PRAYER_ANSWERED && canOperation(prayerInfo.prayerUin)
  const isCanDelete = prayerInfo.state !== PrayerState.PRAYER_DEACTIVATE && canOperation(prayerInfo.prayerUin) || canOperation(prayerInfo.groupUin)
  const isCanReport = !canOperation(prayerInfo.prayerUin)
  const isCanBlock = prayerInfo.state !== PrayerState.PRAYER_LOCK && (!canOperation(prayerInfo.prayerUin) && canOperation(prayerInfo.groupUin))
  const isCanUnBlock = prayerInfo.state === PrayerState.PRAYER_LOCK && canOperation(prayerInfo.groupUin)
  return {
    isCanCopy,
    isCanEditPrayer,
    isCanRemoveFromPublic,
    isCanPrayAgain,
    isCanMarkAsAnswered,
    isCanDelete,
    isCanReport,
    isCanBlock,
    isCanUnBlock,
  }
}