import {reactive} from 'vue'

import {getPrayerListApi} from '@/apis/prayer'
import {getPrayerGroupDetailApi} from '@/apis/prayer-group'
import {useI18n} from '@/i18n/use-i18n'
import {GroupType} from '@/protobuf/pg'
import { useAccountStore } from '@/stores/account'
import {
  Group,
  Prayer,
  TABLE_NAME_PRAYER,
  TABLE_NAME_PRAYER_GROUP,
  TABLE_PRIMARYKEY_PRAYER, TABLE_PRIMARYKEY_PRAYER_GROUP
} from '@/stores/database/database-schema-v1'
import { useDBAdapter } from '@/stores/db-adapter'

const {t} = useI18n()
export function usePrivateGroupState() {
  /**
   * db adapter
   */
  const accountInfo = useAccountStore().accountInfo.value
  const dbAdapter = useDBAdapter<Prayer>(TABLE_NAME_PRAYER)
  const groupAdapter = useDBAdapter<Group>(TABLE_NAME_PRAYER_GROUP, {
    groupType: GroupType.GROUP_TYPE_PRIVATE,
    creatorUin: accountInfo?.uin,
  })

  /**
   * group detail
   */
  const groupDetail = reactive<Group>({
    groupId: '',
    groupAvatar: '',
    groupName: '',
    description: '',
    groupType: GroupType.GROUP_TYPE_PRIVATE,
    groupMembers: [],
    creator: '',
    creatorUin: '',
    createTime: 0,
  })

  const queryGroupDetail = async () => {
    const groupList = await groupAdapter.query('*')
    if (groupList.length === 0) return

    console.log('nate-log groupList[0]', groupList[0])
    Object.assign(groupDetail, groupList[0])
  }

  const fetchGroupDetails = async () => {
    const res = await getPrayerGroupDetailApi()
    res.groupName = t('pageContent.prayerGroup.myPrayRoom')
    res.description = t('pageContent.prayerGroup.myPrayRoomDes')
    Object.assign(groupDetail, res)
    await groupAdapter.upsertSet([res], [TABLE_PRIMARYKEY_PRAYER_GROUP])
    dbAdapter.store()
  }

  /**
   * latest prayer list
   */
  const prayerList = reactive<Prayer[]>([])
  const fetchLatestPrayerList = async () => {
    const res = await getPrayerListApi({
      groupId: groupDetail.groupId,
      pageNumber: 1,
      pageSize: 3,
    })
    prayerList.splice(0, prayerList.length)
    prayerList.push(...res.list)

    await dbAdapter.upsertSet(res.list, [TABLE_PRIMARYKEY_PRAYER])
    dbAdapter.store()
  }
  const queryFromDB = async () => {
    if (!groupDetail.groupId) return
    dbAdapter.setCondition({
      groupId: groupDetail.groupId,
    })
    const list = await dbAdapter.query(
      '*',
      {
        keys: 'createTime',
        order: 'DESC',
      },
      {
        offset: 0,
        limit: 3,
      }
    )

    prayerList.splice(0, list.length)
    prayerList.push(...list)
  }

  dbAdapter.watch(async () => {
    await queryFromDB()
  })

  /**
   * refresh
   */
  const loadData = async () => {
    await queryGroupDetail()
    await queryFromDB()
    await fetchGroupDetails()
    await fetchLatestPrayerList()
  }

  return {
    groupDetail,
    prayerList,
    loadData,
  }
}
