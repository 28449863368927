export enum Prayer {
    prayerBlockPageTitle = '我屏蔽的祷告列表',
    prayerHistoryPageTitle = '我发布的祷告列表',
    prayerReportPageTitle = '举报',
    firstPrayerPageTitle = '创建您的第一条祷告',
    prayerTitle = '标题',
    addReminders = '添加个提醒',
    nextReminderTime = '下一次提醒',
    repeating = '重复',
    prayerDes = '描述',
    myReminders = '我的提醒',
    prayerUpdate = '更新',
    prayerRoom = '群组',
    answered = '标记为应许成功',
    publicAnswered = '在公开应许祷告组里分享',
    prayerCount = '统计',
    prayerMembers = '成员',
    prayerTimes = '祷告次数',
    prayerCountComments = '评论',
    prayerComments = '评论',
    prayerAnsweredComments = '应许感言',
    prayerDeleteTitle = '删除这条祷告',
    prayerBlockTitle = '屏蔽这条祷告',
    prayerUnBlockTitle = '取消屏蔽这条祷告',
    prayerAnsweredCancelTitle = '取消公开应允祷告',
    prayerAnsweredRemoveTitle = '移除公开应允祷告',
    prayerReminders = '祷告提醒',
    prayerReport1 = '骗子',
    prayerReport2 = '裸体或性行为',
    prayerReport3 = '仇恨言论',
    prayerReport4 = '暴力或危险组织',
    prayerReport5 = '欺凌或骚扰',
    prayerReport6 = '虚假信息欺诈',
    prayerReport7 = '骗局或欺诈',
    prayerReport8 = '其他',
    choseReason = '请选择一个原因',
    reportDetails = '详情',
    everyDay = '每一天',
    weekDay = '周末',
    everyWeek = '每一周',
    everyMouth = '每个月',
    everyYear = '每年',
    threeMinutes = '三分钟',
    never = '决不',
}