import {loadingController, toastController} from '@ionic/vue'
import cloneDeep from 'lodash/cloneDeep'
import {computed, onMounted, ref, unref} from 'vue'

import {EditPrayerGroupApiReq} from '@/apis/model/prayer-group-model'
import {
  deletePrayerGroupApi,
  editPrayerGroupApi,
  getPrayerGroupDetailApi,
  getPublicPrayerGroup,
  removePrayerGroupMembersApi
} from '@/apis/prayer-group'
import {useI18n} from '@/i18n/use-i18n'
import {GroupType} from '@/protobuf/pg'
import {useAccountStore} from '@/stores/account'
import {Group, TABLE_PRIMARYKEY_PRAYER_GROUP} from '@/stores/database/database-schema-v1'
import {usePrayerGroupDBAdapter} from '@/stores/db-adapter/prayer-group'

const {t} = useI18n()
export function usePrayerGroup(groupId: string, groupType?: GroupType, group?: Group) {

  const dbAdapter = usePrayerGroupDBAdapter({
    groupId,
  })
  const prayerGroup = ref<Group>(group || {
    groupId: '',
    groupAvatar: '',
    groupName: '',
    groupType: GroupType.GROUP_TYPE_PUBLIC,
    description: '',
    groupMembers: [],
    creator: '',
    creatorUin: '',
    createTime: 0,
  })

  /**
   * 获取祷告组详情
   */
  function getPrayerGroup() {
    if (groupType === GroupType.GROUP_TYPE_PUBLIC) {
      getPublicPrayerGroup().then((res) => {
        prayerGroup.value = res
      })
    } else {
      getPrayerGroupDetailApi({groupId}).then(async (res) => {
        prayerGroup.value = res
        await dbAdapter.upsertSet([res], [TABLE_PRIMARYKEY_PRAYER_GROUP])
        await dbAdapter.store()
      })
    }

  }

  /**
   * 编辑祷告组详情
   * @param data
   */
  function editPrayerGroup(data: Omit<EditPrayerGroupApiReq, 'groupId'>) {
    return editPrayerGroupApi({groupId, ...data}).then(async () => {
      await dbAdapter.update({groupId}, data)
      await dbAdapter.store()
      const toast = await toastController.create({
        color: 'success',
        duration: 2000,
        message: t('toast.modifySuccessfully'),
      })
      await toast.present()
    })
  }

  /**
   * 解散祷告组
   */
  function disbandPrayerGroup() {
    return deletePrayerGroupApi({groupId}).then(async () => {
      const toast = await toastController.create({
        color: 'success',
        duration: 2000,
        message: t('toast.modifySuccessfully'),
      })
      await toast.present()
    })
  }

  /**
   * 删除祷告组成员
   * @param uinList
   */
  function deletePrayerGroupMember(uinList: string[]): Promise<void> {
    return removePrayerGroupMembersApi({groupId, uinList}).then(() => {
      getPrayerGroup()
    })
  }

  /**
   * is inputting
   */
  const isInfoInputting = ref(false)
  const setInfoInputting = (val: boolean) => {
    isInfoInputting.value = val
  }

  const isNameInputting = ref(false)
  const setNameInputting = (val: boolean) => {
    isNameInputting.value = val
  }

  /**
   * backup
   */
  let backupInfo: any
  const backup = () => {
    backupInfo = cloneDeep(prayerGroup.value)
  }
  const restoreBackup = () => {
    Object.assign(prayerGroup.value, backupInfo)
  }

  /**
   * 初始化祷告组数据
   */
  async function initData() {
    if (group) return
    const cachedPrayerGroup = await dbAdapter.query('*')
    if (cachedPrayerGroup && cachedPrayerGroup.length > 0) {
      prayerGroup.value = cachedPrayerGroup[0]
      getPrayerGroup()
    } else {
      const loading = await loadingController.create({
        message: t('loading.pleaseWait'),
      })
      await loading.present()
      try {
        getPrayerGroup()
      } finally {
        await loading.dismiss()
      }
    }
  }


  const {accountInfo} = useAccountStore()
  const hasEditAccess = computed(() => {
    if (prayerGroup.value && prayerGroup.value?.groupMembers) {
      const creatorUin = unref(prayerGroup)?.creatorUin
      return unref(accountInfo)?.uin === creatorUin
    }
    return false
  })

  const otherGroupMembers = computed(() => {
    if (prayerGroup.value && prayerGroup.value?.groupMembers) {
      const groupMembers = prayerGroup.value.groupMembers
      return groupMembers.filter(member => member.uin !== unref(accountInfo)?.uin)
    }
    return []
  })

  onMounted(() => {
    initData()
  })

  return {
    prayerGroup,
    hasEditAccess,
    otherGroupMembers,
    getPrayerGroup,
    editPrayerGroup,
    disbandPrayerGroup,
    deletePrayerGroupMember,
    isInfoInputting,
    setInfoInputting,
    isNameInputting,
    setNameInputting,
    backup,
    restoreBackup,
  }
}
