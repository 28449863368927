import {InfiniteScrollCustomEvent} from '@ionic/core'
import {ref} from 'vue'

import {apiReadAnsweredPrayerInfo, getPrayerListApi} from '@/apis/prayer'
import {useI18n} from '@/i18n/use-i18n'
import {PrayerState} from '@/protobuf/pg'
import {Prayer, TABLE_NAME_PRAYER, TABLE_PRIMARYKEY_PRAYER} from '@/stores/database/database-schema-v1'
import {useDBAdapter} from '@/stores/db-adapter'

const {t} = useI18n()

export function usePrayerList(groupId: string, prayerState: PrayerState) {
  let pageNumber = 0
  const pageSize = 10
  const hasMoreData = ref(false)
  const prayerList = ref<Prayer[]>([])
  const checkedPrayerIds = ref<string[]>([])
  const dbAdapter = useDBAdapter<Prayer>(TABLE_NAME_PRAYER,
    {
      groupId: groupId,
      state: prayerState,
    })


  /**
     * 获取祷告列表
     * @param event
     * @param isReset
     */
  function getPrayerList(event?: InfiniteScrollCustomEvent | null, isReset?: boolean) {
    return getPrayerListApi({
      groupId: groupId,
      state: prayerState,
      pageNumber,
      pageSize,
    }).then(async (res) => {
      const {list, hasNextPage} = res
      pageNumber += 1
      if (isReset) {
        prayerList.value = list
      } else {
        prayerList.value.push(...list)
      }
      hasMoreData.value = hasNextPage
      // 数据持久化到数据库
      await dbAdapter.upsertSet(list, [TABLE_PRIMARYKEY_PRAYER])
      await dbAdapter.store()
    }).finally(() => {
            event?.target.complete()
    })
  }

  function resetPrayerList() {
    pageNumber = 0
    return getPrayerList(null, true)
  }

  /**
     * 反选祷告
     * @param prayerId
     */
  function reverseCheckedPrayerId(prayerId: string) {
    const index = checkedPrayerIds.value.findIndex(checkedPrayerId => checkedPrayerId === prayerId)
    if (index === -1) {
      checkedPrayerIds.value.push(prayerId)
    } else {
      checkedPrayerIds.value.splice(index, 1)
    }
  }

  /**
     * 重置选中祷告列表
     */
  function resetCheckedPrayerIds() {
    checkedPrayerIds.value = []
  }

  /**
     * 阅读祷告
     * @param prayerId
     */
  function readPrayer(prayerId: string) {
    const prayer = prayerList.value.find(prayer => prayer.prayerId === prayerId)
    if (prayer && (!prayer.isRead || prayer.commentStatistic)) {
      apiReadAnsweredPrayerInfo({prayerId: prayer.prayerId}).then(() => {
        prayer.isRead = true
        prayer.commentStatistic = 0
        dbAdapter.update({prayerId: prayer.prayerId}, prayer)
      })
    }


  }

  async function initData() {
    const cachedPrayerList = await dbAdapter.query(
      '*',
      {
        keys: 'createTime',
        order: 'DESC',
      },
      {
        offset: 0,
        limit: 10,
      }
    )
    if (cachedPrayerList && cachedPrayerList.length > 0) {
      prayerList.value.push(...cachedPrayerList)
      await resetPrayerList()
    } else {
      // const loading = await loadingController.create({
      //   message: t('loading.pleaseWait'),
      // })
      // await loading.present()
      try {
        await getPrayerList()
      } finally {
        // await loading.dismiss()
      }
    }
  }


  dbAdapter.watch(async (event, values) => {
    if (event === 'insert') {
      values = dbAdapter.getChangesCareAbout(values)
      values.forEach(value => prayerList.value.unshift(value as Prayer))
    } else if (event === 'update') {
      prayerList.value = prayerList.value.map(prayer => {
        const modifyValue = values.find(value => {
          return value.prayerId === prayer.prayerId
        })
        if (modifyValue) {
          prayer = {...prayer, ...modifyValue}
        }
        return prayer
      })
    } else if (event === 'delete') {
      const deletePrayerIds = values.map(value => value.prayerId)
      prayerList.value = prayerList.value.filter(prayer => !deletePrayerIds.includes(prayer.prayerId))
    }
  })

  // onMounted(async () => {
  //   await initData()
  // })

  return {
    initData,
    prayerList,
    checkedPrayerIds,
    hasMoreData,
    getPrayerList,
    resetPrayerList,
    reverseCheckedPrayerId,
    resetCheckedPrayerIds,
    readPrayer,
  }
}
