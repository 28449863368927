import {useSessionStorage} from '@vueuse/core'
import {parse, stringify} from 'qs'

import { LaunchCommand, LaunchCommandType } from '@/views/entry/launch-command/definitions'
import { usePrayerGroupInvitation } from '@/views/entry/launch-command/use-prayer-group-invitation'
import { usePrayerShare } from '@/views/entry/launch-command/use-prayer-share'

const STORE_KEY = 'launch-command'


export function storeLaunchCommand(href: string) {
  if (!href.includes('launchType')) {
    console.info('Not detect launch command')
    return
  }
  const storage = useSessionStorage<string>(STORE_KEY, null)
  storage.value = href
}

export function getLaunchCommand(): LaunchCommand<any> | null {
  const hrefRef = useSessionStorage<string>(STORE_KEY, null)
  if (!hrefRef.value) return null

  const url = new URL(hrefRef.value)
  const search = url.search.split('?').pop()

  if (!search) throw new Error('Invalid Scheme URL')

  const {launchType, launchParams} = parse(search)
  return {
    launchType: Number(launchType),
    launchParams: launchParams,
  }
}

export function getLaunchCommandFromUrl<Params>(urlstr: string): LaunchCommand<Params> {
  const url = new URL(urlstr)
  const search = url.search.split('?').pop()

  if (!search) throw new Error('Invalid Scheme URL')

  const {launchType, launchParams} = parse(search)
  return {
    launchType: Number(launchType),
    launchParams: launchParams as unknown as Params,
  }
}

export function getStringifiedLaunchCommand<Params>(command: LaunchCommandType, params: Params): string {
  const commandInfo: LaunchCommand<Params> = {
    launchType: command,
    launchParams: params,
  }
  return stringify(commandInfo)
}

export function clearLaunchCommand() {
  const storage = useSessionStorage<string>(STORE_KEY, null)
  storage.value = null
}

export function handleLaunchCommand(command: LaunchCommand<any>) {
  switch (command.launchType) {
  case LaunchCommandType.PrayerShare:
    usePrayerShare(command)
    break
  case LaunchCommandType.PrayerGroupInvitation:
    usePrayerGroupInvitation(command)
    break
  }
}