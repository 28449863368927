import {reactive, watch} from 'vue'

import {useI18n} from '@/i18n/use-i18n'
import {NoticeType} from '@/protobuf/pg'
import {Notice} from '@/stores/database/database-schema-v1'
import {Reactive} from '@/typings/commen'
import {NoticeComponentData, UseNoticeFactoryReturn, UseNoticeReturn} from '@/uses/notice-processor/type.d'
import {useNoticeComment} from '@/uses/notice-processor/use-comment'
import {useNoticeCommentBanned} from '@/uses/notice-processor/use-comment-banned'
import {useNoticeCommentLike} from '@/uses/notice-processor/use-comment-like'
import {useNoticeCommentReply} from '@/uses/notice-processor/use-comment-reply'
import {useNoticeGroupDissolved} from '@/uses/notice-processor/use-group-dissolved'
import {useNoticeGroupJoined} from '@/uses/notice-processor/use-group-joined'
import {useNoticePrayerBanned} from '@/uses/notice-processor/use-prayer-banned'
import {useNoticePrayerReminder} from '@/uses/notice-processor/use-prayer-reminder'
import {useNoticePublicAnsweredFailed} from '@/uses/notice-processor/use-public-answered-fail'

const {t} = useI18n()

export function useNoticeProcessor(props: {notice: Reactive<Notice>}): UseNoticeFactoryReturn {
  const notice = props.notice
  let state: UseNoticeReturn
  switch (notice.type) {
  case NoticeType.NOTICE_TYPE_LIKE:
  case NoticeType.NOTICE_TYPE_REPLY_LIKE:
    state = useNoticeCommentLike(notice)
    break
  case NoticeType.NOTICE_TYPE_COMMENT:
    state = useNoticeComment(notice)
    break
  case NoticeType.NOTICE_TYPE_REPLY_COMMENT:
    state = useNoticeCommentReply(notice)
    break
  case NoticeType.NOTICE_TYPE_COMMENT_BANNED:
    state = useNoticeCommentBanned(notice)
    break
  case NoticeType.NOTICE_TYPE_PRAYER_REMINDER:
    state = useNoticePrayerReminder(notice)
    break
  case NoticeType.NOTICE_TYPE_PRAYER_BANNED:
    state = useNoticePrayerBanned(notice)
    break
  case NoticeType.NOTICE_TYPE_GROUP_DISSOLVE:
    state = useNoticeGroupDissolved(notice)
    break
  case NoticeType.NOTICE_TYPE_GROUP_JOIN:
    state = useNoticeGroupJoined(notice)
    break
  case NoticeType.NOTICE_TYPE_PUBLIC_ANSWERED_FAIL:
    state = useNoticePublicAnsweredFailed(notice)
    break
  default:
    throw new Error(`${t('pageContent.noticeCenter.noticeTypeError')}: ${notice.type}`)
  }

  if (!state) throw new Error(`${t('pageContent.noticeCenter.noticeTypeError')}: ${notice.type}`)

  return {
    componentData: state.componentData,
    onClick: state.onClick,
  }
}