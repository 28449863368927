import {Media} from '@capacitor-community/media'
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo
} from '@capacitor/camera'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Storage } from '@capacitor/storage'
import { isPlatform } from '@ionic/vue'
import {Options as Html2canvasOptions} from 'html2canvas/dist/types/index.d'
import { onMounted, ref, watch } from 'vue'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import {ScreenShot} from '@/utils/screen-shot'

export interface UserPhoto {
  filepath: string
  webviewPath?: string
}

export function usePhoto() {
  const photos = ref<UserPhoto[]>([])
  const PHOTO_STORAGE = 'photos'

  const loadSaved = async () => {
    const photoList = await Storage.get({ key: PHOTO_STORAGE })
    const photosInStorage = photoList.value ? JSON.parse(photoList.value) : []

    // If running on the web...
    if (!isPlatform('hybrid')) {
      for (const photo of photosInStorage) {
        const file = await Filesystem.readFile({
          path: photo.filepath,
          directory: Directory.Cache,
        })
        // Web platform only: Load the photo as base64 data
        photo.webviewPath = `data:image/jpeg;base64,${file.data}`
      }
    }

    photos.value = photosInStorage
  }

  const convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onerror = reject
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })

  const savePicture = async (
    photo: Photo,
    fileName: string
  ): Promise<UserPhoto> => {
    let base64Data: string
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform('hybrid')) {
      const file = await Filesystem.readFile({
        // eslint-disable-next-line
        path: photo.path!,
      })
      base64Data = file.data
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      // eslint-disable-next-line
      const response = await fetch(photo.webPath!);
      const blob = await response.blob()
      base64Data = (await convertBlobToBase64(blob)) as string
    }
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Cache,
    })

    if (isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      }
    } else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return {
        filepath: fileName,
        webviewPath: photo.webPath,
      }
    }
  }

  const takePhoto = async () => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    })
    const fileName = new Date().getTime() + '.jpeg'
    const savedFileImage = await savePicture(cameraPhoto, fileName)

    photos.value = [savedFileImage, ...photos.value]
  }

  const deletePhoto = async (photo: UserPhoto) => {
    // Remove this photo from the Photos reference data array
    photos.value = photos.value.filter((p) => p.filepath !== photo.filepath)

    // delete photo file from filesystem
    const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1)
    await Filesystem.deleteFile({
      path: filename,
      directory: Directory.Cache,
    })
  }

  const cachePhotos = () => {
    Storage.set({
      key: PHOTO_STORAGE,
      value: JSON.stringify(photos.value),
    })
  }

  const saveSreenshotToFileSystem = async (element: HTMLDivElement, fileType: 'jpeg' | 'png', options?: Partial<Html2canvasOptions>) => {
    const screenShot = new ScreenShot(element, fileType, options)
    await screenShot.waitInit()
    const base64Data = screenShot.getDataURL()

    const fileName = new Date().getTime() + `.${fileType}`
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Cache,
    })
    return savedFile.uri
  }

  const screenshotToPhoto = async (element: HTMLDivElement, fileType: 'jpeg' | 'png', options?: Partial<Html2canvasOptions>) => {
    try {
      const uri = await saveSreenshotToFileSystem(element, fileType, options)
      const media = new Media()
      await media.savePhoto({
        path: uri,
        album: isPlatform('android') ? 'PG' : undefined,
      })
      console.info('save photo successfully')
    }
    catch (e) {
      console.error(e)
    }
  }

  onMounted(loadSaved)

  watch(photos, cachePhotos)

  return {
    photos,
    takePhoto,
    deletePhoto,
    saveSreenshotToFileSystem,
    screenshotToPhoto,
  }
}