import {
  Group,
  TABLE_NAME_PRAYER_GROUP
} from '@/stores/database/database-schema-v1'
import { useDBAdapter } from '@/stores/db-adapter/index'

export function usePrayerGroupDBAdapter(condition?: Partial<Group> | string) {
  const dbAdapter = useDBAdapter<Group>(TABLE_NAME_PRAYER_GROUP, condition)

  dbAdapter.setDataModifier((group: Group) => {
    const newGroup = {
      ...group,
    }
    if (group.groupMembers) {
      newGroup.groupMembers = JSON.parse(group?.groupMembers as unknown as string)
    }
    else {
      newGroup.groupMembers = undefined
    }
    return newGroup
  })

  return {
    ...dbAdapter,
  }
}