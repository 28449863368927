// import {GroupFormParams} from '@/apis/model/prayer-group-model'
import {PagenationRes} from '@/apis/type'
import {TimingTaskLoopType} from '@/protobuf/pg'
import {Prayer} from '@/stores/database/database-schema-v1'
import {http} from '@/utils/http'

/**
 * 创建祷告
 */
export interface CreatePrayerReq {
    title: string
    description: string
    reminderType?: number
    nextReminderTime?: number
    groupId?: string
}

interface CreatePrayerRes {
    groupId: string
    groupMemberCount: string
    isPublic: boolean
    prayer: {
        createTime: number
        description: string
        prayerId: string
        state: number
        title: string
        uin: string
        updateTime: number
    }
    prayerCommentCount: number
    prayerCount: number
    reminder: {
        nextReminderTime: number | undefined
        remindType: number | undefined
    }
}

export const createPrayer = (data: CreatePrayerReq): Promise<CreatePrayerRes> => http.post({
  url: '/prayer/create',
  data,
})

/**
 * 删除祷告
 */
interface DeletePrayerReq {
    prayerId: string
}

interface DeletePrayerRes {
    prayerId: string
}

export const deletePrayer = (data: DeletePrayerReq): Promise<DeletePrayerRes> => http.post({
  url: '/prayer/delete',
  data,
})

/**
 * 编辑祷告信息
 */
interface UpdatePrayerReq {
    prayerId: string
    title: string
    description: string
}

interface UpdatePrayerRes {
    prayerId: string
}

export const updatePrayer = (data: UpdatePrayerReq): Promise<UpdatePrayerRes> => http.post({
  url: '/prayer/edit',
  data,
})

/**
 * 获取祷告列表
 */
interface GetPrayerListReq {
    prayerGroupId: string
    limit?: number
    offset?: number
}

type GetPrayerListRes = Prayer[]

export const getPrayerList = (data: GetPrayerListReq): Promise<GetPrayerListRes> => http.get({
  url: '/prayer/list',
  params: data,
})

interface UpdatePrayerReminderReq {
    prayerId: string
    remindType: number
    startTime: number
}

interface UpdatePrayerReminderRes {
    prayerId: string
}

export const UpdatePrayerReminder = (data: UpdatePrayerReminderReq): Promise<UpdatePrayerReminderRes> => http.post({
  url: '/prayer/reminder',
  data,
})

interface PrayerReportReq {
    reportUin: string
    prayerId?: string
    type: number
    dimension: number
    comment?: string
    commentId?: string
}

/**
 * 祷告举报
 */
export const prayerReport = (data: PrayerReportReq) => http.post({
  url: '/report',
  data,
})

interface GetPrayerListByIdsReq {
    prayerIdList: string[]
}

type GetPrayerListByIdsRes = Prayer[]

export const getPrayerListByIdsApi = (data: GetPrayerListByIdsReq): Promise<GetPrayerListByIdsRes> => {
  return http.get({
    url: '/prayer/list/detail',
    params: data,
  })
}

interface AddPrayCountReq {
    relationIds: string[]
    type: number
}

export const addPrayCountApi = (data: AddPrayCountReq): Promise<void> => {
  return http.post({
    url: '/statistic/increase/type',
    data,
  })
}

/**
 * 获取自己的祷告列表
 */
export interface GetPrayerOwnListReq {
    pageNumber: number
    pageSize: number
}

interface GetPrayerOwnListRes extends PagenationRes {
    list: Prayer[]
}

export const getPrayerOwnListApi = (params: GetPrayerOwnListReq): Promise<GetPrayerOwnListRes> => http.get({
  url: '/prayer/own/list',
  params,
})

/**
 * 获取屏蔽的祷告列表
 */
interface GetPrayerBlockListReq {
    pageNumber: number
    pageSize: number
}

export const getPrayerBlockListApi = (params: GetPrayerBlockListReq): Promise<GetPrayerOwnListRes> => http.get({
  url: '/prayer/ban/list',
  params,
})


export interface PrayerListReq {
    // 如果 没有 groupId, 将获取公共应允祷告组的祷告列表
    groupId?: string
    state?: number
    pageNumber?: number
    pageSize?: number
}

export interface PrayerInfo {
    avatar: string
    commentStatistic: number
    groupId: string
    isRead: boolean
    name: string
    prayerId: string
    prayerStatistic: number
    state: number
    title: string
    uin: string
}

export interface PrayerListRes extends PagenationRes {
    list: Prayer[]
}


export const getPrayerListApi = (params: PrayerListReq) => http.get<PrayerListRes>({
  url: '/prayer/list/summary',
  params,
})

/**
 * 祷告应予
 */
interface PrayerAnsweredReq {
    prayerId: string
    isPublic: boolean
}

export const prayerAnsweredApi = (data: PrayerAnsweredReq) => http.post({
  url: '/prayer/answered',
  data,
})
/**
 * 取消祷告应予
 */

export const prayerAnsweredCancelApi = (data: { prayerId: string }) => http.post({
  url: '/prayer/answered/cancel',
  data,
})

/**
 * 屏蔽指定祷告
 */
interface PrayerShieldReq {
    prayerId: string
    groupId: string
}

export const prayerBanApi = (data: PrayerShieldReq) => http.post({
  url: '/prayer/ban',
  data,
})

/**
 * 屏蔽指定祷告
 */
interface PrayerShieldReq {
    prayerId: string
    groupId: string
}

export const prayerUnBanApi = (data: PrayerShieldReq) => http.post({
  url: '/prayer/ban/cancel',
  data,
})

/**
 * 获取指定祷告详情
 */
interface GtePrayerDetailReq {
    prayerId: string
}

interface GetPrayerDetailApiRes {
    groupId: string
    groupMemberCount: number
    isPublic: boolean
    prayer: {
        createTime: number
        description: string
        prayerId: string
        state: number
        title: string
        uin: string
        updateTime: number
    }
    prayerCommentCount: number
    prayerCount: number
    reminder?: {
        nextReminderTime: number
        remindType: number
    }
}

export const getPrayerDetailApi = (params: GtePrayerDetailReq): Promise<GetPrayerDetailApiRes> => http.get({
  url: '/prayer/detail',
  params,
})


/**
 * 获取分享 prayer 的 Link
 */
interface GetShareTokenReq {
    prayerId: string
}

type GetShareTokenRes = string
export const getShareToken = (data: GetShareTokenReq): Promise<GetShareTokenRes> => {
  return http.get({
    url: '/prayer/share/token',
    params: data,
  })
}

interface GetSharedPrayerReq {
    shareToken: string
}

interface GetSharedPrayerRes {
    prayer: Prayer
}

export const apiGetPrayerByToken = (data: GetSharedPrayerReq): Promise<GetSharedPrayerRes> => {
  return http.get({
    url: '/prayer/share/detail',
    params: data,
  })
}

interface ApiEditReminderReq {
    prayerId: string
    remindType: TimingTaskLoopType
    startTime: number
}

interface ApiEditReminderRes {
    nextReminderTime: number
    remindType: TimingTaskLoopType
}

export const apiEditReminder = (data: ApiEditReminderReq): Promise<ApiEditReminderRes> => {
  return http.post({
    url: '/prayer/edit/reminder',
    data,
  })
}
/**
 * 获取个组内全部祷告信息
 */

export const getGroupPrayerList = (params: { groupId: string; state: number | undefined }) => http.get({
  url: '/prayer/list/group/detail',
  params,
})

export interface GroupAnsweredInfo {
    commentStatistic: number
    groupId: string
    isRead: boolean
    prayerTitle: string
}

/**
 * 拉取小组内answered的摘要的统计信息
 */

export const apiGetGroupAnsweredInfo = (params: { groupId: string }) => http.get<GroupAnsweredInfo>({
  url: '/prayer/group/answered/update/info',
  params,
})

/**
 * 已阅新增加的answered统计
 */

export const apiReadGroupAnsweredInfo = (data: { groupId: string }) => http.post({
  url: '/prayer/group/answered/update/clear',
  data,
})


/**
 * read answered group prayer
 */

export const apiReadAnsweredPrayerInfo = (data: { prayerId: string }) => http.post({
  url: '/prayer/answered/update/clear',
  data,
})

/**
 * 用户取消公开祷告
 */

export const removePublicAnsweredApi = (data: { prayerId: string }) => http.post({
  url: '/prayer/public/cancel',
  data,
})



