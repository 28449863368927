import {ref} from 'vue'

import {getPrayerListApi} from '@/apis/prayer'
import {getPublicPrayerGroup} from '@/apis/prayer-group'
import {GroupType, PrayerState} from '@/protobuf/pg'
import {
  Group,
  Prayer,
  TABLE_NAME_PRAYER,
  TABLE_NAME_PRAYER_GROUP, TABLE_PRIMARYKEY_PRAYER,
  TABLE_PRIMARYKEY_PRAYER_GROUP
} from '@/stores/database/database-schema-v1'
import {useDBAdapter} from '@/stores/db-adapter'

export function usePublicAnsweredPrayerState() {
  const prayerList = ref<Prayer[]>([])
  const hasMoreData = ref(false)

  const dbAdapter = useDBAdapter<Prayer>(TABLE_NAME_PRAYER)
  const groupAdapter = useDBAdapter<Group>(TABLE_NAME_PRAYER_GROUP, {
    groupType: GroupType.GROUP_TYPE_PUBLIC,
  })


  dbAdapter.watch((event, values) => {
    const valuesObj: { [key: string]: Partial<Prayer> } = {}
    switch (event) {
    case 'update':
      values.forEach(value => valuesObj[value.prayerId as string] = value)
      prayerList.value = prayerList.value.map(prayer => {
        if (valuesObj[prayer.prayerId]) {
          prayer = {...prayer, ...valuesObj[prayer.prayerId]}
        }
        return prayer
      })
      break
    case 'delete':
      values.forEach(value => valuesObj[value.prayerId as string] = value)
      prayerList.value = prayerList.value.filter(prayer => !valuesObj[prayer.prayerId])
      break
    }
  })
  /**
   * public answered group id
   */
  const publicGroup = ref<Group>()

  const queryGroupDetail = async () => {
    const groupList = await groupAdapter.query('*')
    if (groupList.length === 0) return

    publicGroup.value = groupList[0]
  }

  const fetchPublicPrayerGroup = async () => {
    publicGroup.value = await getPublicPrayerGroup()

    groupAdapter.upsertSet([publicGroup.value], [TABLE_PRIMARYKEY_PRAYER_GROUP])
  }


  /**
   * prayer list
   */

  const queryPrayerList = async () => {
    if (!publicGroup.value) return
    dbAdapter.setCondition({
      groupId: publicGroup.value.groupId,
    })
    const list = await dbAdapter.query(
      '*',
      {
        keys: 'createTime',
        order: 'DESC',
      },
      {
        offset: 0,
        limit: 5,
      }
    )

    prayerList.value = list
  }

  const fetchPrayerList = async () => {
    const params = {
      pageSize: 5,
      state: PrayerState.PRAYER_ANSWERED,
      groupId: publicGroup.value?.groupId,
    }
    const res = await getPrayerListApi(params)
    hasMoreData.value = res.hasNextPage
    prayerList.value = res.list

    await dbAdapter.upsertSet(res.list, [TABLE_PRIMARYKEY_PRAYER])
    dbAdapter.store()
  }

  /**
   * init data
   */

  const loadData = async () => {
    await queryGroupDetail()
    await queryPrayerList()
    await fetchPublicPrayerGroup()
    await fetchPrayerList()
  }

  return {
    loadData,
    publicGroup,
    prayerList,
    hasMoreData,
  }
}
