import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications'

import { Notice } from '@/stores/database/database-schema-v1'
import {handleClickedNotice, handleReceivedNotice, handleToken} from '@/views/entry/push-notification/processor'


async function registerNotification() {
  let permStatus = await PushNotifications.checkPermissions()

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions()
  }

  if (permStatus.receive === 'granted') {
    await PushNotifications.register()
  }
}

export async function listenPushNotification() {
  PushNotifications.addListener('registration', (token: Token) => {
    console.log(`nate-log get push token: ${token.value}`)
    handleToken(token.value)
  })

  PushNotifications.addListener('registrationError', (err: any) => {
    console.error(`nate-log Registration error: ${err.error}`)
  })

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      if (!notification.data) return
      const notice = notification.data.data as Notice
      handleReceivedNotice(notice)
    }
  )
  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed',
    (notification: ActionPerformed) => {
      if (!notification.notification.data) return
      const notice = notification.notification.data.data as Notice
      handleClickedNotice(notice)
    }
  )

  registerNotification()
}
