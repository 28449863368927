import { PagenationReq, PagenationRes } from '@/apis/type'
import { CommentType } from '@/protobuf/pg'
import {PrayerComment} from '@/stores/database/database-schema-v1'
import {http} from '@/utils/http'

export interface ApiDeleteCommentReq {
  commentId: string
}
export const apiDeleteComment = (data: ApiDeleteCommentReq): Promise<void> => {
  return http.post({
    url: '/comment/delete',
    data: {
      ...data,
      type: CommentType.COMMENT_TYPE_COMMENT,
    },
  })
}
export const apiDeleteAnsweredComment = (data: ApiDeleteCommentReq): Promise<void> => {
  return http.post({
    url: '/comment/delete',
    data: {
      ...data,
      type: CommentType.COMMENT_TYPE_ANSWER_COMMENT,
    },
  })
}
export interface ApiGetCommentListReq extends PagenationReq {
  prayerId: string
  type?: number
}
export interface ApiGetCommentListRes extends PagenationRes {
  list: PrayerComment[]
}
export const apiGetCommentList = (data: ApiGetCommentListReq): Promise<ApiGetCommentListRes> => {
  return http.post({
    url: '/comment/list',
    data: {
      ...data,
      type: (typeof data.type === 'undefined') ? CommentType.COMMENT_TYPE_COMMENT : data.type,
    },
  })
}

export interface ApiCreateCommentReq {
  content: string
  parentId?: string
  prayerId: string
}
export const apiCreateComment = (data: ApiCreateCommentReq): Promise<string> => {
  return http.post({
    url: '/comment/new',
    data: {
      ...data,
      type: CommentType.COMMENT_TYPE_COMMENT,
    },
  })
}

export interface ApiLikeCommentReq {
  commentId: string
  prayerId: string
}
export const apiLikeComment = (data: ApiLikeCommentReq): Promise<void> => {
  return http.post({
    url: '/comment/new',
    data: {
      parentId: data.commentId,
      prayerId: data.prayerId,
      type: CommentType.COMMENT_TYPE_LIKE,
    },
  })
}

export interface ApiCancelLikeCommentReq {
  commentId: string
}
export const apiCancelLikeComment = (data: ApiCancelLikeCommentReq): Promise<void> => {
  return http.post({
    url: '/comment/delete',
    data: {
      ...data,
      type: CommentType.COMMENT_TYPE_LIKE,
    },
  })
}

export const apiCreateAnsweredPrayerComment = (data: ApiCreateCommentReq): Promise<void> => {
  return http.post({
    url: '/comment/new',
    data: {
      ...data,
      type: CommentType.COMMENT_TYPE_ANSWER_COMMENT,
    },
  })
}