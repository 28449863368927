import {modalController} from '@ionic/vue'

import {PrayerGroupInvitationApi, getPrayerGroupDetailApi} from '@/apis/prayer-group'
import {useAccountStore} from '@/stores/account'
import {usePrayerGroupDBAdapter} from '@/stores/db-adapter/prayer-group'
import {InvitationParams, LaunchCommand} from '@/views/entry/launch-command/definitions'
import Start from '@/views/entry/start/Start.vue'
import PrayerGroupInvitationSuccess
  from '@/views/prayer-group/prayer-group-invitation-success/PrayerGroupInvitationSuccess.vue'

export function usePrayerGroupInvitation(launchCommand: LaunchCommand<InvitationParams>) {
  const accountStore = useAccountStore()
  const invitationCode = launchCommand.launchParams.token
  const ionNav = document.querySelector('ion-nav') as any
  const uin = accountStore.accountInfo.value?.uin
  if (accountStore.isLoggedIn.value && uin) {
    PrayerGroupInvitationApi({uin, token: invitationCode}).then(async (res) => {
      const {groupId} = res
      const dbAdapter = usePrayerGroupDBAdapter(groupId)
      getPrayerGroupDetailApi({groupId}).then(async (res) => {
        await dbAdapter.insert(res)
        await dbAdapter.store()
        modalController.create({
          component: PrayerGroupInvitationSuccess,
        }).then(modal => {
          modal.present()
        })
      })

    })
  } else {
    ionNav.push(Start, null, {animated: false})
  }
}
