export enum AppSetting {
    noticePageTitle = '消息通知设置',
    termsPageTitle = '用户协议',
    emailTitle = '邮件',
    pushTitle = '推送',
    emailItemTitle1 = '祷告评论',
    emailItemTitle2 = '祷告回复',
    emailItemTitle3 = '系统通知',
    pushItemTitle1 = '祷告评论',
    pushItemTitle2 = '祷告回复',
    pushItemTitle3 = '系统通知',
    settingItemTitle1 = '账号',
    settingItemTitle2 = '消息通知',
    settingItemTitle3 = '隐私政策',
    settingItemTitle4 = '用户协议',
    appVersion = '版本',
    logOutTips = '您确定要退出登录吗',
}