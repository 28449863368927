import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions'
import {Clipboard} from '@capacitor/clipboard'
import {isPlatform} from '@ionic/vue'
import {useClipboard} from '@vueuse/core'

export async function copy(text: string) {
  if (isPlatform('mobileweb')) {
    const {copy} = useClipboard()
    await copy(text)
    return Promise.resolve()
  }

  if (isPlatform('android')) {
    const checkPermissionResponse = await AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
    if (checkPermissionResponse.hasPermission) {
      await Clipboard.write({url: text})
      return Promise.resolve()
    } else {
      const requestPermissionResponse =await AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
      if (requestPermissionResponse.hasPermission) {
        await Clipboard.write({url: text})
        return Promise.resolve()
      }
    }
  }

  if (isPlatform('ios')) {
    Clipboard.write({url: text})
    return Promise.resolve()
  }

  return Promise.reject()
}
