enum Placeholder {
    emailAddress = '邮箱',
    password = '密码',
    newPassword = '新密码',
    nickName = '名字',
    notRequired = '非必填',
    prayerTitle = '请输入祷告标题',
    prayerDes = '关于祷告的描述',
    answeredDes = '有新的变化吗？',
    groupRoomName = '组名',
    groupRoomDes = '描述',
    groupChangeDes = '请在此处输入更多信息...',
    search = '查询',
}

enum ErrorMessage {
    enterEmail = '请输入邮箱',
    incorrectEmail = '请检查，您填写的信息格式不正确',
    enterCode = '请输入密码',
    codeError = '密码长度为8-20位',
    sameCodeError = '请输入相同的密码',
    enterName = '请输入一个昵称',
    enterGroupName = '请输入一个群组名称',
    groupNameError = '不能超过200个字',
    groupDesError = '不能超过200个字',
}

export const Input = {
  placeholder: Placeholder,
  errorMessage: ErrorMessage,
}