import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import {Ref, computed, reactive, ref} from 'vue'

import {createPrayer} from '@/apis/prayer'
import {useAccountStore} from '@/stores/account'
import {Group, Prayer, ReminderInfo, TABLE_NAME_PRAYER} from '@/stores/database/database-schema-v1'
import {useDBAdapter} from '@/stores/db-adapter'
import {Reactive} from '@/typings/commen'

const {profileInfo} = useAccountStore()

interface EditingInfo {
    title: string
    description: string
    reminder: ReminderInfo | null
    room: string
}

interface UserCreatorState {
    editingInfo: EditingInfo
    saveToServer: () => void
    updateTimeLabel: Ref<string>
    isInputing: Ref<boolean>
    setInputing: (value: boolean) => void
    backup: () => void
    restoreBackup: () => void
    canSaveToServer: Ref<boolean>
}

export function useCreatorState(props: Reactive<{ group?: Group }>): UserCreatorState {
  /**
     * db adapter
     */
  const dbAdapter = useDBAdapter<Prayer>(TABLE_NAME_PRAYER)

  /**
     * 编辑中的数据
     */
  const editingInfo = reactive<EditingInfo>({
    title: '',
    description: '',
    reminder: null,
    room: props?.group?.groupName || '',
  })
  // data for server save
  const saveToServer = async () => {
    const prayerInfo = {
      groupId: props.group?.groupId,
      title: editingInfo.title,
      description: editingInfo.description,
      nextReminderTime: editingInfo.reminder?.nextReminderTime,
      reminderType: editingInfo.reminder?.remindType,
    }
    const res = await createPrayer(prayerInfo)
    const {groupId, prayer} = res
    const {createTime, prayerId, state, title, uin, updateTime} = prayer
    await dbAdapter.insert({
      avatar: profileInfo.value?.avatar,
      commentStatistic: 0,
      createTime,
      groupId,
      isRead: true,
      name: profileInfo.value?.name,
      prayerId,
      prayerStatistic: 0,
      state,
      title,
      uin,
      updateTime,
    })
    await dbAdapter.store()
    return res
  }

  const canSaveToServer = computed(() => {
    return editingInfo.title.length > 0
            && editingInfo.description.length > 0
  })

  /**
     * UPDATE 3:31 8/24/2021
     */
  const updateTimeLabel = computed(() => {
    return dayjs(Date.now()).format('HH:mm DD/MM/YYYY')
  })

  /**
     * is inputing
     */
  const isInputing = ref(false)
  const setInputing = (val: boolean) => {
    isInputing.value = val
  }

  /**
     * backup
     */
  let backupInfo: EditingInfo
  const backup = () => {
    backupInfo = cloneDeep(editingInfo)
  }
  const restoreBackup = () => {
    Object.assign(editingInfo, backupInfo)
  }

  return {
    editingInfo,
    canSaveToServer,
    saveToServer,
    updateTimeLabel,
    isInputing,
    setInputing,
    backup,
    restoreBackup,
  }
}