export enum Home {
    newPrayer = '创建祷告',
    newPrayerRoom = '创建祷告组',
    groupPrayRooms = '祷告群组',
    newPrayers = '新增{num}条祷告',
    newComments = '新增{num}条评论',
    creator = '群主',
    publicAnsweredPrayer = '公开应许祷告组',
    showMore = '更多',
    createGroupPrayRoom = '创建祷告组',
    createGroupPrayRoomDes = '为你的家庭、教会小组、事工小组等创建一个可以共享祷告的群组',
    createPrayer = '创建您的第一条祷告',
}