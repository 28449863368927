export enum NoticeCenter {
    pageTitle = 'Notification Center',
    commented = 'Commented',
    prayer = 'Prayer',
    liked = 'like',
    replied = 'Replied',
    groupDissolved = '{title} has been dissolved by the team leader',
    groupJoin = '{name} Joined',
    noticeTypeError = 'unsupported notice type',
    prayerBanned = 'Your {title} has been hidden',
    prayerBannedDes = 'Please read the platform rules carefully. If you have any questions, please contact the group leader.',
    prayerReminder = 'It\'s time to pray',
    prayerPublicAnsweredFailed = 'Your answer has not been approved',
    empty = 'Empty',
}