import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import {onBeforeUnmount, onMounted} from 'vue'

export function useStatusState() {
  if (Capacitor.getPlatform() === 'web') {
    return
  }
  onMounted(async () => {
    await StatusBar.setStyle({ style: Style.Dark })
  })

  onBeforeUnmount(async () => {
    await StatusBar.setStyle({ style: Style.Light })
  })
}