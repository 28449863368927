import {StorageSerializers, useLocalStorage} from '@vueuse/core'
import dayjs from 'dayjs'

interface ResetPasswordTime {
    timeStamp: number
    countdown: number
}

const resetPasswordTimeStamp = useLocalStorage<ResetPasswordTime>('resetPasswordTimeStamp', null, {
  deep: true,
  listenToStorageChanges: true,
  serializer: StorageSerializers.object,
})
export const COUNTDOWN_TIME_SECOND = 60

export function useTimestampState() {
  const setTimeStamp = (countdown: number) => {
    resetPasswordTimeStamp.value = {
      timeStamp: dayjs().valueOf(),
      countdown: countdown,
    }
  }
  const getCountdown = () => {
    const nowTimestamp = dayjs().valueOf()
    if (!resetPasswordTimeStamp.value?.timeStamp) return COUNTDOWN_TIME_SECOND
    if (nowTimestamp - resetPasswordTimeStamp.value?.timeStamp >= 60 * 1000) return COUNTDOWN_TIME_SECOND
    const newCountdown = resetPasswordTimeStamp.value.countdown - Math.floor((nowTimestamp - resetPasswordTimeStamp.value?.timeStamp) / 1000)
    if(newCountdown > 0 ) return newCountdown
    return COUNTDOWN_TIME_SECOND
  }
  return {
    setTimeStamp,
    getCountdown,
    resetPasswordTimeStamp,
  }
}