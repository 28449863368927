// import { MediaObject} from '@awesome-cordova-plugins/media/ngx'
// import { Media, MediaObject } from '@ionic-native/media'
import { Ref, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'

// import RecordFile from '@/assets/media/record-file.mp3'
const musicUrls = [
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_awe.mp3',
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_bliss.mp3',
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_hope.mp3',
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_majestic.mp3',
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_peace.mp3',
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_relax.mp3',
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_serenity.mp3',
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_tranquility.mp3',
  'https://s3.us-east-2.amazonaws.com/res.befruitful.goodnewslabs.com/pray-bgaudio/track_ambiance_waves.mp3',
]

interface UseModalState {
  musicUrl: Ref<string>
  isPlayingMusic: Ref<boolean>
  switchMusic: () => void
  switchPlayingState: () => void
  // 音频播放暂时不用插件
  // musicPlay: () => void
  // musicPause: () => void
  // musicStop: () => void
  // musicRelease: () => void
  // musicStartRecord: () => void
  // musicStopRecord: () => void
}


export function useMusicState(audioRef: Ref<HTMLAudioElement>): UseModalState {
  // let musicFile: MediaObject
  // let recordFile: MediaObject
  const musicUrl = ref('')
  const isPlayingMusic = ref(true)
  const switchMusic = () => {
    const randomIndex = Math.floor(Math.random() * musicUrls.length)
    musicUrl.value = musicUrls[randomIndex]
    // musicFile = Media.create(musicUrls[randomIndex]) // 问题可能为创建失败，但是并未抛出任何错误
    // console.log(musicFile) // 有值
  }
  const switchPlayingState = () => {
    isPlayingMusic.value = !isPlayingMusic.value
    if (isPlayingMusic.value) {
      audioRef.value.play()
    }
    else {
      audioRef.value.pause()
    }
  }
  // const musicPlay = ()=>{
  //   // console.log('musicPlay')
  //   // console.log('ccc',musicFile.getDuration()) // 获取时长 undefined
  //   musicFile.play() // 未播放 未报错
  //   musicFile.onSuccess.subscribe(() => console.log('success')) // 未打印
  //   musicFile.onError.subscribe((error ) => console.log('error',error)) // 未打印
  //   musicFile.onStatusUpdate.subscribe((status) => console.log(status)) // 未打印
  // }
  // const musicPause = ()=>{
  //   // console.log('musicPause')
  //   musicFile.pause()
  // }
  // const musicStop = ()=>{
  //   // console.log('musicStop')
  //   musicFile.stop()
  // }
  // const musicRelease = ()=>{
  //   // console.log('musicRelease')
  //   musicFile.release()
  // }
  // const musicStartRecord = ()=>{
  //   recordFile = Media.create(RecordFile)
  //   recordFile.startRecord()
  // }
  // const musicStopRecord = () => {
  //   recordFile.stopRecord()
  // }
  watchEffect(() => {
    if (!audioRef.value) return
    switchMusic()
    nextTick(() => {
      audioRef.value.play()
    })
  })
  onBeforeUnmount(() => {
    audioRef.value.pause()
  })
  return {
    musicUrl,
    isPlayingMusic,
    switchMusic,
    switchPlayingState,
    // musicPlay,
    // musicPause,
    // musicStop,
    // musicRelease,
    // musicStartRecord,
    // musicStopRecord,
  }
}