export enum Button  {
    continueWithEmail = '邮箱注册&登陆',
    signIn = '登录',
    resetPassword = '重置密码',
    prayNow = '祷告',
    cancel = '取消',
    done = '提交',
    confirm = '确认',
    yes = '确认',
    ok = '好的',
    terms = '用户协议',
    privacyPolicy = '隐私政策',
    deleteAccount = '删除',
    resend = '重新发送',
    send = '发送',
    amen = '阿门',
    more = '更多',
    delete = '删除',
    reply = '回复',
    copy = '复制',
    prayAgain = '取消应允',
    markAsAnswered = '标记为应允',
    reportUser = '举报用户',
    reportPrayer = '举报祷告',
    removeFromPublic = '移除出公开组',
    block = '屏蔽',
    unBlock = '取消屏蔽',
    pray = '祷告',
    savePhoto = '保存图片',
    copyLink = '拷贝链接',
    share = '分享',
    skip = '跳过',
    disbandGroup = '解散群组',
    logOut = '退出登录',
    agree = '同意',
    disagree = '不同意',
    download = '下载',
    confirmToJoin = '确定加入',
    open = '打开',
    complete = '完成',
    verifyLater = '稍后验证',
    verifyNow = '立即验证',
}