import {http} from '@/utils/http'

interface GetPresignedInfoReq {
  filename: string
}

export interface GetPresignedInfoRes {
  key: string
  presignedUrl: string
  url: string
}

export const getPresignedInfo = (data: GetPresignedInfoReq): Promise<GetPresignedInfoRes> => {
  return http.get({
    url: '/image/presignedUrl',
    params: data,
  })
}


export interface GetImageIsIllegalReq {
  key: string
}

export const getImageIsIllegal = (data: GetImageIsIllegalReq) => {
  return http.get<boolean>({
    url: '/image/recognition',
    params: data,
  })
}

export async function uploadFile(file: File | Blob, presignedRes: GetPresignedInfoRes): Promise<string> {
  await fetch(presignedRes.presignedUrl, {method: 'PUT', body: file})
  return presignedRes.url
}
