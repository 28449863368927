import _ from 'lodash'

import {ErrorCode} from '@/protobuf/pg'

export const ignoreErrors = [
  'Network Error',
  'timeout error',
]

export const ignoreOriginalException = [
  '{"isTrusted":true}',
  '{"isTrusted":false}',
  '"cancel"',
  '{"promise":{},"reason":false}',
]

const ignoreHttpRequests = [
  ErrorCode.PARAMS_ERROR,
  ErrorCode.NO_ACCOUNT,
  ErrorCode.NOT_LOGIN,
  ErrorCode.LOGIN_FAIL,
  ErrorCode.EMAIL_ERROR,
  ErrorCode.VERIFYCODE_ERROR,
  ErrorCode.ACCOUNT_EXIST,
  ErrorCode.PASSWORD_ERROR,
  ErrorCode.NO_EDIT_PRAYER_PERMISSION,
]

// 获取是否应该阻止错误上报
export function shouldIgnoreReport(hint: any) {
  const message = _.get(hint, 'data.stack.message', '')
  const code = _.get(hint, 'originalException.code', null)
  let originalException
  try {
    originalException = JSON.stringify(hint.originalException)
  } catch (err) {
    originalException = ''
  }
  // 存在code，此错误是 http 请求错误，需要特殊处理
  return ignoreErrors.includes(message) ||
        ignoreHttpRequests.includes(code) ||
        ignoreOriginalException.includes(originalException)
}