import {TimingTaskLoopType} from './pg'

import {useI18n} from '@/i18n/use-i18n'
const {t} = useI18n()
export const reminderTypeMap = new Map<TimingTaskLoopType, string>([
  [TimingTaskLoopType.EVERY_DAY, t('pageContent.prayer.everyDay') ],
  [TimingTaskLoopType.WEEKDAY, t('pageContent.prayer.weekDay')],
  [TimingTaskLoopType.EVER_WEEK, t('pageContent.prayer.everyWeek')],
  [TimingTaskLoopType.EVER_MOUTH_DATE, t('pageContent.prayer.everyMouth')],
  [TimingTaskLoopType.EVER_YEAR, t('pageContent.prayer.everyYear')],
  [TimingTaskLoopType.THREE_MINUTES, t('pageContent.prayer.threeMinutes')],
  [TimingTaskLoopType.NEVER, t('pageContent.prayer.never')],
])
