import { PluginListenerHandle } from '@capacitor/core'

export enum NotificationActionType {
  CLICKED = 0,
  DELETE = 2,
}

export interface TPNSPushNotification {
  msgId: string
  title: string
  content: string
  customContent: string
  pushChannel: number
  templateId: string
  traceId: string
  activityName: string
  actionType: NotificationActionType
  notificationActionType: number
  notificationId: number
  activity: string
  notificationType: string
}

export interface TPNSPlugin {
  /**
   * Register the app to receive push notifications.
   *
   * This method will trigger the `'registration'` event with the push token or
   * `'registrationError'` if there was a problem. It does not prompt the user for
   * notification permissions, use `requestPermissions()` first.
   *
   * @since 1.0.0
   */
  register(): Promise<void>

  /**
   * Called when the push notification registration finishes without problems.
   *
   * Provides the push notification token.
   *
   * @since 1.0.0
   */
  addListener(eventName: 'registration', listenerFunc: (data: {token: string}) => void): Promise<PluginListenerHandle> & PluginListenerHandle
  /**
   * Called when the push notification registration finished with problems.
   *
   * Provides an error with the registration problem.
   *
   * @since 1.0.0
   */
  addListener(eventName: 'registrationError', listenerFunc: (error: any) => void): Promise<PluginListenerHandle> & PluginListenerHandle
  /**
   * Called when the device receives a push notification.
   *
   * @since 1.0.0
   */
  addListener(eventName: 'pushNotificationReceived', listenerFunc: (notification: TPNSPushNotification) => void): Promise<PluginListenerHandle> & PluginListenerHandle

  /**
   * Called when an action is performed on a push notification.
   *
   * @since 1.0.0
   */
  addListener(eventName: 'pushNotificationActionPerformed', listenerFunc: (notification: TPNSPushNotification) => void): Promise<PluginListenerHandle> & PluginListenerHandle
}