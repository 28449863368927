
import {http} from '@/utils/http'

/**
 * 安卓消息返回
 * @returns {Promise}
 */
export const pushMessageAndroidApi = ( ) => {
  return http.get({
    url: '/push/message/android',
  })
}

/**
 * apns消息返回
 * @returns {Promise}
 */
export const pushMessageApnsApi = ( ) => {
  return http.get({
    url: '/push/message/apns',
  })
}

/**
 * 刷新pushToken
 * @returns {Promise}
 */
export const pushRefreshTokenApi = (data: { pushToken: string }) => {
  return http.post({
    url: '/push/refresh/token',
    data,
  })
}