import { Capacitor } from '@capacitor/core'

import {listenPushNotification as initAPNS} from './apns'
import {listenPushNotification as initTPNS} from './tpns'

export async function listenPushNotification() {
  const platform = Capacitor.getPlatform()
  if (platform === 'ios') {
    await initAPNS()
  }
  else if (platform === 'android') {
    await initTPNS()
  }
}
