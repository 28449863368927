enum Placeholder {
    emailAddress = 'E-mail address',
    password = 'Password',
    newPassword = 'New Password',
    nickName = 'please enter a valid name',
    notRequired = 'Not required',
    prayerTitle = 'Enter Prayer Title...',
    prayerDes = 'Add details about your Prayer',
    answeredDes = 'What’s new? what’s changed?',
    groupRoomName = 'Group Room Name',
    groupRoomDes = 'Description',
    groupChangeDes = 'Enter more information here...',
    search = 'Search',
}

enum ErrorMessage {
    enterEmail = 'Enter an email',
    incorrectEmail = 'Incorrect email',
    enterCode = 'Enter a code',
    codeError = 'Use 8-20 characters',
    sameCodeError = 'Please enter the same code',
    enterName = 'please enter a valid name',
    enterGroupName = 'groupName is required',
    groupNameError = 'cannot more than 200 characters',
    groupDesError = 'cannot more than 1000 characters',
}

export const Input = {
  placeholder: Placeholder,
  errorMessage: ErrorMessage,
}