import {computed, ref, watch} from 'vue'

import {clearGroupNewInfoTipsApi, getPrayerGroupListApi} from '@/apis/prayer-group'
import {GroupType} from '@/protobuf/pg'
import {useAccountStore} from '@/stores/account'
import {Group, TABLE_PRIMARYKEY_PRAYER_GROUP} from '@/stores/database/database-schema-v1'
import {usePrayerGroupDBAdapter} from '@/stores/db-adapter/prayer-group'

export function usePrayerGroupListState() {
  /**
   * group list
   */
  const groupList = ref<Group[]>([])
  const hasMoreData = ref(false)
  let pageNumber = 0
  const {profileInfo, accountInfo} = useAccountStore()

  const dbAdapter = usePrayerGroupDBAdapter({
    groupType: GroupType.GROUP_TYPE_NORMAL,
  })

  const queryGroupList = async (limit = 10) => {
    const res = await dbAdapter.query(
      '*',
      {
        keys: 'createTime',
        order: 'DESC',
      },
      {
        offset: 0,
        limit,
      })
    groupList.value = res
  }

  dbAdapter.watch((event, values) => {
    if (event === 'insert') {
      values.forEach(value => {
        groupList.value.unshift(value as Group)
      })
    } else if (event === 'update') {
      groupList.value = groupList.value.map(group => {
        const modifyValue = values.find(value => {
          return value.groupId === group.groupId
        })
        if (modifyValue) {
          group = {...group, ...modifyValue}
        }
        return group
      })
    } else if (event === 'delete') {
      const deletePrayerGroupIds = values.map(value => value.groupId)
      groupList.value = groupList.value.filter(group => !deletePrayerGroupIds.includes(group.groupId))
    } else {
      queryGroupList(groupList.value.length)
    }
  })
  const fetchGroupList = async (reset: boolean) => {
    if (reset) {
      pageNumber = 1
    } else {
      pageNumber += 1
    }

    const res = await getPrayerGroupListApi({
      pageSize: 10,
      pageNumber,
    })
    hasMoreData.value = res.hasNextPage

    if (reset) {
      groupList.value.splice(0, groupList.value.length)
    }
    groupList.value.push(...res.list)
    await dbAdapter.upsertSet(res.list, [TABLE_PRIMARYKEY_PRAYER_GROUP])
    dbAdapter.store()
  }

  const loadData = async (reset: boolean) => {
    if (reset) {
      await queryGroupList()
    }
    await fetchGroupList(reset)
  }

  function clearGroupNewInfoTips(groupId: string) {
    clearGroupNewInfoTipsApi({groupId}).then(() => {
      groupList.value.forEach(group => {
        if (group.groupId === groupId) {
          group.newCommentNum = 0
          group.newPrayerNum = 0
        }
      })
    })
  }

  const firstSectionList = computed(() => {
    return groupList.value.slice(0, 3)
  })
  const secondSectionList = computed(() => {
    return groupList.value.slice(3, groupList.value.length)
  })

  /**
   * 头像更新后
   */
  watch(() => profileInfo.value?.avatar, () => {
    if (groupList.value.length > 0) {
      groupList.value = groupList.value.map(group => {
        group.groupMembers = group.groupMembers?.map(groupMember => {
          if (groupMember.uin === accountInfo.value?.uin && profileInfo.value?.avatar) {
            groupMember.avatar = profileInfo.value?.avatar
          }
          return groupMember
        })
        return group
      })

    }
  })
  return {
    groupList,
    loadData,
    hasMoreData,
    clearGroupNewInfoTips,
    firstSectionList,
    secondSectionList,
  }
}
