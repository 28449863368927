export const Terms = {
  pageTitle: 'General Terms and Conditions for the use of PrayRooms',
  time: 'Active since: March 1, 2022',
  subtitle: 'The following terms and conditions govern the contractual relationship between PrayRooms and its customers, both in regards for people using the service free of charge and as a paying member, regardless of whether you access PrayRooms via its website or mobile apps.\n' +
    '\n' +
    'Please note that for contractual relationships established via third-party providers such as Apple or Google, different regulations apply in some cases. Functions might differ whether the service is accessed via the website or app.\n' +
    '\n' +
    'Persons who have not yet reached the age of 18 may not use this service. The use of this service for commercial purposes is also not allowed.',
  contentList: [
    {
      title: '1 Subject of the contract',
      text: '1.1 During the term of the contract, PrayRooms offers its customers access to an online database that enables them to get to access content and also see other profiles (hereinafter "PrayRooms service"). The PrayRooms service can be accessed exclusively via mobile apps for iOS and Android devices or via the website and includes both free and paid services.\n' +
        '\n' +
        '1.2 The online database provided by PrayRooms contains content and so-called profiles of other people, which are provided by the registered customers with their own content (also referred to as "data"). What data is collected in this context and how this data is visible to other customers is also set out in the data protection notices (see section 4.1).\n' +
        '\n' +
        '1.3 PrayRooms offers its service worldwide. The respective profile of what the customer provides with own data can be visible in all countries, irrespective of the regional website or regional mobile app through which the customer has registered.\n' +
        '\n' +
        '1.4 The subject of the contract with PrayRooms is a social network that offers its customers the opportunity to access content provided by PrayRooms and present their own content and interact with other customers.',
    },
    {
      title: '2 Access & Conclusion of contract',
      text: '2.1 Access to the use of the PrayRooms service requires registration. For this purpose, the customer needs an e-mail address and a password chosen by the customer. A profile ID (user name) and a "password" are automatically created for the customer by PrayRooms after registration and sent to the customer by e-mail.\n' +
        'Other Login options are provided such as Facebook Login and Apple Login or the login with a phone number. The customer can login with their account credentials from these services. When signing up by phone number, the customer should ensure its validity to receive codes via SMS text messages e.g. the initial login password.\n' +
        '\n' +
        '2.2 With the registration initially a basically free contractual relationship between PrayRooms and the registered customer (also called "basic membership").\n' +
        '\n' +
        '2.3 In order to use the PrayRooms service, it is necessary provide of personal data such as the first name, date of birth. Only with a registered account, the profile is able to access the data base. Some of these details can be changed after profile creation only with the help of PrayRooms customer service.\n' +
        '\n' +
        '2.4 PrayRooms offers the customer various chargeable services. This includes in particular the "Premium Membership". The customer can choose in the ordering process between different initial durations. Credit for the activation of individual profiles is provided in the basic membership both free of charge and subject to a charge, insofar as this feature is available. The customer will be informed again about the content of the respective chargeable service, the prices and the terms of payment before concluding a chargeable contractual relationship. By pressing the button “Buy Now", the customer declares that he wishes to conclude a contract for the chargeable services selected by him.\n' +
        '\n' +
        '2.5 PrayRooms also enables its customers to use paid services via the payment platforms Apple and Google Play (so-called "in-app purchases"). The third-party providers have prescribed sets of rules for the conclusion of the contract, the payment as well as the termination of the contractual relationship, which the customer accepts via the terms of use of the third-party provider and over which PrayRooms has no influence. If the customer decides to conclude a contract with PrayRooms via the third party provider, it is an obligation of the customer to comply with the regulations prescribed in the terms of use of the third party provider for the conclusion, payment and termination of contracts. In case of any contradictions between the regulations of the third party provider and these General Terms and Conditions of PrayRooms, the regulations of the third party provider take precedence. In particular, the customer will be asked by the third party provider to confirm his purchase to him. The payment method is then the one that the customer has agreed with the third-party provider (e.g. the customer pays at Apple via the payment method stored in his Apple ID). If the customer wishes to change or terminate an in-app purchased Premium Membership, it is necessary to do so via his account with the third-party provider, log in there and follow the third-party provider\'s instructions on the termination, revocation or cancellation. Any refunds will be made either in the relationship of the third party provider with the customer or in the relationship of the customer with PrayRooms, depending on the rules and regulations of the third party provider.',
    },
    {
      title: '3 Chargeable and Free of Charge Services',
      text: 'PrayRooms offers free services and the option to upgrade the account to get extended access to content and further people.',
    },
    {
      title: '4 Data protection notices & Security',
      text: '4.1 Further information on how personal data is processed and what rights the customer has in relation to data protection can be found in the data protection notices.\n' +
        '\n' +
        '4.2 PrayRooms is constantly striving to protect its customers from harassment such as hacker attacks, spam or romance scamming. For this purpose, PrayRooms has developed numerous technical and organizational standards to detect violations of the usage guidelines. PrayRooms is in this respect also entitled to bring suitable filter or validation software to use. An obligation to use such software does not exist. It is the responsibility of the customer to familiarize himself with the security tips of PrayRooms before using the PrayRooms services. The customer should also forward problematic content or behavior to PrayRooms via the the respective customer service representatives.\n' +
        '\n' +
        '4.3 PrayRooms is not obligated to carry messages if there is a suspicion that they violate the usage guidelines and are therefore abusive. In particular, there is no obligation to transport messages that are unsolicited advertising messages or there is a corresponding suspicion.',
    },
    {
      title: '5 Termination & Extension',
      text: '5.1 The customer has the right to terminate the contract for the free basic membership with immediate effect at any time. The termination of the basic membership is effective by deleting the profile in the app or website and the confirmation of this process by entering the personal password.\n' +
        '\n' +
        '5.2 The customer has the right to terminate the contract for the paid premium membership properly at the end of the initial term. The ordinary termination of the paid Premium Membership shall only take effect at the end of the agreed initial term (and as such is not refundable). The period for the ordinary termination of the paid premium membership at the end of the initial term results for the order via PrayRooms from the product-related contract contents, which are confirmed by the customer during the ordering process on the order page. When ordering a premium membership, the customer is also informed of the cancellation period in the order confirmation by e-mail. When ordering a premium membership via third-parties (App Store or Google Play) it is up to the customer to check auto-renewals and membership durations in their phone settings and to cancel auto renewals before expiration dates to avoid any unwanted costs.\n' +
        '\n' +
        '5.3 If the customer has concluded his Premium Membership via third-party providers such as Apple or Google Play, the termination of the contractual relationship is governed by what has been said in section 2.5. If the customer wishes to terminate his in-app purchased Premium Membership, it is necessary to do so via his account with the third party provider, log in there and follow the third party provider\'s instructions for termination. Details on how to terminate a Premium Membership via third-party providers can be found here for Apple and here for Google Play. The ordinary termination of the paid membership via third-party providers also does not automatically lead to the termination of the basic membership.\n' +
        '\n' +
        '5.4 When an order is placed via third-parties for PrayRooms, the contract for the paid premium membership can be auto-renewal and will be automatically extended for an indefinite period of time, unless the customer terminates his contract in accordance at the end of the initial term in compliance with the notice period. After the contract has been extended, payment shall be made at the agreed price monthly in advance, whereby the Premium Membership can be terminated at any time with one month\'s notice. When ordering a Premium Membership, the customer will also be informed immediately before submitting his order and in the order confirmation about the circumstance and conditions of a possible extension in case of untimely termination.\n' +
        '\n' +
        '5.5 In the event that the Premium Membership is concluded via third-party providers such as Apple or Google Play, the conditions of any automatic renewal shall result from the respective offer, the regulations in the third-party provider\'s account and the order confirmation received by the customer upon conclusion of the contract.\n' +
        '\n' +
        '5.6 Termination of the paid membership does not automatically lead to termination of the free membership. In this case, the procedure set forth in Section 5.1 shall apply.',
    },
    {
      title: '6 Liability of PrayRooms',
      text: '6.1 PrayRooms can not be held responsible for incorrect information in the applications of customers. It follows that PrayRooms can not assume any liability for the accuracy of the results of the PrayRooms questionnaire as well as the contents of the portrait of the partnership personality created on its basis.\n' +
        '\n' +
        '6.2 PrayRooms owes only the provision of IT services for the automatic mediation of contacts, not the success. PrayRooms provides only the technical device that generally enables a contact.\n' +
        '\n' +
        '6.3 PrayRooms also assumes no liability for the possible misuse of information. It is possible that customers use the PrayRooms service in an inadmissible or unlawful manner despite the prohibition. For such inadmissible or illegal use, any liability of PrayRooms is excluded. PrayRooms is also not liable for the misuse of data and information that the customers themselves have made available to third parties.\n' +
        '\n' +
        '6.4 PrayRooms does not guarantee the proper operation at all times or the uninterrupted usability or accessibility of the service. In particular, PrayRooms is not liable for disruptions in the quality of access to the Service due to force majeure or events for which PrayRooms is not responsible. Furthermore, PrayRooms is not liable for the unauthorized knowledge of third parties of personal data of customers (eg by unauthorized access by "hackers" to the database).',
    },
    {
      title: '7 Responsibility and obligations of the customer',
      text: '7.1 The customer is obliged to make all payments due to PrayRooms or the third-party providers (see in this respect section 2.5) on time. If the customer is culpably in arrears with more than two consecutive partial amounts (if partial payments are agreed), all outstanding payment claims up to the next possible termination date are due immediately. In the event of a delay in payment, PrayRooms is entitled to temporarily block access to the PrayRooms service.\n' +
        '\n' +
        '7.2 The customer is solely responsible for the content of his registration and thus for the information he provides about himself. The customer assures that the data provided is true and describes him personally. False information provided intentionally and/or fraudulently or the impersonation of a different or fictitious identity may also result in civil action.\n' +
        '\n' +
        '7.3 The customer further assures that he uses the PrayRooms service exclusively for private purposes, has no business intentions and does not use the data of other members entrusted to him for commercial or advertising purposes. In particular, it is not permitted to systematically read the contents of other users (e.g. by means of a program or by mere copying) in order to use them outside the PrayRooms service. In this respect, the use of computer programs for the automatic reading of files, such as crawlers, is also prohibited.\n' +
        '\n' +
        '7.4 The customer agrees to hold PrayRooms harmless from any kind of lawsuits, damages, losses or claims that may arise from his registration and / or participation in this service, provided that the customer has acted culpably. This applies in particular to damages due to defamation, insult, violation of personal rights, due to the failure of services for other customers, a violation of these terms and conditions, due to the violation of intellectual property or other rights. The claim for reimbursement of expenses shall be limited in amount to the necessary expenses or the expenses required for a specific purpose.\n' +
        '\n' +
        '7.5 The customer is obligated to treat e-mails and other messages confidentially and not to make them accessible to third parties without the consent of their author or without good cause. The same applies to names, telephone and fax numbers, residential addresses, e-mail addresses and/or URLs or other personal data of other customers.\n' +
        '\n' +
        '7.6 The customer agrees to notify PrayRooms immediately if he is no longer interested in receiving the service because of the conclusion of a partnership or for other reasons or if he no longer wants to be available to other customers as a receiver.\n' +
        '\n' +
        '7.7 The customer is required to retrieve the messages received for him as well as the data of his profile at regular and reasonable intervals and, if necessary, to archive them on his own computer or other storage media. PrayRooms is entitled to delete the messages stored in the account of the customer in each case after twelve months after sending or receiving without further inquiry. All data within the scope of a free membership will be automatically deleted after twenty-four months after the last login of the customer.\n' +
        '\n' +
        '7.8 Furthermore, each Customer undertakes not to misuse the Service, in particular:\n' +
        '•Not to disseminate any immoral, obscene, pornographic or right-wing/left-wing radical content or photos in the profile or video chat;\n' +
        '•not to distribute any defamatory, offensive or otherwise unlawful material or information through the Service;\n' +
        '•not to threaten or harass other persons or violate the rights (including personal rights) of third parties;\n' +
        '•not to upload data containing a virus (infected software) or software or other material protected by copyright, unless the Customer has the rights thereto or the necessary consents;\n' +
        '•not to use the Service in a way that adversely affects the availability of the offers to other customers;\n' +
        '•not to intercept or attempt to intercept any emails / messages;\n' +
        '•Not to send e-mails / messages (including video chat messages) to customers for any purpose other than communication and, in particular, not to advertise or offer goods or services to other customers (except in cases where this is expressly permitted by PrayRooms);\n' +
        '•not to send e-mails / messages (including video chat messages) to customers with an unreasonably high or harassing frequency, as well as to send initial messages to an unreasonably high number of customers at short intervals; in this regard, PrayRooms reserves the right to set certain limits by technical means that are necessary to ensure the security and integrity of the platform;\n' +
        '•not to create message texts by "copy and paste" function or to send chain letters;\n' +
        '•not to send messages that serve a commercial purpose;\n' +
        '•to maintain the required distance in initial messages and contact requests and therefore not to provide them with contact details such as full names, addresses, telephone or fax numbers, e-mail addresses, etc;\n' +
        '•not to make initial messages or contact requests unreasonably short or long; PrayRooms reserves the right to technically determine certain minimum and maximum lengths of messages by means of technical specifications.\n' +
        '\n' +
        '7.9 Failure to comply with any of the behavioral obligations set out in paragraphs 7.1 - 7.5 and 7.8 may result in the following sanctions: \n' +
        '•Request for a statement in the event of suspected breaches of contract / law, blocking of use until facts are clarified (especially in the event of multiple complaints from other customers), \n' +
        '•warning of a customer, restriction / limitation in the use of applications in the PrayRooms service, partial deletion of content. \n' +
        '\n' +
        'Failure to comply with the behavioral obligations set out in sections 7.1 - 7.5 and 7.8 may also lead to immediate termination of the user agreement as well as civil and criminal consequences for the customer himself. The blocking of an access does not change the customer\'s obligation to pay for ordered services.',
    },
    {
      title: '8 Communication',
      text: 'PrayRooms is an online service. As such, PrayRooms communicates with its customers either via the in-app messenger system when logged in or via email (e.g. support, sending partner proposals, reminders). Telephone, fax and mail by post are excluded from communication between the company and the user. For clear authentication and protection against misuse, your messages should contain one the following detail so that we can assign you to your account, depending what applies to you: E-mail address, Facebook email address, Apple ID email address, or phone number registered with. PrayRooms sends to all of its registered users unpersonalized messages of informational and promotional content. This can be within the PrayRooms platform (PrayRooms News) or to the linked Email address or phone number in the for of SMS messages. Users can specify the types of messages they want to receive in their settings.',
    },
    {
      title: '9 Rights of Use and Copyrights',
      text: 'In relation to you, PrayRooms is the sole legal owner of the reproduction, distribution, processing and all copyrights as well as the right of intangible transmission and reproduction of the PrayRooms website and the individual content contained therein. The use of all programs and the content, materials, brand and trade names contained therein is only permitted for the purposes stated in these terms and conditions.',
    },
    {
      title: '10 Links',
      text: 'This website and the PrayRooms services may contain links to sites that are not affiliated with PrayRooms. The inclusion of a link on this website does not imply our endorsement of the linked site. If you decide to access linked third-party websites, you do so at your own risk. Despite careful control of the content, we don’t take any liability for the content of external links. The operators of linked pages are solely responsible for their content.',
    },
    {
      title: '11 Applicable law, alternative dispute resolution',
      text: 'This contract is subject to the law of the People’s Republic of China in its Special Administrative Region of Hong Kong.\n' +
        'PrayRooms does not participate in dispute settlement procedures before consumer arbitration boards. In this respect, there is no legal obligation.',
    },
    {
      title: '12 Disclaimers',
      text: 'PrayRooms is not responsible for any incorrect or inaccurate content posted on the website or in connection with the service, regardless of whether it is caused by users of the website, members or by devices or programs connected to the service or used in this service.\n' +
        'PrayRooms is not responsible for the online or offline behavior of a user of the website or a member of the service.\n' +
        'PrayRooms is not responsible for errors, interruptions, deletions, defects, operating or transmission delays, communication line failure, theft or destruction, or unauthorized access to or changes in user or member communications.\n' +
        'PrayRooms is not responsible for problems or technical malfunctions in telephone networks or lines, online computer systems, servers or providers, computer equipment, software, email or player failure due to technical problems or traffic jams on the Internet or on websites or a combination thereof, including injuries or damage to users and / or members or someone else\'s computer related to or resulting from participation or downloading of materials related to the website and / or service.\n' +
        'In no event will PrayRooms be responsible for any loss or damage, including personal injury or death, arising from the use of the website or the service, content published on the website or transmitted to members, or interactions or contacts between users of the website, whether online or offline.',
    },
    {
      title: '13 Additional',
      text: '13.1 PrayRooms is entitled to commission third service providers and vicarious agents to provide parts or the entire range of services.\n' +
        '13.2 Should a part of the contract or the general terms and conditions become or will be ineffective or should the contract be incomplete, the remaining content of the contract will not be affected. The statutory provisions also apply.\n' +
        '13.3 The contract language is English.\n' +
        '13.4 As part of the conclusion of the contract, the contract text is not automatically saved by PrayRooms. The customer is therefore obliged to save the contractual text that is valid at the time of the conclusion of the contract and is accessible on the PrayRooms website on his hard drive or to archive it in any other way.\n' +
        'This contract can be change by PrayRooms at any time. If you are a PrayRooms member (“basic” or “premium”) at the time of any change, the revised terms will be effective upon posting on the website and your use of the PrayRooms services. By using the services on the PrayRooms website or app, you are accepting all of PrayRooms’s terms or revised terms and conditions. Contact us at xx@PrayRooms.com if you have any further questions.',
    },
  ],

}
