import dayjs from 'dayjs'
import {Ref, computed, onMounted, ref} from 'vue'

import {createPrayer, getPrayerDetailApi} from '@/apis/prayer'
import {TimingTaskLoopType} from '@/protobuf/pg'
import {useAccountStore} from '@/stores/account'
import {
  Group,
  Prayer,
  ReminderInfo,
  TABLE_NAME_PRAYER,
  TABLE_PRIMARYKEY_PRAYER
} from '@/stores/database/database-schema-v1'
import {useDBAdapter} from '@/stores/db-adapter'
import {useOperationLimitState} from '@/views/prayer/prayer-detail/use-operation-limit-state'

interface Props {
    prayerId: string
    group: Ref<Group>
}

interface PrayerInfo extends Prayer {
    reminder: ReminderInfo
}

export function usePrayerDetailState(props: Props) {
  const dbAdapter = useDBAdapter<Prayer>(TABLE_NAME_PRAYER, {
    prayerId: props.prayerId,
  })

  const isLoadingPrayer = ref(true)
  const prayerInfo = ref<PrayerInfo>()

  const queryPrayerDetail = async () => {
    const res = await dbAdapter.query('*')
    if (res.length === 0) return

    const prayer = res[0]
    prayerInfo.value = {
      ...prayer,
      reminder: {
        remindType: prayer.reminderType || TimingTaskLoopType.NEVER,
        nextReminderTime: prayer.nextReminderTime || 0,
      },
    }
  }

  const getPrayerDetail = async () => {
    try {
      const res = await getPrayerDetailApi({prayerId: props.prayerId})
      prayerInfo.value = {
        ...res.prayer,
        groupId: res.groupId,
        isPublic: res.isPublic,
        prayerCommentCount: res.prayerCommentCount,
        prayerStatistic: res.prayerCount,
        reminderType: res.reminder?.remindType,
        nextReminderTime: res.reminder?.nextReminderTime,
        groupMemberCount: res.groupMemberCount,
        reminder: {
          remindType: res.reminder?.remindType !== undefined ? res.reminder?.remindType : TimingTaskLoopType.NEVER,
          nextReminderTime: res.reminder?.nextReminderTime || 0,
        },
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {reminder: ignore, ...prayer} = prayerInfo.value
      await dbAdapter.upsertSet([prayer], [TABLE_PRIMARYKEY_PRAYER])
      await dbAdapter.store()
    } finally {
      isLoadingPrayer.value = false
    }
  }

  const isCanEditPrayer = computed(() => {
    if (!prayerInfo.value?.uin) return false
    return useOperationLimitState({
      prayerUin: prayerInfo.value.uin,
      groupUin: props.group.value.creatorUin,
    }).isCanEditPrayer
  })

  const updateTime = computed(() => {
    if (!prayerInfo.value) return ''
    return dayjs(prayerInfo.value.updateTime).format('HH:mm MM/DD/YYYY')
  })

  const updateReminder = async (reminder: ReminderInfo) => {
    if (!prayerInfo.value) return

    prayerInfo.value.reminder = reminder
    prayerInfo.value.reminderType = reminder.remindType
    prayerInfo.value.nextReminderTime = reminder.nextReminderTime

    await dbAdapter.update({
      prayerId: prayerInfo.value.prayerId,
    }, {
      reminderType: reminder.remindType,
      nextReminderTime: reminder.nextReminderTime,
    })
  }
  /**
     * deletePrayer
     */
  const deletePrayer = async () => {
    await dbAdapter.deleteOne({prayerId: props.prayerId})
    await dbAdapter.store()
  }

  /**
     * updatePrayer
     * @param change
     */
  const updatePrayer = async (change: Partial<Omit<Prayer, 'prayerId'>>) => {
    await dbAdapter.update({prayerId: props.prayerId}, change)
    await dbAdapter.store()
  }

  /**
     * copyPrayer
     * @param change
     */
  const copyPrayer = async () => {
    const {profileInfo} = useAccountStore()
    if (prayerInfo.value?.title && prayerInfo.value?.description) {
      const res = await createPrayer({
        title: prayerInfo.value?.title,
        description: prayerInfo.value?.description,
      })
      await dbAdapter.insert({
        prayerId: res.prayer.prayerId,
        title: prayerInfo.value?.title,
        description: prayerInfo.value?.description,
        groupId: res.groupId,
        createTime: res.prayer?.createTime,
        updateTime: res.prayer?.updateTime,
        name: profileInfo.value?.name,
      })
      await dbAdapter.store()
    }
  }

  const initData = async () => {
    await queryPrayerDetail()
    await getPrayerDetail()
  }
  // onMounted(async () => {
  //   await initData()
  // })

  return {
    initData,
    isLoadingPrayer,
    isCanEditPrayer,
    prayerInfo,
    getPrayerDetail,
    updateTime,
    updatePrayer,
    updateReminder,
    deletePrayer,
    copyPrayer,
  }
}
