import {StorageSerializers, useLocalStorage} from '@vueuse/core'
import {Ref, computed} from 'vue'

import {Account, Profile} from '@/stores/database/database-schema-v1'

export interface AccountStore {
  token: Ref<string | null>
  accountInfo: Ref<Account | null>
  profileInfo: Ref<Profile | null>
  isLoggedIn: Ref<boolean>
  saveAccountInfo: (accountInfo: Account) => void
  saveProfileInfo: (profileInfo: Profile) => void
  updateProfileInfo: (profileInfo: Partial<Profile>) => void
  updateAccountInfo: (accountInfo: Partial<Account>) => void
  clear: () => void
}

const token = useLocalStorage<string | null>('token', null, {
  listenToStorageChanges: true,
})
const accountInfo = useLocalStorage<Account>('accountInfo', null, {
  deep: true,
  listenToStorageChanges: true,
  serializer: StorageSerializers.object,
})
const profileInfo = useLocalStorage<Profile>('profileInfo', null, {
  deep: true,
  listenToStorageChanges: true,
  serializer: StorageSerializers.object,
})

export function useAccountStore(): AccountStore {
  // 使用 useDBAdapter 函数操作数据库
  // dataList 是响应式的, 修改数据库之后会重新执行query, 从而更新dataList

  const isLoggedIn = computed(() => {
    return !!token.value
  })

  function saveAccountInfo(account: Account) {
    token.value = account.token
    accountInfo.value = account
  }

  function saveProfileInfo(profile: Profile) {
    profileInfo.value = profile
  }

  function updateProfileInfo(profile: Partial<Profile>) {
    Object.assign(profileInfo.value, {
      ...profile,
    })
  }

  function updateAccountInfo(account: Partial<Account>) {
    Object.assign(accountInfo.value, {
      ...account,
    })
  }

  function clear() {
    token.value = null
    accountInfo.value = null
    profileInfo.value = null
  }

  return {
    token,
    accountInfo,
    profileInfo,
    isLoggedIn,
    saveAccountInfo,
    saveProfileInfo,
    updateProfileInfo,
    updateAccountInfo,
    clear,
  }
}