export enum PrayerGroup {
    createGroupTitle = '创建祷告群组',
    createGroupDes = '为你的家庭、教会小组、事工小组等创建一个可以共享祷告的群组',
    answered = '应许的祷告',
    alleluia = 'alleluia',
    newAnsweredTips = '有新的应许祷告产生',
    groupChangeInfoTitle = '修改群介绍',
    groupChangeNameTitle = '修改群组名称',
    groupChangeInfoTips = '修改群介绍后，将在群内通知其他成员',
    groupChangeNameTips = '修改群名称后，将在群内通知其他成员',
    deleteMembersPageTitle = '删除成员',
    groupSettingsPageTitle = '群组设置',
    groupPhotoLabel = '群组头像',
    groupNameLabel = '群组名称',
    groupInfoLabel = '群组信息',
    disbandGroupTitle = '禁用这个群组吗',
    groupShareTips1 = '将此链接发送给被邀请的用户',
    groupShareTips2 = '链接有效期14天',
    groupInviteTitle = '邀请家庭或朋友加入吧',
    groupInviteLinkShare = '分享链接',
    groupInviteEmail = '邮箱',
    groupInviteSuccessTips1 = '成功加入',
    groupInviteSuccessTips2 = '为我们的家人祈祷',
    publicAnsweredGroupName = '公开应许祷告',
    publicAnsweredGroupDes = '被应允的祷告会被记录在这里。让我们为神的慈爱欢呼！',
    publicAnsweredGroupCreator= '',
    myPrayRoom = '个人祷告组',
    myPrayRoomDes = '这里你的私人祷告空间。让我们更加殷勤的祷告，并记录圣灵里的感动！',
    groupInviteJoin = '{name} 邀请您加入'
}
