const UPDATE_MODEL_EVENT = 'update:modelValue'
const CHANGE_EVENT = 'change'
const INPUT_EVENT = 'input'
const FOCUS_EVENT = 'focus'
const BLUR_EVENT = 'blur'
const KEYDOWN_EVENT = 'keydown'
const CLICK_EVENT = 'click'
const CANCEL_EVENT= 'cancel'
const CONFIRM_EVENT= 'confirm'

export const MAX_PAGE_SIZE = 1000

export {
  CHANGE_EVENT,
  INPUT_EVENT,
  UPDATE_MODEL_EVENT,
  FOCUS_EVENT,
  BLUR_EVENT,
  KEYDOWN_EVENT,
  CLICK_EVENT,
  CANCEL_EVENT,
  CONFIRM_EVENT
}
