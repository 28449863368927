export enum Home {
    newPrayer = 'New Prayer',
    newPrayerRoom = 'New Pray Room',
    groupPrayRooms = 'Group Pray Rooms',
    newPrayers = 'New Prayers {num}',
    newComments = 'New Comments {num}',
    creator = 'Creator',
    publicAnsweredPrayer = 'Public Answered Prayer',
    showMore = 'Show More',
    createGroupPrayRoom = 'Create a Group Pray Room',
    createGroupPrayRoomDes = 'Create a room for your family, church group, ministry group, etc. to share prayers',
    createPrayer = 'Create your first prayer',
}