export enum Toast {
    refreshFail = 'Refresh Fail',
    enterContent = 'Please enter the content',
    enterPrayerTitle= 'Please enter a title',
    enterGroupName= 'Please enter a valid group name',
    enterEmail= 'Please enter a valid email address',
    lastOne= 'Last One',
    deleteSuccessfully= 'Delete successfully',
    answeredSuccessfully= 'Answered successfully',
    copySuccessfully= 'Copy successfully',
    blockSuccessfully= 'Block successfully',
    unBlockSuccessfully= 'Cancel block successfully',
    cancelSuccessfully= 'Cancel successfully',
    removeSuccessfully= 'Remove successfully',
    editSuccessfully= 'Edit successfully',
    reportSuccessfully= 'Report successfully',
    linkCopiedSuccessful= 'Link Copied Successful',
    linkCopiedFailed= 'Link Copied Failed',
    photoSavedSuccessful= 'Photo Saved Successful',
    canNotSavePhoto= 'Can\'t Save Photo',
    canNotSupportSocialShare= 'Can\'t Support Social Share',
    sharePrayer= 'Share Prayer',
    groupSaveSuccessful= 'Save Successful, Invite friends join in',
    groupInviteSuccessful = 'Invite {email} successfully',
    modifySuccessfully = 'Modify successfully',
    sendSuccessfully = 'Send successfully',
    setSuccessfully = 'Set successfully',
    pleaseCheckTime = 'Please check time',
}