import { DBLimit, DBOrder } from './index.type'

function getConditionStatement<T>(condition?: Partial<T> | string) {
  const statement = !condition
    ? ''
    : typeof condition === 'string'
      ? condition
      : Object.entries(condition).reduce((pre, [key, value]) => {
        if (typeof value === 'undefined') return pre
        if (typeof value === 'string') {
          return (pre = `${pre} AND ${key}='${value}'`)
        } else {
          return (pre = `${pre} AND ${key}=${value}`)
        }
      }, '')
  return statement.startsWith(' AND')
    ? statement.substring(4, statement.length)
    : statement
}

function getOrderStatement<T>(order?: DBOrder<T>) {
  const statement = !order
    ? ''
    : typeof order.keys === 'string'
      ? `${order.keys} ${order.order}`
      : `${(order.keys as Array<string>).join(',')} ${order.order}`
  return statement
}

function getLimitStatement(limit?: DBLimit) {
  const statement = !limit ? '' : `LIMIT ${limit.limit} OFFSET ${limit.offset}`
  return statement
}

function getSelectStatement(select: Array<string> | '*') {
  const statement = typeof select === 'string' ? select : select.join(',')
  return statement
}

function deleteUndefined(obj: any) {
  const newObj = JSON.parse(JSON.stringify(obj))
  for (const objKey in newObj) {
    if (typeof obj[objKey] === 'undefined') {
      delete obj[objKey]
    }
  }
  return newObj
}

/**
 * 生成 sql 语句的helper
 */
export class SQLStatement<T> {
  statement: string
  values: any[]
  tableName: string

  constructor(tableName: string) {
    this.statement = ''
    this.tableName = tableName
    this.values = []
  }

  query(select: Array<string> | '*') {
    const selectStr = getSelectStatement(select)
    this.statement = `SELECT ${selectStr} FROM ${this.tableName}`
    return this
  }

  insert(obj: Partial<T>, forSet?: boolean) {
    obj = deleteUndefined(obj)
    const keys = Object.keys(obj).join(',')
    if (forSet) {
      const valSymbol: string[] = []
      const values = Object.values(obj).map(value => {
        valSymbol.push('?')
        switch (typeof value) {
        case 'string':
        case 'number':
          return value
        case 'boolean':
          return value ? 1 : 0
        case 'object':
          if (value === null) return value
          else return `'${JSON.stringify(value)}'`
        default:
          throw new Error(`不支持的数据类型 ${typeof value} ${JSON.stringify(obj)}`)
        }
      })
      this.statement = `INSERT INTO ${this.tableName} (${keys}) VALUES (${valSymbol.join(',')})`
      this.values = values
    }
    else {
      const values = Object.values(obj).map(value => {
        switch (typeof value) {
        case 'string':
          return `"${value}"`
        case 'number':
          return value
        case 'boolean':
          return value ? 1 : 0
        case 'object':
          if (value === null) return value
          else return `'${JSON.stringify(value)}'`
        default:
          throw new Error(`不支持的数据类型 ${typeof value} ${JSON.stringify(obj)}`)
        }
      })
      this.statement = `INSERT INTO ${this.tableName} (${keys}) VALUES (${values.join(',')})`
      this.values = []
    }
    return this
  }

  update(changes: Partial<T>, identifier?: string[]) {
    changes = deleteUndefined(changes)
    const values: string[] = []
    for (const [key, value] of Object.entries(changes)) {
      if (identifier?.includes(key)) continue
      switch (typeof value) {
      case 'string':
        values.push(`${key}="${value}"`)
        break
      case 'number':
        values.push(`${key}=${value}`)
        break
      case 'boolean':
        values.push(`${key}=${value ? 1 : 0}`)
        break
      case 'object':
        if (value === null) values.push(`${key}=${value}`)
        else values.push(`${key}='${JSON.stringify(value)}'`)
        break
      default:
        throw new Error(`不支持的数据类型 ${typeof value}`)
      }
    }
    if (identifier) {
      this.statement = `UPDATE SET ${values.join(',')}`
    }
    else {
      this.statement = `UPDATE ${this.tableName} SET ${values.join(',')}`
    }
    return this
  }

  upsertSet(obj: Partial<T>, identifier: string[]) {
    obj = deleteUndefined(obj)
    const insertStatement = new SQLStatement<T>(this.tableName)
    const insertSta = insertStatement.insert(obj, true).statement
    const updateSta = this.update(obj, identifier).statement
    this.statement = `${insertSta} ON CONFLICT(${identifier.join(',')}) DO ${updateSta}`
    this.values = insertStatement.values
    return this
  }

  delete() {
    this.statement = `DELETE FROM ${this.tableName}`
    return this
  }

  condition(condition?: Partial<T> | string) {
    const conditionStr = getConditionStatement(condition)
    if (conditionStr) {
      this.statement += ` WHERE ${conditionStr}`
    }
    return this
  }

  order(order?: DBOrder<T>) {
    const orderStr = getOrderStatement(order)
    if (orderStr) {
      this.statement += ` ORDER BY ${orderStr}`
    }
    return this
  }

  limit(limit?: DBLimit) {
    const limitStr = getLimitStatement(limit)
    if (limitStr) {
      this.statement += ` ${limitStr}`
    }
    return this
  }

  value() {
    // console.log('nate-log statement:', this.statement, this.values)
    return this.statement
  }
}
