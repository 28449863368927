import {ErrorCode} from '@/protobuf/pg'

export const ErrorTips = {
  [ErrorCode.SUCCESS]: 'success',
  [ErrorCode.FAIL]: 'fail',
  [ErrorCode.UNKNOWN_ERROR]: 'unknown error',
  [ErrorCode.PARAMS_ERROR]: 'param error',
  [ErrorCode.NO_ACCOUNT]: 'account not exist',
  [ErrorCode.NOT_LOGIN]: 'not login',
  [ErrorCode.NOT_FOUND_IN_DB]: 'not found in DB',
  [ErrorCode.INTERNAL_ERROR]: 'internal server error',
  [ErrorCode.NO_TOKEN]: 'no token',
  [ErrorCode.TOKEN_INVALID]: 'token invalid',
  [ErrorCode.TOKEN_REDIS_NOT_FOUND]: 'token not found token in redis',
  [ErrorCode.TOKEN_EXPIRED]: 'token expired',

  [ErrorCode.LOGIN_FAIL]: 'Use 8 or more characters with a mix of letters; numbers & symbols.',
  [ErrorCode.EMAIL_ERROR]: 'This email address is not valid',
  [ErrorCode.VERIFYCODE_ERROR]: 'code invalid',
  [ErrorCode.ACCOUNT_DISABLED]: 'account disabled',
  [ErrorCode.PERMISSION_TOKEN_EXPIRED]: 'permission token expired',
  [ErrorCode.ACCOUNT_EXIST]: 'account exist',
  [ErrorCode.PASSWORD_ERROR]: 'password error',
  [ErrorCode.ACCOUNT_VERIFIED]: 'account had verified',
  [ErrorCode.ACCOUNT_VERIFY_ERROR]: 'This link is no longer valid',
  [ErrorCode.ACCOUNT_DELETED]: 'account was disabled',

  [ErrorCode.IMAGE_AUTO_VERIFY_OVERDUE]: 'image auto verify result overdue',
  [ErrorCode.IMAGE_INDEX_INVALID]: 'image index invalid',
  [ErrorCode.DB_INSERT_PROFILE]: 'fail to create profile',
  [ErrorCode.DB_UPDATE_PROFILE]: 'fail to update profile',
  [ErrorCode.DB_PROFILE_NOT_FOUND]: 'profile not found',

  [ErrorCode.AMAZON_S3_UPLOAD]: 'fail to upload file',

  [ErrorCode.SEND_EMAIL_ERROR]: 'send email error',

  [ErrorCode.NO_EDIT_PRAYER_PERMISSION]: 'The user has no edit prayer permission',
  [ErrorCode.EDIT_PRAYER_ERROR]: 'Edit prayer failed',
  [ErrorCode.DELETE_PRAYER_ERROR]: 'Delete prayer failed',
  [ErrorCode.PUBLIC_PRAYER_ERROR]: 'public prayer failed',
  [ErrorCode.PRAYER_NOT_IN_GROUP]: 'Prayer is not in the group',
  [ErrorCode.NO_GET_PRAYER_PERMISSION]: 'The user does not have access to the prayer',
  [ErrorCode.PRAYER_NOT_EXIST]: 'Prayer does not exist',

  [ErrorCode.DB_INSERT_COMMENT]: 'fail to create comment',
  [ErrorCode.DB_UPDATE_COMMENT]: 'fail to update comment',
  [ErrorCode.DB_GET_COMMENT]: 'fail to get comment',
  [ErrorCode.DB_NO_COMMENT]: 'found no comment',
  [ErrorCode.REPEAT_LIKE]: 'repeat like',

  [ErrorCode.GROUP_EDIT_NOT_FOUND]: 'This group doesn\'t belong to you',
  [ErrorCode.DB_UPDATE_GROUP]: 'fail to update group',
  [ErrorCode.DELETE_GROUP_ERROR]: 'Delete group fail',
  [ErrorCode.NOT_FOUND_GROUP]: 'This group not found',
  [ErrorCode.USER_ALREADY_IN_GROUP]: 'User has benn in the group',
  [ErrorCode.USER_IN_GROUP_FAIL]: 'User in the group failed',
  [ErrorCode.NOT_GROUP_MANAGER]: 'Not group manager',
  [ErrorCode.GROUP_REMOVE_USER_FAIL]: 'Remove group user fail',
  [ErrorCode.USER_GROUP_NOT_EXIST]: 'The user\'s group does not exist',
  [ErrorCode.CAN_NOT_REMOVE_OWN]: 'CAN NOT REMOVE OWNe',
  [ErrorCode.NOT_FOUND_OWN_GROUP]: 'The user\'s private group was not found',
  [ErrorCode.CREATORS_CANNOT_REMOVE_THEMSELVES]: 'Creators cannot remove themselves',

  [ErrorCode.DB_UPDATE_SETTING]: 'fail to update setting',

  [ErrorCode.DB_INSERT_REPORT]: 'Fail to create report',
  [ErrorCode.DB_REPORT_NOT_FOUND]: 'Can not found report',
  [ErrorCode.BLOCK_USER_ERROR]: 'Block User Error',
  [ErrorCode.DB_UPDATE_ERROR]: 'Fail to update report',

  [ErrorCode.DB_NO_NOTICE]: 'found no notice',
  [ErrorCode.DB_GET_NOTICE]: 'fail to get notice',
  [ErrorCode.DB_UPDATE_NOTICE]: 'fai lto update notice',
  [ErrorCode.DB_CLEAR_UNREAD_NOTICE]: 'fail to clear unread notice',

  [ErrorCode.DB_NO_TIMING_TASK]: 'found no timingTask',
}