export enum Button {
    continueWithEmail = 'Continue with Email',
    signIn = 'Sign In',
    resetPassword = 'Reset Password',
    prayNow = 'Pray Now',
    cancel = 'Cancel',
    done = 'Done',
    confirm = 'Confirm',
    yes = 'Yes',
    ok = 'OK',
    terms = 'Terms',
    privacyPolicy = 'Privacy Policy',
    deleteAccount = 'Delete Account',
    resend = 'Resend',
    send = 'Send',
    amen = 'Amen',
    more = 'More',
    delete = 'Delete',
    reply = 'Reply',
    copy = 'Copy',
    prayAgain = 'Pray again',
    markAsAnswered = 'Mark as Answered',
    reportUser = 'Report User',
    reportPrayer = 'Report Prayer',
    removeFromPublic = 'Remove from Public',
    block = 'Block',
    unBlock = 'UnBlock',
    pray = 'PRAY',
    savePhoto = 'Save Photo',
    copyLink = 'Copy Link',
    share = 'Share',
    skip = 'skip',
    disbandGroup = 'Disband the Group',
    logOut = 'Log Out',
    agree = 'Agree',
    disagree = 'Disagree',
    download = 'Download',
    confirmToJoin = 'Confirm to join',
    open = 'Open in',
    complete = 'Done',
    verifyLater = 'Verify Later',
    verifyNow = 'Verify Now',
}