import {modalController} from '@ionic/vue'
import {Ref, computed, onMounted, ref} from 'vue'

import {getGroupPrayerList, getPrayerList, getPrayerListApi, getPrayerListByIdsApi} from '@/apis/prayer'
import {
  Prayer,
  TABLE_NAME_PRAYER,
  TABLE_PRIMARYKEY_PRAYER
} from '@/stores/database/database-schema-v1'
import {useDBAdapter} from '@/stores/db-adapter'
import {Reactive} from '@/typings/commen'

interface Props {
    groupId?: string
    prayerIds?: string[]
    state?: number
}

interface UsePrayerListSate {
    readonly prayerList: Ref<Prayer[]>
    activeIndex: Ref<number>
    activePrayer: Ref<Prayer | null>
    showLoading: Ref<boolean>
    loadData: () => void
}

export function usePrayerListState(props: Reactive<Props>): UsePrayerListSate {
  const prayerList = ref<Prayer[]>([])
  const activeIndex = ref(0)
  const showLoading = ref(true)

  if (!props.groupId && (!props.prayerIds || props.prayerIds.length === 0)) {
    throw new Error('groupId 和 prayerIds 必须存在一个')
  }
  const dbAdapter = useDBAdapter<Prayer>(TABLE_NAME_PRAYER)
  const loadDataFromDB = async () => {
    let list
    if (props.prayerIds && props.prayerIds.length > 0) {
      // 数据处理 查询语句内的值需要用('')包裹
      const prayerIds = props.prayerIds.map(item=>`'${item}'`)
      await dbAdapter.setCondition(`prayerId IN (${prayerIds.join(',')})`)
      list = await dbAdapter.query(
        '*',
        {
          keys: 'createTime',
          order: 'ASC',
        },
        {
          offset: 0,
          limit: 10,
        }
      )
    } else if (props.groupId) {
      await dbAdapter.setCondition({groupId: props.groupId, state: props.state})
      list = await dbAdapter.query(
        '*',
        {
          keys: 'createTime',
          order: 'ASC',
        },
        {
          offset: 0,
          limit: 10,
        }
      )
    }
    prayerList.value = list || []
  }

  const loadData = async () => {
    let requestPromise = null
    if (props.prayerIds && props.prayerIds.length > 0) {
      requestPromise = getPrayerListByIdsApi({
        prayerIdList: props.prayerIds,
      })
    } else if (props.groupId) {
      requestPromise = await getGroupPrayerList({
        groupId: props.groupId,
        state: props.state,
      })
    } else {
      throw new Error('groupId 和 prayerIds 必须存在一个')
    }

    try {
      const res = await requestPromise
      prayerList.value = res || []
      await dbAdapter.upsertSet(res, [TABLE_PRIMARYKEY_PRAYER])
      await dbAdapter.store()
    } finally {
      showLoading.value = false

      // 拉取数据失败，返回
      if (prayerList.value.length === 0) {
        const topModal = await modalController.getTop()
        if (topModal) {
          await topModal.dismiss()
        }
      }
    }
  }

  const activePrayer = computed(() => {
    return prayerList.value[activeIndex.value]
  })
  onMounted(async () => {
    await loadDataFromDB()
    await loadData()
  })

  return {
    prayerList,
    showLoading,
    activeIndex,
    activePrayer,
    loadData,
  }
}
