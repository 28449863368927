import { handleClickedNotice, handleReceivedNotice, handleToken } from './processor'

import { NotificationActionType, TPNS, TPNSPushNotification } from '@/capacitor-plugins/tpns-android'
import { Notice } from '@/stores/database/database-schema-v1'

function addListener() {
  TPNS.addListener('registration', (data: {token: string}) => {
    handleToken(data.token)
  })

  TPNS.addListener('registrationError', (err: any) => {
    console.error(`Registration error: ${err.error}`)
  })

  TPNS.addListener('pushNotificationReceived',
    (notification: TPNSPushNotification) => {
      if (!notification.customContent) return
      const notice = JSON.parse(notification.customContent) as Notice
      handleReceivedNotice(notice)
    }
  )

  TPNS.addListener('pushNotificationActionPerformed',
    (notification: TPNSPushNotification) => {
      if (notification.actionType === NotificationActionType.DELETE) return
      if (!notification.customContent) return
      const notice = JSON.parse(notification.customContent) as Notice
      handleClickedNotice(notice)
    }
  )
}

export async function listenPushNotification() {
  addListener()
  await TPNS.register()
}
