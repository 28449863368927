import { SQLiteDBConnection } from '@capacitor-community/sqlite'
import { Capacitor } from '@capacitor/core'
import {isPlatform} from '@ionic/vue'
import {
  defineCustomElements as jeepSqlite,
  applyPolyfills as polyfill
} from 'jeep-sqlite/loader'
import { useSQLite } from 'vue-sqlite-hook'

import { SQL_STATEMENT_FOR_SETUP, getTableNames } from './database-schema-v1'

// 数据库名字, 不可更改
const DATABASE_NAME = 'hxx_pg'
// 数据库版本, 数据库升级时更改, 需要同步更改 upgradeDatabase 当中的数据库迁移逻辑
const DATABASE_VERSION = 1
const sqlite = useSQLite()

/**
 * 仅 Web App 需要调用本方法
 */
async function initWebStore() {
  polyfill().then(() => {
    jeepSqlite(window)
  })

  const jeepSqliteDom = document.createElement('jeep-sqlite')
  document.body.appendChild(jeepSqliteDom)
  await customElements.whenDefined('jeep-sqlite')
  await sqlite.initWebStore()
}

/**
 * 获取一个数据库连接, 可以执行sql语句, 业务中应该不会使用本方法
 * 仅仅启动 App 初始化时, 才需要传入 version 用以检查是否需要数据库升级
 */
export async function openDB(version?: number): Promise<SQLiteDBConnection> {
  let db: SQLiteDBConnection
  const ret = (await sqlite.checkConnectionsConsistency()).result
  const isConn = (await sqlite.isConnection(DATABASE_NAME)).result
  if (ret && isConn) {
    db = await sqlite.retrieveConnection(DATABASE_NAME)
  }
  else {
    db = await sqlite.createConnection(
      DATABASE_NAME,
      false,
      'no-encryption',
      version
    )
  }
  if (!(await db.isDBOpen()).result) {
    await db.open()
  }
  return db
}

/**
 * insert / update / delete 等操作仅仅修改内存中的数据
 * 调用本方法进行数据持久化
 */
export async function saveDB() {
  if (isPlatform('hybrid')) {
    return Promise.resolve()
  }
  else {
    return sqlite.saveToStore(DATABASE_NAME)
  }
}

/**
 * 第一次进入应用, 创建数据表
 */
async function setupTables() {
  const db: SQLiteDBConnection = await openDB()
  console.info('setup database table')
  await db.execute(SQL_STATEMENT_FOR_SETUP)
  await db.close()
}

/**
 * 检查是否需要数据库升级
 * 编写数据迁移的逻辑
 * 数据迁移, 遵循该规则: old-version to latest-version
 * 数据迁移是直接从老版本迁移到最新版本, 并非渐进式的逐个版本迁移, 所以在写迁移逻辑时需要掌握每一次版本的变化
 * !!!!请谨慎撰写迁移逻辑
 */
async function upgradeDatabase() {
  const db: SQLiteDBConnection = await openDB()
  const oldVersion = await db.getVersion()
  console.info('old version', oldVersion?.version, 'new version:', DATABASE_VERSION)
  if (!oldVersion?.version || oldVersion.version === DATABASE_VERSION) {
    return
  }
  switch (oldVersion.version) {
  case 1:
    sqlite.addUpgradeStatement(DATABASE_NAME, {
      fromVersion: 1,
      toVersion: 2,
      statement: SQL_STATEMENT_FOR_SETUP,
      // set: [
      //   {
      //     statement: 'INSERT INTO account (email,name,age) VALUES (?,?,?);',
      //     values: ['11@qq.com', 'Nate', '22'],
      //   },
      // ],
    })
    break
  default:
    return
  }

  console.info('upgrading database')

  await sqlite.closeAllConnections()
  const newDb = await openDB(DATABASE_VERSION)
  await newDb.close()
}

/**
 * 数据库的初始化, 进入 App 时就要调用
 */
export async function databaseInit(): Promise<void> {
  console.info('>>>>>Database initialization')
  const platform = Capacitor.getPlatform()
  if (platform === 'web') {
    await initWebStore()
  }

  const isDatabaseExist = await sqlite.isDatabase(DATABASE_NAME)
  if (isDatabaseExist.result) {
    await upgradeDatabase()
  } else {
    await setupTables()
  }
  console.info('>>>>>Database initialization finish')
}

export async function clearAllDataInDB(): Promise<void> {
  const db: SQLiteDBConnection = await openDB()
  const tables = getTableNames()
  for (const tableName of tables) {
    await db.execute('delete from '+ tableName)
  }
  await saveDB()
}