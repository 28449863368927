import { ToggleCustomEvent, loadingController, toastController } from '@ionic/vue'
import { useLocalStorage } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

import { apiChangeSettingNotice, apiGetSettingNotice } from '@/apis/notice'
import {useI18n} from '@/i18n/use-i18n'
import { NotificationConfig } from '@/stores/database/database-schema-v1'


const NotificationConfigStorageKey = 'NOTIFICATION_CONFIG'
const {t} = useI18n()

export function useNotificationConfigState() {
  const isLoading = ref(true)
  const configure = useLocalStorage<NotificationConfig>(NotificationConfigStorageKey, {
    emailPrayerComment: false,
    emailReplyComment: false,
    emailSystemNotice: false,
    pushPrayerComment: false,
    pushReplyComment: false,
    pushSystemNotice: false,
  })

  async function getSettingNoticeDetail() {
    try {
      configure.value = await apiGetSettingNotice()
    }
    finally {
      isLoading.value = false
    }
  }

  async function updateNoticeSetting(event: ToggleCustomEvent, key: keyof NotificationConfig) {
    if (!configure.value) return

    const checked = event.detail.checked
    if (checked === configure.value[key]) return

    configure.value[key] = checked

    isLoading.value = true
    const loading = await loadingController.create({
      message: 'please wait...',
    })
    loading.present()
    isLoading.value = true

    try {
      await apiChangeSettingNotice(configure.value)
    }
    finally {
      loading.dismiss()
      isLoading.value = false
    }

    const toast = await toastController.create({
      color: 'success',
      duration: 2000,
      message: t('toast.setSuccessfully'),
    })
    toast.present()
  }

  onMounted(() => {
    getSettingNoticeDetail()
  })

  const emailNotificationConfig = computed(() => {
    return [
      {title: t('pageContent.appSetting.emailItemTitle1'), value: configure.value?.emailPrayerComment || false, key: 'emailPrayerComment'},
      {title: t('pageContent.appSetting.emailItemTitle2'), value: configure.value?.emailReplyComment || false, key: 'emailReplyComment'},
      {title: t('pageContent.appSetting.emailItemTitle3'), value: configure.value?.emailSystemNotice || false, key: 'emailSystemNotice'},
    ]
  })

  const pushNotificationConfig = computed(() => {
    return [
      {title: t('pageContent.appSetting.pushItemTitle1'), value: configure.value?.pushPrayerComment || false, key: 'pushPrayerComment'},
      {title: t('pageContent.appSetting.pushItemTitle2'), value: configure.value?.pushReplyComment || false, key: 'pushReplyComment'},
      {title: t('pageContent.appSetting.pushItemTitle3'), value: configure.value?.pushSystemNotice || false, key: 'pushSystemNotice'},
    ]
  })

  return {
    isLoading,
    updateNoticeSetting,
    emailNotificationConfig,
    pushNotificationConfig,
  }
}