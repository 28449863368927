export enum Prayer {
    prayerBlockPageTitle = 'Block prayers list',
    prayerHistoryPageTitle = 'My group post prayers',
    prayerReportPageTitle = 'Report',
    firstPrayerPageTitle = 'Create your first prayer',
    prayerTitle = 'Title',
    addReminders = 'ADD A REMINDERS',
    nextReminderTime = 'NEXT REMINDER TIME',
    repeating = 'REPEATING',
    prayerDes = 'DESCRIPTION',
    myReminders = 'MY REMINDERS',
    prayerUpdate = 'UPDATE',
    prayerRoom = 'ROOM',
    answered = 'Answered',
    publicAnswered = 'Share to Public Answered Prayer',
    prayerCount = 'COUNT',
    prayerMembers = 'Members',
    prayerTimes = 'Pray times',
    prayerCountComments = 'Comments',
    prayerComments = 'COMMENTS',
    prayerAnsweredComments = 'ANSWERED COMMENTS',
    prayerDeleteTitle = 'This Prayer was Deleted',
    prayerBlockTitle = 'This Prayer was Block',
    prayerUnBlockTitle = 'This Prayer was UnBlock',
    prayerAnsweredCancelTitle = 'This Prayer Answered was Cancel',
    prayerAnsweredRemoveTitle = 'This Prayer Answered was Remove',
    prayerReminders = 'Prayer Reminders',
    prayerReport1 = 'It\'s spam',
    prayerReport2 = 'Nudity or sexual activity',
    prayerReport3 = 'Hate speech or symbols',
    prayerReport4 = 'Violence or dangerous organizations',
    prayerReport5 = 'Bullying or harassment',
    prayerReport6 = 'False information',
    prayerReport7 = 'Scam or fraud',
    prayerReport8 = 'Other',
    choseReason = 'Chose Reason',
    reportDetails = 'Report Details',
    everyDay = 'EVERY_DAY',
    weekDay = 'WEEKDAY',
    everyWeek = 'EVERY_WEEK',
    everyMouth = 'EVERY_MOUTH',
    everyYear = 'EVERY_YEAR',
    threeMinutes = 'THREE_MINUTES',
    never = 'NEVER',
}