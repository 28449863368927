import { InjectionKey, onMounted, reactive, ref } from 'vue'

export type RefreshHandler = () => Promise<void>

export interface HomeChildState {
  loadData: (reset: boolean) => Promise<void>
}

export const HomeStateInjectionKey: InjectionKey<{
  addChildState: (state: HomeChildState) => void
}> = Symbol('homeState')

export function useHomeState() {

  /**
   * state
   */
  const isIniting = ref(true)

  /**
   * child state
   */
  const childStates = reactive<HomeChildState[]>([])
  const addChildState = (state: HomeChildState) => {
    childStates.push(state)
  }
  const loadData = async () => {
    const promiseList = []
    for (const childState of childStates) {
      promiseList.push(childState.loadData(true))
    }
    await Promise.all(promiseList)
  }

  /**
   * init data
   */
  // onMounted(async () => {
  //   isIniting.value = true
  //   try {
  //     await loadData()
  //   } finally {
  //     isIniting.value = false
  //   }
  // })

  return {
    doRefresh: loadData,
    isIniting,
    addChildState,
  }
}
