import {Button} from '@/i18n/model/en/button'
import {ErrorTips} from '@/i18n/model/en/error-tips'
import {Input} from '@/i18n/model/en/input'
import {Loading} from '@/i18n/model/en/loading'
import {PageContent} from '@/i18n/model/en/page-content/page-content'
import {Toast} from '@/i18n/model/en/toast'

export const en = {
  button: Button,
  input: Input,
  loading: Loading,
  pageContent: PageContent,
  toast: Toast,
  errorCode: ErrorTips,
}
