import {InfiniteScrollCustomEvent} from '@ionic/core'
import {useLocalStorage} from '@vueuse/core'
import {Ref, computed, onMounted, onUnmounted, ref} from 'vue'

import {apiGetNoticeList, apiSetNoticeRead} from '@/apis/notice'
import {STORAGE_KEY_NOTICE_UNREAD} from '@/constants/localstorage'
import {
  Notice,
  TABLE_NAME_NOTICE,
  TABLE_PRIMARYKEY_NOTICE
} from '@/stores/database/database-schema-v1'
import {useDBAdapter} from '@/stores/db-adapter'
import {UseListState} from '@/typings/commen'

interface UseNotificationState extends UseListState {
    noticeList: Ref<Notice[]>
    setRead: (noticeId: string) => Promise<void>
}

export function useNotificationState(): UseNotificationState {
  /**
     * db adapter
     */
  const dbAdapter = useDBAdapter<Notice>(TABLE_NAME_NOTICE)

  const noticeList = ref<Notice[]>([])
  const hasMoreData = ref(true)
  const isLoading = ref(true)
  let pageNumber = 0

  const loadMore = async (event?: InfiniteScrollCustomEvent, reset?: boolean, ignoreStore?: boolean, reload?: boolean) => {
    pageNumber = reload ? 0 : pageNumber
    pageNumber += 1
    const res = await apiGetNoticeList({
      pageNumber,
      pageSize: 10,
    })
    if (reload) noticeList.value = []
    if (reset) {
      noticeList.value = res.list
    } else {
      noticeList.value.push(...res.list)
    }
    hasMoreData.value = res.hasNextPage

    await dbAdapter.upsertSet(res.list, [TABLE_PRIMARYKEY_NOTICE])
    event?.target.complete()
  }

  const isEmpty = computed(() => {
    return !isLoading.value && noticeList.value.length === 0
  })

  const loadDataFromDB = async () => {
    noticeList.value = await dbAdapter.query(
      '*',
      {
        keys: 'stamp',
        order: 'DESC',
      },
      {
        offset: 0,
        limit: 10,
      }
    )
  }

  // initNoticeList
  onMounted(async () => {
    try {
      await loadDataFromDB()
      // await loadMore(undefined, true)
    } finally {
      isLoading.value = false
    }
  })

  onUnmounted(async () => {
    await dbAdapter.store()
  })

  /**
     * set read
     */
  const setRead = async (noticeId: string) => {
    const notice = noticeList.value.find(notice => notice.noticeId === noticeId)
    if (notice) {
      notice.isRead = 1
    }

    await apiSetNoticeRead({noticeId})
    const unreadCount = useLocalStorage(STORAGE_KEY_NOTICE_UNREAD, 0, {
      deep: true,
      listenToStorageChanges: true,
    })
    unreadCount.value = Math.max(unreadCount.value - 1, 0)
  }

  return {
    noticeList,
    hasMoreData,
    isLoading,
    isEmpty,
    setRead,
    loadMore,
  }
}