import {toastController} from '@ionic/vue'
import AsyncValidator, {RuleItem} from 'async-validator'
import {ref} from 'vue'

import {sendPrayerGroupInvitationEmailApi} from '@/apis/prayer-group'
import {useI18n} from '@/i18n/use-i18n'

const {t} = useI18n()

export function useInputEmailState(props: { invitationUrl: string }) {
  const emailByUserInput = ref('')
  const timeOut = ref(0)

  const sendInvitation = async () => {
    const descriptor: Record<string, RuleItem> = {
      email: {type: 'email', required: true},
    }
    const validator = new AsyncValidator(descriptor)
    validator.validate({email: emailByUserInput.value}).then(async () => {
      await sendPrayerGroupInvitationEmailApi({
        email: emailByUserInput.value,
        invitationUrl: props.invitationUrl,
      })
      timeOut.value = 60
      const timer = setInterval(() => {
        if (timeOut.value !== 0) {
          timeOut.value -= 1
        } else {
          clearInterval(timer)
        }
      }, 1000)
      const toast = await toastController.create({
        message: t('toast.groupInviteSuccessful', {email: emailByUserInput.value}),
        duration: 1500,
      })
      await toast.present()

    }).catch(async () => {
      const toast = await toastController.create({
        message: t('toast.enterEmail'),
        color: 'warning',
        duration: 1500,
      })
      await toast.present()
    })
  }

  return {
    emailByUserInput,
    sendInvitation,
    timeOut,
  }
}
