import {InfiniteScrollCustomEvent} from '@ionic/core'
import {Ref, reactive, ref} from 'vue'

import {getPrayerOwnListApi} from '@/apis/prayer'
import {PrayerState} from '@/protobuf/pg'
import {useAccountStore} from '@/stores/account'
import {
  Prayer,
  TABLE_NAME_PRAYER,
  TABLE_PRIMARYKEY_PRAYER
} from '@/stores/database/database-schema-v1'
import {useDBAdapter} from '@/stores/db-adapter'
import {Reactive, UseListState} from '@/typings/commen'

interface UsePrayerListState extends UseListState {
  prayerList: Reactive<Prayer[]>
}

export function usePrayerListState(): UsePrayerListState {
  /**
   * db adapter
   */
  const {accountInfo} = useAccountStore()
  const condition = `uin="${accountInfo.value?.uin}" AND state IN (${PrayerState.PRAYER_NORMAL},${PrayerState.PRAYER_ANSWERED})`
  const dbAdapter = useDBAdapter<Prayer>(TABLE_NAME_PRAYER, condition)
  /**
   * prayer list
   */
  const prayerList: Reactive<Prayer[]> = reactive([])
  const isLoading: Ref<boolean> = ref(true)
  const isEmpty: Ref<boolean> = ref(false)
  const hasMoreData: Ref<boolean> = ref(false)

  let pageNumber = 0
  const loadMore = async (event?: InfiniteScrollCustomEvent, reset?: boolean, ignoreStore?: boolean, reload?: boolean) => {
    pageNumber = reload ? 0 : pageNumber += 1
    const params = {
      pageNumber,
      pageSize: 20,
    }
    const res = await getPrayerOwnListApi(params)
    // console.log(res)
    /*
      拿到数据库中所有祷告:
        {total: 4, list: Array(4), pageNum: 1, pageSize: 20, size: 4....}
    */
    if (reset) {
      prayerList.splice(0, prayerList.length)
      prayerList.push(...res.list)
    } else {
      prayerList.push(...res.list)
    }
    hasMoreData.value = res.hasNextPage

    if (ignoreStore) {
      console.info('ignoreStore is true, 不会插入数据到数据库')
    } else {
      await dbAdapter.upsertSet(res.list, [TABLE_PRIMARYKEY_PRAYER])
      await dbAdapter.store()
    }
  }

  const loadDataFromDB = async () => {
    const list = await dbAdapter.query(
      '*',
      {keys:'createTime',order:'DESC'},
      {
        offset: 0,
        limit: 10,
      }
    )
    prayerList.splice(0, prayerList.length)
    prayerList.push(...list)
  }

  /**
   * init data
   * 先从数据库拿数据出来显示
   */
  (async function () {
    await loadDataFromDB()
    // await loadMore(undefined, true)
  })()

  /**
   * watch
   */
  dbAdapter.watch(async () => {
    await loadDataFromDB()
  })

  return {
    prayerList,
    isLoading,
    isEmpty,
    hasMoreData,
    loadMore,
  }
}
