import {modalController} from '@ionic/vue'
import {computed} from 'vue'

import defaultSvg from '@/assets/svgs/time-reminder.svg'
import {useI18n} from '@/i18n/use-i18n'
import {Notice} from '@/stores/database/database-schema-v1'
import {Reactive} from '@/typings/commen'
import {UseNoticeReturn} from '@/uses/notice-processor/type.d'
import {getTimeTips} from '@/utils/util'
import DoPrayer from '@/views/prayer/do-pray/DoPray.vue'

const {t} = useI18n()

export function useNoticePrayerReminder(notice: Reactive<Notice>): UseNoticeReturn {
  const onClick = async () => {
    const mode = await modalController.create({
      component: DoPrayer,
      componentProps: {
        prayerIds: [notice.prayerId],
      },
    })
    mode.present()
  }

  const componentData = computed(() => {
    return {
      url: notice.groupImage,
      icon: defaultSvg,
      title: `${t('pageContent.noticeCenter.prayerReminder')}: `,
      description: `${notice.prayerTitle}`,
      time: getTimeTips(notice.stamp),
      isReaded: !!notice.isRead,
    }
  })

  return {
    componentData,
    onClick,
  }
}