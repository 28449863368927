import {useEventBus, useLocalStorage} from '@vueuse/core'

import {apiClearUnreadCount, apiGetUnreadCount} from '@/apis/notice'
import {STORAGE_KEY_NOTICE_UNREAD} from '@/constants/localstorage'
import { useBadgeState } from '@/views/entry/home/use-badge-state'

export function useBellState() {
  const {clearBadge} = useBadgeState()

  const unreadCount = useLocalStorage(STORAGE_KEY_NOTICE_UNREAD, 0, {
    deep: true,
    listenToStorageChanges: true,
  })

  async function clearUnreadCount() {
    await apiClearUnreadCount()
    unreadCount.value = 0
    await clearBadge()
  }

  async function getUnreadCount() {
    const res = await apiGetUnreadCount()
    unreadCount.value = res.num
  }

  const eventBus = useEventBus<string>('PUSH_NOTIFICATION')
  eventBus.on(async (event: string) => {
    if (event !== 'received') return
    await getUnreadCount()
  })

  const loadData = async (reset: boolean) => {
    if (!reset) return
    await getUnreadCount()
  }

  return {
    loadData,
    unreadCount,
    clearUnreadCount,
  }
}