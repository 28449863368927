import {PagenationReq, PagenationRes} from '@/apis/type'
import { Notice, NotificationConfig } from '@/stores/database/database-schema-v1'
import {http} from '@/utils/http'

interface GetNoticeListRes extends PagenationRes {
  list: Notice[]
}

export const apiGetNoticeList = (data: PagenationReq): Promise<GetNoticeListRes> => {
  return http.post({
    url: '/notice/list',
    data,
  })
  // const data1 = {
  //   endRow: 0,
  //   hasNextPage: false,
  //   hasPreviousPage: false,
  //   isFirstPage: true,
  //   isLastPage: true,
  //   navigateFirstPage: 0,
  //   navigateLastPage: 0,
  //   navigatePages: 8,
  //   navigatepageNums: [],
  //   nextPage: 0,
  //   pageNum: 1,
  //   pageSize: 10,
  //   pages: 0,
  //   prePage: 0,
  //   size: 0,
  //   startRow: 0,
  //   total: 0,
  //   list: [
  //     {
  //       avatar: 'string',
  //       banReasion: 'string',
  //       commentContent: 'string',
  //       commentId: 'string',
  //       groupId: 'string',
  //       groupName: 'string',
  //       inviteUserAvatar: 'string',
  //       inviteUserName: 'string',
  //       inviteUserUin: 'string',
  //       isRead: 0,
  //       name: 'string',
  //       noticeId: 'string',
  //       prayerId: 'string',
  //       prayerTitle: 'string',
  //       repliedName: 'string',
  //       stamp: 1312312312312,
  //       type: 1,
  //     },
  //   ],
  // } as unknown as GetNoticeListRes
  // return Promise.resolve(data1)
}

interface SetNoticeRead {
  noticeId: string
}

export const apiSetNoticeRead = (data: SetNoticeRead) => {
  return http.post({
    url: 'notice/set_read',
    data,
  })
}

interface GetNoticeUnreadNumRes {
  num: number
}

export const apiGetNoticeUnreadNum = (): Promise<GetNoticeUnreadNumRes> => {
  return http.get({
    url: '/notice/unread/num',
  })
}

export interface SettingNoticeRes extends NotificationConfig{
  uin: string
}

export const apiGetSettingNotice = () => {
  return http.get<SettingNoticeRes>({
    url: '/setting/detail',
  })
}

export const apiChangeSettingNotice = (data: NotificationConfig) => {
  return http.post({
    url: '/setting/change',
    data,
  })
}

export const apiGetUnreadCount = (): Promise<{num: number}> => {
  return http.get({
    url: '/notice/unread/num',
  })
}

export const apiClearUnreadCount = (): Promise<void> => {
  return http.post({
    url: '/notice/unread/clear',
  })
}