export enum AppSetting {
    noticePageTitle = 'Push Notifications',
    termsPageTitle = 'Terms',
    emailTitle = 'Email',
    pushTitle = 'Push',
    emailItemTitle1 = 'Prayer comments',
    emailItemTitle2 = 'Reply of comments',
    emailItemTitle3 = 'System notification',
    pushItemTitle1 = 'Prayer comments',
    pushItemTitle2 = 'Reply of comments',
    pushItemTitle3 = 'System notification',
    settingItemTitle1 = 'Account',
    settingItemTitle2 = 'Notifications',
    settingItemTitle3 = 'Privacy',
    settingItemTitle4 = 'Terms',
    appVersion = 'Version',
    logOutTips = 'Are you sure to log out',
}