export enum Start {
    appName = 'PrayRooms',
    title1 = 'But when you ',
    title2 = 'pray, go into ',
    title3 = 'your room, close ',
    title4 = 'the door and ',
    title5 = 'pray to your ',
    title6 = 'Father',
    tips = 'Matthew 6:6 NIV',
    footerTips1 = 'By signing up,you agree to our',
    footerTips2 = 'See how',
    footerTips3 = 'we use your data in our',
}