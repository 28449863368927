export enum PrayerGroup {
    createGroupTitle = 'Create a Group Pray',
    createGroupDes = 'Create a room for your family, church group, ministry group, etc. to share prayers',
    answered = 'Answered',
    alleluia = 'alleluia',
    newAnsweredTips = 'New Answered Pray is added',
    groupChangeInfoTitle = 'Change Group info',
    groupChangeNameTitle = 'Change Group name',
    groupChangeInfoTips = 'After modifying the group introduction, other members will be notified in the group',
    groupChangeNameTips = 'After modifying the group name, other members will be notified in the group',
    deleteMembersPageTitle = 'Delete members',
    groupSettingsPageTitle = 'Group settings',
    groupPhotoLabel = 'Group photo',
    groupNameLabel = 'Group name',
    groupInfoLabel = 'Group info',
    disbandGroupTitle = 'This Group was Disband',
    groupShareTips1 = 'Send this link to invited users',
    groupShareTips2 = 'Link valid for 14 days',
    groupInviteTitle = 'Invite family or friends to join',
    groupInviteLinkShare = 'Link Share',
    groupInviteEmail = 'Email',
    groupInviteSuccessTips1 = 'Successfully joined',
    groupInviteSuccessTips2 = 'Prayer For our Spouses',
    publicAnsweredGroupName = 'Public Answered Pray',
    publicAnsweredGroupDes = 'Allowed prayers are recorded here. Let\'s shout out for God\'s steadfast love!',
    publicAnsweredGroupCreator = '',
    myPrayRoom = 'My Pray Room',
    myPrayRoomDes = 'This is your personal prayer room. Pray and record the fellowship of the Holy Spirit.',
    groupInviteJoin = '{name} Invite your join'
}
