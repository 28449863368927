import {Badge as badge} from '@awesome-cordova-plugins/badge'
import {onMounted} from 'vue'

export function useBadgeState() {
  let isSupported: any

  onMounted(async () => {
    isSupported = await badge.isSupported()
    if (!isSupported) return

    const hasPermission = await badge.hasPermission()
    if (!hasPermission) {
      await badge.requestPermission()
    }
  })

  async function clearBadge() {
    await badge.clear()
  }

  return {
    clearBadge,
  }
}