/**
 * 账号
 */
import { GroupType, PrayerState, TimingTaskLoopType } from '@/protobuf/pg'

export const TABLE_NAME_PROFILE = 'profile'
export const TABLE_PRIMARYKEY_PROFILE = 'uin'

export interface Account {
  uin: string
  email: string
  token: string
  state: number
}

export interface Profile {
  uin: string
  avatar: string
  name: string
}

const TABLE_PROFILE = `
  CREATE TABLE ${TABLE_NAME_PROFILE} (
    uin TEXT PRIMARY KEY,
    name TEXT,
    avatar TEXT
  );
`

/**
 * 祷告
 */
export const TABLE_NAME_PRAYER = 'Prayer'
export const TABLE_PRIMARYKEY_PRAYER = 'prayerId'

export interface Prayer {
  prayerId: string
  groupId: string
  title: string
  description?: string
  reminderType?: TimingTaskLoopType
  nextReminderTime?: number
  createTime?: number
  updateTime?: number
  state?: PrayerState
  avatar?: string // creator avatar
  name?: string // creator name
  uin?: string // creator uin
  prayerStatistic?: number
  commentStatistic?: number// unread comment count
  prayerCommentCount?: number // all comment count
  isRead?: boolean
  groupName?: string // group name
  groupType?: GroupType // group type
  isPublic?: boolean
  groupMemberCount?: number
}

export interface ReminderInfo {
  remindType: TimingTaskLoopType
  nextReminderTime: number
}

const TABLE_PRAYER = `
  CREATE TABLE ${TABLE_NAME_PRAYER} (
    prayerId TEXT PRIMARY KEY,
    title TEXT NOT NULL,
    groupId TEXT,
    description TEXT,
    reminderType INTEGER,
    nextReminderTime INTEGER,
    createTime INTEGER,
    updateTime INTEGER,
    state INTEGER,
    avatar TEXT,
    name TEXT,
    uin TEXT,
    prayerStatistic INTEGER,
    commentStatistic INTEGER,
    prayerCommentCount INTEGER,
    isRead INTEGER,
    groupName TEXT,
    groupType INTEGER,
    isPublic INTEGER,
    groupMemberCount INTEGER
  );
`

/**
 * 评论
 */
export const TABLE_NAME_COMMENT = 'comment'
export const TABLE_PRIMARYKEY_COMMENT = 'commentId'

export interface PrayerComment {
  prayerId: string
  commentId: string
  content: string
  createTime: number
  likeNum: number
  liked: boolean
  type: number
  uin: string
  avatar?: string
  name?: string
  parentId?: string
  subCommentList: {
    list: PrayerComment[]
  }
  needScrollTo?: boolean
}

const TABLE_COMMENT = `
  CREATE TABLE ${TABLE_NAME_COMMENT} (
    commentId TEXT PRIMARY KEY,
    prayerId TEXT NOT NULL,
    content TEXT NOT NULL,
    createTime INTEGER NOT NULL,
    likeNum INTEGER NOT NULL,
    liked INTEGER NOT NULL,
    type INTEGER NOT NULL,
    uin TEXT NOT NULL,
    avatar TEXT,
    name TEXT,
    parentId TEXT,
    subCommentList TEXT
  );
`

/**
 * 通知
 */
export const TABLE_NAME_NOTICE = 'notice'
export const TABLE_PRIMARYKEY_NOTICE = 'noticeId'

export interface Notice {
  noticeId: string // 通知ID
  isRead: number // 通知是否已读
  stamp: number // 通知时间
  type: number // 通知类型
  avatar?: string // 通知关联的用户头像
  banReason?: string // prayer被屏蔽的原因
  commentContent?: string // 通知关联的评论内容
  commentId?: string // 通知关联的评论ID
  groupId: string // 通知关联的group
  groupName?: string // 通知关联的group name
  groupImage?: string // 通知关联的group image
  inviteUserAvatar?: string // 邀请新用户注册头像
  inviteUserName?: string // 邀请新用户注册名字
  inviteUserUin?: string // 邀请新用户注册uin
  name?: string // 通知关联的用户名称
  prayerId?: string // 通知关联的prayer
  prayerTitle?: string // 通知关联的prayer title
  repliedName?: string // 通知关联的被回复或被点赞用户名称
  repliedCommentId?: string // 被回复的评论ID
  repliedCommentContent?: string // 被回复的评论的内容
}

const TABLE_NOTICE = `
  CREATE TABLE ${TABLE_NAME_NOTICE} (
    noticeId TEXT PRIMARY KEY,
    isRead INTEGER NOT NULL,
    stamp INTEGER NOT NULL,
    type INTEGER NOT NULL,
    avatar TEXT,
    banReason TEXT,
    commentContent TEXT,
    commentId TEXT,
    groupId TEXT,
    groupName TEXT,
    groupImage TEXT,
    inviteUserAvatar TEXT,
    inviteUserName TEXT,
    inviteUserUin TEXT,
    name TEXT,
    prayerId TEXT,
    prayerTitle TEXT,
    repliedName TEXT,
    repliedCommentId TEXT,
    repliedCommentContent TEXT
  );
`

/**
 * 祷告组
 */
export const TABLE_NAME_PRAYER_GROUP = 'prayer_group'
export const TABLE_PRIMARYKEY_PRAYER_GROUP = 'groupId'
export const TABLE_NAME_PRAYER_GROUP_MEMBER_RLP = 'group_member_relationship'

export interface GroupMemberRelationship {
  uin: string
  groupId: string
}

export interface Group {
  groupId: string
  groupName: string
  groupAvatar: string
  groupType: number
  description: string
  creatorUin: string
  createTime: number
  creator?: string
  newCommentNum?: number
  newPrayerNum?: number
  groupMembers?: Profile[]
}
const TABLE_GROUP = `
  CREATE TABLE ${TABLE_NAME_PRAYER_GROUP} (
    groupId TEXT PRIMARY KEY,
    groupName TEXT NOT NULL,
    groupAvatar TEXT NOT NULL,
    groupType INTEGER NOT NULL,
    creatorUin TEXT NOT NULL,
    createTime INTEGER NOT NULL,
    description TEXT,
    creator TEXT,
    groupMembers TEXT,
    newCommentNum INTEGER,
    newPrayerNum INTEGER
  );
`

export interface NotificationConfig {
  emailPrayerComment: boolean
  emailReplyComment: boolean
  emailSystemNotice: boolean
  pushPrayerComment: boolean
  pushReplyComment: boolean
  pushSystemNotice: boolean
}

export const SQL_STATEMENT_FOR_SETUP = `
  ${TABLE_PROFILE}
  ${TABLE_PRAYER}
  ${TABLE_GROUP}
  ${TABLE_NOTICE}
  ${TABLE_COMMENT}
`

// 清空所有数据的时候会用到
export function getTableNames() {
  const matches = [...SQL_STATEMENT_FOR_SETUP.matchAll(/CREATE TABLE (.*) /g)]
  return matches.map(match => match[1])
}
