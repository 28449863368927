import {onMounted, ref} from 'vue'

import {GroupAnsweredInfo, apiGetGroupAnsweredInfo, apiReadGroupAnsweredInfo} from '@/apis/prayer'

export function useGroupAnsweredInfo(groupId: string) {
  const groupAnsweredInfo = ref<GroupAnsweredInfo>({
    commentStatistic: 0,
    groupId: '',
    isRead: true,
    prayerTitle: '',
  })


  function readGroupAnsweredInfo() {
    if (groupAnsweredInfo.value.commentStatistic > 0 || !groupAnsweredInfo.value.isRead) {
      apiReadGroupAnsweredInfo({groupId}).then(() => {
        groupAnsweredInfo.value.isRead = true
        groupAnsweredInfo.value.commentStatistic = 0
      })
    }
  }

  async function initData() {
    const res = await apiGetGroupAnsweredInfo({groupId})
    groupAnsweredInfo.value = res
  }

  // onMounted(() => {
  //   initData()
  // })

  return {
    initData,
    groupAnsweredInfo,
    readGroupAnsweredInfo,
  }
}
