export enum Toast {
    refreshFail = '刷新失败',
    enterContent = '请输入内容',
    enterPrayerTitle= '请输入祷告标题',
    enterGroupName= '请输入群组名称',
    enterEmail= '请输入邮箱地址',
    lastOne= '最后一个',
    deleteSuccessfully= '删除成功',
    answeredSuccessfully= '应允成功',
    copySuccessfully= '复制成功',
    blockSuccessfully= '屏蔽成功',
    unBlockSuccessfully= '取消屏蔽成功',
    cancelSuccessfully= '取消应允成功',
    removeSuccessfully= '移除成功',
    editSuccessfully= '编辑成功',
    reportSuccessfully= '举报成功',
    linkCopiedSuccessful= '链接复制成功',
    linkCopiedFailed= '链接复制失败',
    photoSavedSuccessful= '照片保存成功',
    canNotSavePhoto= '照片保存失败',
    canNotSupportSocialShare= '不能支持社交共享',
    sharePrayer= '分享祷告',
    groupSaveSuccessful= '创建成功，邀请朋友加入吧',
    groupInviteSuccessful = '邀请 {email} 成功',
    modifySuccessfully = '修改成功',
    sendSuccessfully = '发送成功',
    setSuccessfully = '设置成功',
    pleaseCheckTime = '请选择时间',
}