export enum NoticeCenter {
    pageTitle = '通知中心',
    commented = '评论',
    prayer = '祷告',
    liked = '点赞',
    replied = '回复',
    groupDissolved = '{title}已被组长解散',
    groupJoin = '{name}已加入',
    noticeTypeError = '不支持的通知类型',
    prayerBanned = '您的{title}已被隐藏',
    prayerBannedDes = '请仔细阅读平台规则，如有疑问可联系群主',
    prayerReminder = '祷告的时间到咯',
    prayerPublicAnsweredFailed = '您的应允祷告未通过审核',
    empty = '无数据',
}