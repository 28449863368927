import {InfiniteScrollCustomEvent} from '@ionic/core'
import {UnwrapRef} from '@vue/reactivity'
import {InjectionKey, Ref, onMounted, onUnmounted, reactive, ref, watch} from 'vue'

import {
  ApiCancelLikeCommentReq,
  ApiCreateCommentReq,
  ApiDeleteCommentReq,
  ApiGetCommentListRes,
  ApiLikeCommentReq,
  apiCancelLikeComment,
  apiCreateComment,
  apiDeleteAnsweredComment,
  apiDeleteComment,
  apiGetCommentList,
  apiLikeComment
} from '@/apis/comment'
import {CommentType} from '@/protobuf/pg'
import {useAccountStore} from '@/stores/account'
import {
  PrayerComment,
  TABLE_NAME_COMMENT,
  TABLE_PRIMARYKEY_COMMENT
} from '@/stores/database/database-schema-v1'
import {useDBAdapter} from '@/stores/db-adapter'
import {MAX_PAGE_SIZE} from '@/utils/constants'
import {isNumber} from '@/utils/util'

interface ApiReplyToCommentReq {
    content: string
    commentId: string
}

export interface UseCommentListState {
    commentList: PrayerComment[]
    answerCommentList: PrayerComment[]
    hasMoreData: Ref<boolean>
    loadMoreComments: (event?: InfiniteScrollCustomEvent, reset?: boolean, ignoreStore?: boolean, reload?: boolean) => Promise<void>
    loadAnswerComments: () => Promise<void>
    createComment: (data: ApiCreateCommentReq) => void
    deleteComment: (data: ApiDeleteCommentReq, parentId?: string, isAnsweredComment?: boolean) => void
    likeComment: (data: ApiLikeCommentReq, parentId?: string, likeNum?: number, isAnsweredComment?: boolean) => void
    cancelLikeComment: (data: ApiCancelLikeCommentReq, parentId?: string, likeNum?: number, isAnsweredComment?: boolean) => void
    replyToComment: (data: ApiReplyToCommentReq) => void
    getComment: (commentId: string, isAnsweredComment?: boolean) => PrayerComment | null
    showLoading: Ref<boolean>
    finishScroll: () => void
}

function findCommentById(comments: PrayerComment[], commentId: string) {
  let target = comments.find(comment => comment.commentId === commentId)
  if (!target) {
    for (const comment of comments) {
      target = comment.subCommentList.list.find(comment => comment.commentId === commentId)
      if (target) break
    }
  }
  return target
}

export function useCommentListState(prayerIdRef: Ref<string>, commentToDisplay?: string): UseCommentListState {
  const dbAdapter = useDBAdapter<PrayerComment>(TABLE_NAME_COMMENT, {
    prayerId: prayerIdRef.value,
  })
  const commentList = reactive<PrayerComment[]>([])
  const answerCommentList = reactive<PrayerComment[]>([])
  const accountStore = useAccountStore()
  const hasMoreData = ref(false)
  const showLoading = ref(true)
  const commentIdScrollTo = ref(commentToDisplay)

  /**
     * comment list
     */
  const loadDataFromDB = async () => {
    const list = await dbAdapter.query(
      '*',
      {
        keys: 'createTime',
        order: 'DESC',
      },
      {
        offset: 0,
        limit: 10,
      }
    )
    commentList.splice(0, commentList.length, ...list)
  }

  let pageNumber = 0
  const loadMoreComments = async (event?: InfiniteScrollCustomEvent, reset?: boolean, ignoreStore?: boolean, reload?: boolean): Promise<void> => {
    pageNumber = reload ? 0 : pageNumber
    pageNumber += 1
    const params = {
      prayerId: prayerIdRef.value,
      pageNumber: pageNumber,
      pageSize: commentToDisplay ? MAX_PAGE_SIZE : 10,
    }
    const res = await apiGetCommentList(params)
    if (reset) {
      commentList.splice(0, commentList.length, ...res.list)
    } else {
      commentList.push(...res.list)
    }

    hasMoreData.value = res.hasNextPage
    await dbAdapter.upsertSet(res.list, [TABLE_PRIMARYKEY_COMMENT])
    event?.target.complete()

    if (commentIdScrollTo.value) {
      const target = findCommentById(commentList, commentIdScrollTo.value)
      if (target) {
        target.needScrollTo = true
      }
    }
  }

  const loadAnswerComments = async () => {
    const params = {
      prayerId: prayerIdRef.value,
      pageNumber: 1,
      pageSize: MAX_PAGE_SIZE,
      type: CommentType.COMMENT_TYPE_ANSWER_COMMENT,
    }
    const res = await apiGetCommentList(params)
    answerCommentList.push(...res.list)
  }

  /**
     * get comment
     */

  const getComment = (commentId: string, isAnsweredComment?: boolean): PrayerComment | null => {
    return (isAnsweredComment ? answerCommentList.find(item => item.commentId == commentId) : commentList.find(item => item.commentId == commentId)) || null
  }

  /**
     * create comment
     */
  const createComment = async (data: ApiCreateCommentReq) => {
    if (!accountStore.accountInfo.value) {
      throw new Error('need login')
    }

    const comment = {
      commentId: 'temp-comment-id',
      prayerId: prayerIdRef.value,
      avatar: accountStore.profileInfo.value?.avatar,
      name: accountStore.profileInfo.value?.name,
      uin: accountStore.accountInfo.value.uin,
      content: data.content,
      createTime: Date.now(),
      likeNum: 0,
      liked: false,
      subCommentList: {
        list: [],
      },
      type: CommentType.COMMENT_TYPE_COMMENT,
    }
    commentList.push(comment)

    const commentId = await apiCreateComment(data)
    comment.commentId = commentId
  }

  /**
     * delete comment
     */

  const deleteComment = async (data: ApiDeleteCommentReq, parentId?: string, isAnsweredComment?: boolean) => {
    if (parentId) {
      const parentComment = getComment(parentId, isAnsweredComment)
      const index = parentComment?.subCommentList.list.findIndex(item => item.commentId == data.commentId)
      if (isNumber(index) && index >= 0) {
                parentComment?.subCommentList.list.splice(index, 1)
      }
    } else {
      const list = isAnsweredComment ? answerCommentList : commentList
      const index = list.findIndex(item => item.commentId == data.commentId)
      if (index >= 0) {
        isAnsweredComment ? answerCommentList.splice(index, 1) : commentList.splice(index, 1)
      }
    }

    if (isAnsweredComment) {
      await apiDeleteAnsweredComment(data)
    }
    else {
      await apiDeleteComment(data)
    }
  }

  /**
     * comment like
     */
  function setCommentLikeTo(isLike: boolean, commentId: string, parentId?: string, likeNum?: number, isAnsweredComment?: boolean) {
    let comment
    if (parentId) {
      const parentComment = getComment(parentId, isAnsweredComment)
      comment = parentComment?.subCommentList.list.find(item => item.commentId == commentId)
    } else {
      comment = getComment(commentId, isAnsweredComment)
    }
    if (comment) {
      likeNum = likeNum ? likeNum : 0
      comment.liked = isLike
      comment.likeNum = isLike ? likeNum + 1 : likeNum - 1
    }
  }

  const likeComment = async (data: ApiLikeCommentReq, parentId?: string, likeNum?: number, isAnsweredComment?: boolean) => {
    setCommentLikeTo(true, data.commentId, parentId, likeNum, isAnsweredComment)
    await apiLikeComment(data)
  }
  const cancelLikeComment = async (data: ApiCancelLikeCommentReq, parentId?: string, likeNum?: number, isAnsweredComment?: boolean) => {
    setCommentLikeTo(false, data.commentId, parentId, likeNum, isAnsweredComment)
    await apiCancelLikeComment(data)
  }

  /**
     * reply comment
     */
  const replyToComment = async (data: ApiReplyToCommentReq) => {
    if (!accountStore.accountInfo.value) {
      throw new Error('need login')
    }

    const comment = commentList.find(item => item.commentId == data.commentId)
    if (!comment) {
      throw new Error('need refresh')
    }

    const subComment = {
      commentId: 'temp-commentid',
      prayerId: prayerIdRef.value,
      parentId: data.commentId,
      avatar: accountStore.profileInfo.value?.avatar,
      name: accountStore.profileInfo.value?.name,
      uin: accountStore.accountInfo.value.uin,
      content: data.content,
      createTime: Date.now(),
      likeNum: 0,
      liked: false,
      subCommentList: {list: []},
      type: CommentType.COMMENT_TYPE_COMMENT,
    }
    comment.subCommentList.list.push(subComment)

    const commentId = await apiCreateComment({
      content: data.content,
      parentId: data.commentId,
      prayerId: prayerIdRef.value,
    })
    subComment.commentId = commentId
  }

  const finishScroll = () => {
    commentIdScrollTo.value = ''
  }

  /**
     * init data
     */
  // watch(prayerIdRef, async () => {
  //   pageNumber = 0
  //   commentList.splice(0, commentList.length)
  //   answerCommentList.splice(0, answerCommentList.length)
  //
  // }, {
  //   immediate: true,
  // })
  onMounted(async () => {
    await loadDataFromDB()
  })

  onUnmounted(async () => {
    await dbAdapter.store()
  })
  return {
    commentList,
    answerCommentList,
    hasMoreData,
    showLoading,
    loadMoreComments,
    loadAnswerComments,
    createComment,
    deleteComment,
    likeComment,
    cancelLikeComment,
    replyToComment,
    getComment,
    finishScroll,
  }
}

export const ProvideKeyCommentListState: InjectionKey<UnwrapRef<UseCommentListState>> = Symbol('ProvideKeyCommentListState')