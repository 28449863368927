import { App, URLOpenListenerEvent } from '@capacitor/app'
import {onMounted} from 'vue'

import { useAccountStore } from '@/stores/account'
import {
  clearLaunchCommand,
  getLaunchCommand,
  getLaunchCommandFromUrl,
  handleLaunchCommand,
  storeLaunchCommand
} from '@/views/entry/launch-command/use-launch-command'


// launch from deeplink (native app)
export const setupDeeplinks = () => {
  App.addListener('appUrlOpen', async function (event: URLOpenListenerEvent) {
    const slug = event.url.split('.app').pop()
    console.log('nate-log app url open:', slug)
    if (!slug) return
    if (useAccountStore().isLoggedIn.value) {
      const commandInfo = getLaunchCommandFromUrl(slug)
      console.log('nate-log commandInfo', commandInfo)
      handleLaunchCommand(commandInfo)
    }
    else {
      storeLaunchCommand(slug)
    }
  })
}

// launch within browser
export function useLaunchCommand() {
  onMounted(async () => {
    const commandInfo = getLaunchCommand()
    if (!commandInfo) return
    handleLaunchCommand(commandInfo)
    clearLaunchCommand()
  })
}
