export const Privacy = {
  pageTitle: '隐私政策',
  time: 'Active since: March 1, 2022',
  contentList: [
    {
      title: '1. Contact',
      text: 'Unless otherwise stated in this Privacy Policy, the data controller is Be Fruitful Limited (hereinafter “Be Fruitful" or "we" or "us").\n\nIf you would like to contact us, you can reach us and our data protection officer at: support@prayrooms.com\n\nIf you have any questions or suggestions regarding data protection, please do not hesitate to contact us or our data protection officer.',
    },
    {
      title: '2. Collection, processing and use of personal data',
      text: 'Personal data is any information relating to an identified or identifiable natural person (e.g. name, address, telephone number, date of birth or e-mail address).\n' +
        '\n' +
        'When we process personal data, this means that we collect, store, use, transmit or delete data.\n' +
        '\n' +
        'PrayRooms collects and processes your personal data exclusively in the following cases:\n' +
        '•When you visit us on our website without being a member.\n' +
        '•When you contact us by direct means.\n' +
        '•When you install our PrayRooms app.\n' +
        '•When you conclude a free or paid membership with PrayRooms and subsequently use PrayRooms.\n' +
        '\n' +
        'The provision of your personal data in the context of the conclusion and existence of the contractual relationship with PrayRooms is voluntary. For the membership with PrayRooms, however, it is necessary that you provide personal data of special categories (e.g. your gender or data of sexual orientation or other personal data). Also, the use of the PrayRooms service requires that your profile information and your matching ratings to other PrayRooms members are visible to them.\n' +
        '\n' +
        'In this regard, please also note that we offer the PrayRooms service in many different countries and the information contained in your profile is visible to our members in all of these countries. You can search for your partner in any of these countries.\n' +
        '\n' +
        'If you do not want PrayRooms to process personal data or parts thereof from you, PrayRooms can not provide its service as stated in the terms and conditions and you cannot use PrayRooms.',
    },
    {
      title: '2.1 Data processing',
      text: 'If you install the PrayRooms app via an app store operator as a third-party provider (e.g. Google or Apple), you may have to conclude a usage agreement with the respective app store operator regarding access to its portal. This access requires a valid account with the app store operator as well as a corresponding end device (e.g. smartphone) and PrayRooms has no influence on the data processing in this context. The responsible party in this respect is solely the app store operator of the respective app platform. If necessary, please inform yourself directly with the app store operator (third-party provider) about its data processing. In this case, PrayRooms is only the responsible party in the context of the use of the PrayRooms app.',
    },
    {
      title: '2.2 What data does PrayRooms collect from its members to fulfill the contractual relationship?',
      text: 'In order for PrayRooms to provide the services described in more detail in the General Terms and Conditions, it is necessary to process the personal data described below, which the user (also referred to as PrayRooms member) provides, among other things, as part of the registration process, the conclusion of a basic membership or a premium membership.',
    },
    {
      title: '2.3 Registration process',
      text: 'During the registration process, which is initially free of charge (conclusion of the so-called "basic membership"), the user (or also referred to as "basic member") is requested to provide certain minimum information, without which the registration cannot be completed. These data are:\n' +
        '\n' +
        '•Gender and searched gender (the searched gender is a date of sexual orientation and therefore a special category personal data).\n' +
        '•E-mail address or phone number\n' +
        '•Password chosen by the user\n' +
        '\n' +
        'If you have chosen a login via third party providers such as Facebook or Apple, you do not provide an email address/ phone number or password within the registration process.\n' +
        'The respective access to PrayRooms then takes place via the e-mail address and password selected by the user (so-called access data) or alternatively via the login data of the third-party provider.',
    },
    {
      title: '2.4 When registering via Apple ("Register with Apple")',
      text: 'PrayRooms offers you the option to register via your Apple user account. If you make use of this, we receive the data required for registration from Apple. In this respect, a data transfer takes place. With Apple, you have the option of sharing your email address stored there with PrayRooms or hiding it. If you hide your e-mail address, we receive a unique, randomly generated e-mail address from Apple for the creation and use of your PrayRooms account. Our communications are sent by us to this email address and automatically forwarded by Apple to your email address on file with Apple, unless you have disabled PrayRooms email forwarding.\n' +
        '\n' +
        'We have no influence on the scope of the data collected by Apple by means of the Apple login. If you do not want Apple to collect data about you in connection with your use of our online service and use it for its own purposes, you should not use the Apple login.\n' +
        '\n' +
        'You can disconnect your PrayRooms account from your Apple account at any time on our website. In this case, we require the access data described above from you.\n' +
        '\n' +
        'For more information about the purpose and scope of the collection and the further processing and use of your data by Apple, as well as your rights and settings options for protecting your data, please refer to Apple\'s privacy policy: https://www.apple.com/legal/privacy/de-ww/.',
    },
    {
      title: '2.5 When registering via Facebook ("Facebook Login")',
      text: 'PrayRooms uses the "Log in with Facebook" business tool with the additional "Data Access Function". Through this, PrayRooms offers you the possibility to register via your Facebook account. If you make use of the function, we receive the following authentication data from Facebook for the registration: Your e-mail address stored there, your profile name and your profile picture. PrayRooms stores and processes only the e-mail address. In this respect, a data transfer takes place. Your Facebook profile name and profile picture are not stored or processed by us, but are automatically deleted.\n' +
        '\n' +
        'If you use the Facebook login on our website or app, Facebook will also collect data through third-party scripts, process it for Facebook Analytics purposes or pass it on to third parties. . The Facebook privacy notices explain which data can be collected by means of the Facebook login. This includes data about your device, your use of PrayRooms, the websites you have visited, and the advertisements you have viewed. Unfortunately, it is not technically possible for PrayRooms to refuse this processing. If you do not want Facebook to collect data about you in connection with your use of our online offer and use it for its own purposes, you should not use the Facebook login.\n' +
        '\n' +
        'You can disconnect your PrayRooms account from your Facebook account at any time on our website. In this case, we need the access data described above from you.\n' +
        '\n' +
        'For more information about the purpose and scope of the collection and the further processing and use of your data by Facebook, as well as your rights and settings options for protecting your data, please refer to Facebook\'s privacy policy: http://www.facebook.com/policy.php.',
    },
    {
      title: '2.6 When registering via phone number (“Cell phone login”)',
      text: 'PrayRooms offers the option of specifying the cell phone number for a so-called cell phone login. People can sign up using their cell phone number. To fully function, the users have to agree to receive text messages (SMS) from PrayRooms. A confirmation code will be sent to the cell phone number they use after the user has given consent.\n' +
        'PrayRooms may send to this phone number important messages regarding the user’s PrayRooms account and its promotions and services. PrayRooms won’t share this phone number with any third party.\n' +
        'If a user does not want to sign-up with his cell phone number, he should choose a different sign-up method on the PrayRooms app or website PrayRooms.com.',
    },
    {
      title: '2.7 Required information within the scope of a basic membership',
      text: 'In the personality test that follows registration, the following information is requested, among other things\n' +
        '\n' +
        'Place of residence \n' +
        'Date of birth\n' +
        'Name\n' +
        'Education\n' +
        'Profession\n' +
        'Profile Picture\n' +
        '\n' +
        'Furthermore, PrayRooms gives its users the option to provide additional information in order to provide better services, preferences, to its members such as personal values, preferences, appearance and other characteristics.\n' +
        'Photos can also be uploaded, and these photos are made available to other members in recognizable form in the profile next to the member\'s first name.\n' +
        '\n' +
        'All data of the PrayRooms profile is visible on app for other registered users. In particular, the first name, age, place of residence, and interests of the customer are visible.',
    },
    {
      title: '2.8 Conclusion of a premium membership',
      text: 'If the user (hereinafter also referred to as "premium member") decides to conclude a paid service of PrayRooms (so-called "premium membership") the purchase goes through third-party applications and providers (e.g. Google or Apple). The terms of use of these app platform operators can be found which personal data are processed by them in the course of the purchase process. PrayRooms does not process any payment and billing data in this respect. ',
    },
    {
      title: '2.9 Communication with members among themselves, communication with customer service',
      text: 'PrayRooms stores member communications that take place via the PrayRooms platform, as well as communications with customer service. Members\' communication with each other when logged in is encrypted.\n' +
        '\n' +
        'Please note that in principle, communication between PrayRooms and you will only take place via the e-mail address or phone number registered with PrayRooms or when you reach out to our support team to the email address you wrote from.\n',
    },
    {
      title: '2.10 Promotional Messages',
      text: 'With the conclusion of the free basic membership, you provide your e-mail address or phone number upon registration or we receive this from Apple / Facebook (in the case of registration made via this). This e-mail address or any new e-mail addresses subsequently provided by you will be used to send promotional e-mails for the free and paid products of PrayRooms, without any consent on your part being required.\n' +
        '\n' +
        'On the PrayRooms website, you also have the option of subscribing to a free newsletter by clicking on a checkbox.\n' +
        'Furthermore, during your PrayRooms membership you will receive e-mails with personalized or individualized advice, which should enable you to make the best possible use of the PrayRooms products.\n' +
        '\n' +
        'Promotional e-mails (including newsletters and guides) are transport encrypted, provided that your mail provider supports the "Transport Layer Security" (TLS) transport encryption.\n' +
        '\n' +
        'If you no longer wish to receive promotional e-mails, newsletters or individualized advice from PrayRooms, please contact our support at support@ PrayRooms.com.',
    },
    {
      title: '2.11 Push messages',
      text: 'If your mobile device allows it, you will receive so-called push messages from us when using the PrayRooms app, even if you are not currently using this app. These are messages that we send you as part of the performance of the contract, but also promotional information.\n' +
        '\n' +
        'You can adjust or prevent the receipt of push messages at any time via the device settings of your mobile device or in the PrayRooms app.',
    },
    {
      title: '2.12 What data does PrayRooms collect when using the website?',
      text: 'Each time the PrayRooms website is accessed, usage data of the respective website visitors - unless otherwise indicated below, even if they are not PrayRooms members - is transmitted by the respective Internet browser and stored in log files, so-called server log files. These data are:\n' +
        '\n' +
        '•Information about the browser type and the Internet service provider of the user and the operating system used (e.g. Windows 7, Apple OS, etc.).\n' +
        '•IP address (Internet Protocol address) of the accessing computer, tablet or smartphone (In this context, the IP address is also compared with a geo-database and the origin or the country, state and city of the user is determined. In this context, PrayRooms uses, among others, MaxMind (see explanations below)).\n' +
        '•Name of the page accessed\n' +
        '•date and time of the retrieval\n' +
        '•the referrer URL (origin URL) from which the user came to the retrieved page\n' +
        '•Amount of data transferred\n' +
        '•Status message about whether the retrieval was successful\n' +
        '•Session identification number (session ID)\n' +
        '•Session participant pseudonym\n' +
        '•User ID (profile ID) on the PrayRooms platform (only from PrayRooms members)\n' +
        '•Screen resolution used\n' +
        '\n' +
        'In the so-called login records, the user ID on the PrayRooms platform is also stored each time the PrayRooms member logs into the network.\n' +
        '\n' +
        'In addition to the previously mentioned data, cookies or pseudonymous IDs (such as user ID, ad ID, if applicable) may be stored on your terminal device during or even after your visit to our online offers when using the PrayRooms website. You can find more information in this context in our cookies & tracking instructions.',
    },
    {
      title: '2.13 What data does PrayRooms collect when using the PrayRooms app?',
      text: 'Each time the PrayRooms app is called up by the user\'s end device, PrayRooms automatically collects data and information from the operating system of the calling device. This includes, among other things, the storage of the IP address.\n' +
        '\n' +
        'Usage data\n' +
        'Each time the PrayRooms app is accessed, usage data of the respective app user is stored. These data are:\n' +
        '•The operating system used and, if applicable, the browser type.\n' +
        '•Current language setting of the smartphone\n' +
        '•Information about the user\'s Internet service provider\n' +
        '•IP address (Internet protocol address) of the accessing computer\n' +
        '•Device ID (e.g. UDID, IDFV) to identify your device(s) in the context of secure authentication\n' +
        '•User ID on the PrayRooms platform (only of PrayRooms members)\n' +
        '•Name of the page accessed and, if applicable, the page of origin\n' +
        '•Date and time of the retrieval\n' +
        '•Amount of data transferred\n' +
        '•Status message about whether the retrieval was successful\n' +
        '•In addition to the aforementioned data, tracking technologies or pseudonymous IDs (such as your advertising ID, if applicable (e.g. Apple\'s IDFA (identifier for advertisers), Google\'s advertising ID) may be retrieved from your terminal device during or even after your visit to our app when using the PrayRooms app. For more information in this context, please refer to our Cookies & Tracking Notice.\n' +
        '\n' +
        '\n' +
        'Login data\n' +
        'In the so-called login data records, the following usage data is stored each time the PrayRooms member logs into the network:\n' +
        '\n' +
        '•Date and time of login\n' +
        '•User ID on the PrayRooms platform (e-mail address)\n' +
        '•IP address (Internet protocol address)\n' +
        '•Device ID (e.g. UDID) to identify your device(s) as part of a secure authentication process (Secure Authentification).\n' +
        'In addition to the previously mentioned data, pseudonymous IDs (such as User ID, Ad ID, if applicable) may be stored on your end device during or even after your visit when using the PrayRooms app. You can find more information in this context in our cookies & tracking instructions.\n' +
        '\n' +
        '\n' +
        'GPS data when using the PrayRooms app\n' +
        'When using the app, the location data of the device is accessed at this moment (GPS, possibly WLAN information, and device ID). This information is used to determine the user\'s location so that he or she can set a suitable search radius and view members nearby. ',
    },
    {
      title: '3. Processing purposes',
      text: 'PrayRooms processes the personal data of its users for the following purposes. If the data processing is based on the legal basis of a legitimate interest, we will subsequently also explain our legitimate interest in the data processing:\n' +
        '\n' +
        'To provide the PrayRooms platform and thus to fulfill the services described in the General Terms and Conditions or, in the case of the forum, in the Terms of Use. This includes in particular:\n' +
        '\n' +
        '•Provision of our website for non-registered users;\n' +
        '•Provision and use of our website and app for registered PrayRooms members;\n' +
        '•Providing a contact option and answering inquiries via the platform;\n' +
        '•Enabling the conclusion of a contract, including invoicing;\n' +
        '•Sending emails and/or push messages with matches or other messages relevant to the contract.(Legal basis of the processing is the performance of the contract with you. Legal basis in some cases is also your consent. Legal basis is also our legal obligation and legitimate interest to provide customer service and improve the customer experience).\n' +
        '•To provide a login via the PrayRooms platform, the Facebook login, the Apple login or the PrayRooms forum. (Legal basis of the processing is the fulfillment of our contract).\n' +
        '•For abuse prevention and defense. PrayRooms collects, processes and uses personal data as well as geodata, which are collected in connection with your registration and the completion of the PrayRooms personality test and/or the PrayRooms profile, for an automated check whether there are indications or data for an abuse of PrayRooms. The data is stored in a database and compared with empirical values. If the automated processing results in a suspicion of misuse, a PrayRooms employee checks the rating and the underlying indications as well as any free text information and the stored profile photos. PrayRooms also provides its members with a function called "Report profile" and carries out checks of profiles by customer service employees in the event of contract violations reported by other members. Furthermore, PrayRooms uses the voluntary provision of the cell phone number to carry out a mobile phone-based identity comparison as part of a so-called ID check. (Legal basis is the legitimate interest of PrayRooms and the PrayRooms members that the PrayRooms service is not abused for contractual and / or illegal acts of third parties. In doing so, we also fulfill our legal obligation in the area of data security to ensure system security and the detection and tracking of unauthorized access attempts or accesses).\n' +
        '•For securing your remuneration obligations from the contractual relationship in the event of default (this only applies to the purchase of Premium Membership via PrayRooms). If you do not settle outstanding invoices / partial payments despite repeated reminders, we will transmit the data required for the implementation of a collection procedure to a collection service provider for the purpose of trustee collection. Insofar as disputed claims arise, we also transmit necessary data to our payment service providers for the assertion of our rights (e.g. in the context of conflict resolution for chargebacks). (In addition to the fulfillment of the contractual relationship, the legal basis is also PrayRooms\'s legitimate interest in the payment of the contractually agreed remuneration).\n' +
        '•For the protection and defense of our rights and the fulfillment of legal obligations. (Legal basis is our legitimate interest in asserting and defending our rights).\n' +
        '•For self-promotion through promotional emails and/or push messages, newsletters, surveys and individualized guides. \n' +
        '•For compliance with statutory retention obligations and other legal or statutory obligations and provisions (e.g. in connection with tax audits, official or court orders to provide information and other orders). (Legal basis is the fulfillment of our legal obligations).\n' +
        '•For other communication purposes in the context of inquiries. (Legal basis may be a pre-contractual legal relationship or a legal obligation).\n' +
        '•To publish your love story (success story) on the PrayRooms website and, if applicable, in other media. (Legal basis is your consent).\n' +
        '\n' +
        'The processing purposes and the legal basis for the processing of personal data using "cookies" and other tracking technologies when using PrayRooms can be found in our Cookies & Tracking Notice.',
    },
    {
      title: '4. Disclosure of data to third parties & service providers',
      text: 'As a matter of principle, PrayRooms will only pass on your personal data to third parties if this is necessary for the performance of the contract, if we or the third party have a legitimate interest in the transfer, if you have given your consent for this or if this is necessary for the fulfillment of a legal obligation. If personal data is transferred to third parties on the basis of a legitimate interest, we explain our legitimate interest in these data protection provisions.\n' +
        '\n' +
        'In addition, personal data may be transferred to third parties:\n' +
        '\n' +
        '•to service providers, insofar as the data is necessary for the execution of a contract processing agreement with us;\n' +
        '•if applicable, to providers of marketing tracking technologies and analysis tools (for more information on this, please see our Cookies & Tracking Notice);\n' +
        '•if we should be obliged to do so in individual cases due to legal requirements or by enforceable official or court order;\n' +
        '•in connection with legal disputes (vis-à-vis courts or our attorneys), debt collection cases or audits (vis-à-vis auditors);\n' +
        '•in connection with possible criminal acts to the competent investigating authorities;\n' +
        '•in the event of a sale of the business (to the purchaser).\n' +
        'Insofar as data may be transmitted to other third parties on a regular basis, this will be explained in these data protection provisions or in our cookies & tracking notes. In the case of transmission based on consent, the explanation may also be provided when consent is obtained.\n' +
        '\n' +
        'Service providers\n' +
        'PrayRooms reserves the right to use service providers for the collection or processing of personal data. Service providers receive from PrayRooms only the personal data that they need for their specific activities.\n' +
        '\n' +
        'PrayRooms uses - to the extent that service providers are not already named in this Privacy Policy or in our Cookies & Tracking Policy - service providers for sending e-mails, push messages and newsletters to members, among other things. Furthermore, service providers provide PrayRooms with server capacity. If you have purchased the PrayRooms Premium Membership through PrayRooms, external payment services and service providers support PrayRooms in processing payments and in the event of collection. PrayRooms does not collect payment information, but instead works with payment providers and third-parties. Payment service providers collect this data themselves under their own responsibility. In this respect, the privacy policy of the respective payment service provider applies.\n' +
        '\n' +
        'Service providers are usually involved as so-called order processors, which may process personal data of users of this online service. Transmitted to PrayRooms is only an order ID or equivalent.\n' +
        '\n' +
        '\n' +
        'Your personal data may also be passed on to third parties (jointly or solely with us) responsible parties, or processors, who are based in non-EU/EEA countries. In this case, we ensure before the transfer that either an adequate level of data protection exists at the recipient (e.g. based on an adequacy decision of the EU Commission for the respective country pursuant to Article 45 of the GDPR or the agreement of so-called standard contractual clauses of the European Union with the recipient pursuant to Article 46 of the GDPR using additional technical and organizational protection measures) or that there is sufficient consent from our users.',
    },
    {
      title: '5. How do we protect your data?',
      text: 'PrayRooms uses various security measures, such as state-of-the-art encryption and authentication tools, to protect the security, integrity and availability of the personal data of its customers and users. In particular, these are the following measures:\n' +
        '\n' +
        '•Strict criteria for authorization to access your data and 2-factor authentication,\n' +
        '•Storage of confidential data in encrypted form,\n' +
        '•Firewall protection of IT systems to protect against unauthorized access,\n' +
        'Permanent monitoring of access to IT systems to detect and prevent misuse of personal data.',
    },
    {
      title: '6. Storage of data',
      text: 'We store your data for as long as is necessary for the provision of our online services and the associated services or we have a legitimate interest in the continued storage. In all other cases, we will delete your personal data, with the exception of data that we must retain in order to comply with contractual or legal (e.g. tax or commercial) security periods (e.g. blacklisted users).\n' +
        '\n' +
        'We store data that is subject to a retention period until the period expires.\n' +
        '\n' +
        'The following applies to basic members: You can revoke the storage of the personal data contained in your profile at any time with effect for the future. You can also delete your profile data yourself.\n' +
        '\n' +
        'For premium members applies: The personal data of PrayRooms premium members are generally stored for the duration of the contractual relationship. If you ask us to do so, we will delete your data, unless there is a legal obligation to retain this data.\n' +
        '\n' +
        'The uninstallation of the PrayRooms app on your end device does not lead to the deletion of the data in your profile. The statements just made about the deletion of basic member or premium member data apply here.\n' +
        '\n' +
        'Log files are stored by PrayRooms for 90 days and then deleted.\n' +
        '\n' +
        'If a user has been blacklisted due to the performance of illegal activities that causes harm to our users, we will store that data for up to 5 years to protect our members and prevent the person from further sign-ups in the future.',
    },
    {
      title: '7. Cookies & Tracking',
      text: 'This online service uses cookies. In addition to the description of the individual cookies, we have also listed the purpose of use and other important information at this point.\n' +
        'Cookies are small text files that are sent when you visit a website and stored in the user\'s browser. If the corresponding website is called up again, the user\'s browser sends back the content of the cookies and thus enables the user to be recognized. Certain cookies are automatically deleted after the end of the browser session (so-called session cookies), others are stored in the user\'s browser for a specified time or permanently and then delete themselves (so-called temporary or permanent cookies).\n' +
        'In principle, no personal data is stored in cookies, it’s only an online identifier.\n' +
        '\n' +
        'What cookies does PrayRooms use?\n' +
        '•Strictly necessary cookies\n' +
        '•Certain cookies are required so that we can provide our online offer safely and in a user-friendly manner.\n' +
        '•Cookies that serve to identify or authenticate our users;\n' +
        '•Cookies that temporarily save certain user inputs;\n' +
        '•Cookies that save certain user preferences.\n' +
        '\n' +
        'Analysis cookies\n' +
        'We use analysis cookies to record the usage behavior (e.g. visited sub-pages) of our users and to be able to evaluate them in statistical form.\n' +
        '\n' +
        'Third-party advertising cookies / Third-party pixels / Third-party tracking\n' +
        'We allow other companies to collect data from our users using advertising cookies or pixels. This enables us and third parties to display interest-based advertising to the users of our online offering, which is based on an analysis of their usage behavior (e.g. clicked advertising banners, visited sub-pages) overall and not limited to our online offering.\n' +
        '\n' +
        'How can you delete cookies?\n' +
        'You can deactivate the storage of cookies via your browser settings and delete cookies that have already been saved at any time in your browser (see technical information). Please note, however, that this online offer may not work or only function to a limited extent without cookies.\n' +
        'We also recommend the website of www.YourOnlineChoices.com, where you can find information and assistance in connection with web tracking mechanisms.This online service uses cookies. In addition to the description of the individual cookies, we have also listed the purpose of use and other important information at this point.\n' +
        'Cookies are small text files that are sent when you visit a website and stored in the user\'s browser. If the corresponding website is called up again, the user\'s browser sends back the content of the cookies and thus enables the user to be recognized. Certain cookies are automatically deleted after the end of the browser session (so-called session cookies), others are stored in the user\'s browser for a specified time or permanently and then delete themselves (so-called temporary or permanent cookies).\n' +
        'In principle, no personal data is stored in cookies, it’s only an online identifier.\n' +
        '\n' +
        'What cookies does PrayRooms use?\n' +
        '•Strictly necessary cookies\n' +
        '•Certain cookies are required so that we can provide our online offer safely and in a user-friendly manner.\n' +
        '•Cookies that serve to identify or authenticate our users;\n' +
        '•Cookies that temporarily save certain user inputs;\n' +
        '•Cookies that save certain user preferences.\n' +
        '\n' +
        'Analysis cookies\n' +
        'We use analysis cookies to record the usage behavior (e.g. visited sub-pages) of our users and to be able to evaluate them in statistical form.\n' +
        '\n' +
        'Third-party advertising cookies / Third-party pixels / Third-party tracking\n' +
        'We allow other companies to collect data from our users using advertising cookies or pixels. This enables us and third parties to display interest-based advertising to the users of our online offering, which is based on an analysis of their usage behavior (e.g. clicked advertising banners, visited sub-pages) overall and not limited to our online offering.\n' +
        '\n' +
        'How can you delete cookies?\n' +
        'You can deactivate the storage of cookies via your browser settings and delete cookies that have already been saved at any time in your browser (see technical information). Please note, however, that this online offer may not work or only function to a limited extent without cookies.\n' +
        'We also recommend the website of www.YourOnlineChoices.com, where you can find information and assistance in connection with web tracking mechanisms.',
    },
    {
      title: '8. Right of information & deletion',
      text: 'A.\n' +
        'You have the right to request information from PrayRooms as to whether we are processing personal data relating to you. In addition, you also have the right to information about this personal data as well as information about the category of data that is stored, the purpose of processing, any recipients, the storage period and the data subject rights to which you are entitled. If your data is incorrect or incomplete, you may request that your data be corrected or completed. If we have passed on your data to third parties, we will inform them of the correction, insofar as this is required by law.\n' +
        '\n' +
        'You can assert your right to information here: support@prayrooms.com\n' +
        '\n' +
        'B.\n' +
        'Your right to deletion\n' +
        '\n' +
        'You may, if the legal requirements are met, request that we delete your personal data without delay. This is particularly the case if\n' +
        '\n' +
        '•Your personal data is no longer required for the purposes for which it was collected;\n' +
        '•the legal basis for the processing was exclusively your consent and you have revoked it;\n' +
        '•you have objected to processing for advertising purposes ("advertising objection");\n' +
        '•you have objected to processing on the legal basis of weighing interests on personal grounds and we cannot demonstrate that there are overriding legitimate grounds for processing;\n' +
        '•your personal data has been processed unlawfully; or\n' +
        '•Your personal data must be erased to comply with legal requirements.\n' +
        '\n' +
        'Please note that your right to erasure is subject to limitations. For example, we do not have to or are not allowed to delete data that we still need to retain due to legal retention periods. Also, data that we need for the assertion, exercise or defense of legal claims are excluded from your right of deletion.\n' +
        '\n' +
        'You can also delete the data of your PrayRooms Basic membership in your profile or your profile itself. Alternatively, you can contact customer service and request the deletion of your data.',
    },
    {
      title: '9. Changes to the Privacy Policy',
      text: 'We may update this Privacy Policy. We will place any updates to this policy on this website. You are bound by any changes to the Privacy Policy by using PrayRooms services.\n' +
        '\n' +
        'To receive any additional information regarding the privacy policy please contact PrayRooms at support@prayrooms.com.',
    },
  ],

}
