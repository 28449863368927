import { modalController } from '@ionic/vue'

import { apiGetPrayerByToken } from '@/apis/prayer'
import { LaunchCommand, PrayerShareParams } from '@/views/entry/launch-command/definitions'
import PrayerShare from '@/views/prayer/prayer-share/PrayerShare.vue'

async function getPrayerDetail(token: string) {
  if (!token) return
  const res = await apiGetPrayerByToken({shareToken: token})
  return res.prayer
}

export async function usePrayerShare(launchCommand: LaunchCommand<PrayerShareParams>) {
  const prayer = await getPrayerDetail(launchCommand.launchParams.token)
  const modal = await modalController
    .create({
      component: PrayerShare,
      componentProps: {
        prayer,
      },
    })
  return modal.present()
}