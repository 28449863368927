import dayjs from 'dayjs'
import {computed, reactive, watchEffect} from 'vue'

import {apiEditReminder} from '@/apis/prayer'
import {TimingTaskLoopType} from '@/protobuf/pg'
import {reminderTypeMap} from '@/protobuf/pg-map'
import {ReminderInfo} from '@/stores/database/database-schema-v1'
import {Reactive} from '@/typings/commen'

interface Props {
  reminder?: ReminderInfo
  prayerId?: string
}

export function useReminderState(props: Reactive<Props>) {
  // 初始化 reminder 数据
  const reminder = reactive<ReminderInfo>({
    nextReminderTime: Date.now(),
    remindType: TimingTaskLoopType.WEEKDAY,
  })

  // props 数据更新，局部 reminder 数据同步更新
  watchEffect(() => {
    if (props.reminder) {
      Object.assign(reminder, props.reminder)
    }
  })

  const reminderDisplay = computed(() => {
    if (reminder.remindType === undefined || !reminder.nextReminderTime) return '--'
    const time = dayjs(reminder.nextReminderTime).format('YYYY/MM/DD HH:mm')
    return `${time} ${reminderTypeMap.get(reminder.remindType)}`
  })

  const reminderTimeLabel = computed(() => {
    if (!reminder.nextReminderTime) return '--'
    return dayjs(reminder.nextReminderTime).format('YYYY/MM/DD HH:mm')
  })

  const remindTypeLabel = computed(() => {
    return reminderTypeMap.get(reminder.remindType) || '--'
  })

  const fixNextReminderTime = () => {
    // 工作日, 如果选择的日期不在工作日之内, 修正为下一个工作日
    if (reminder.remindType !== TimingTaskLoopType.WEEKDAY) return
    let newDayjsObj = dayjs(reminder.nextReminderTime)
    const SUNDAY = 0
    const SATURDAY = 6

    switch (newDayjsObj.day()) {
    case SATURDAY:
      newDayjsObj = newDayjsObj.add(2, 'day')
      break
    case SUNDAY:
      newDayjsObj = newDayjsObj.add(1, 'day')
      break
    default:
      break
    }
    reminder.nextReminderTime = newDayjsObj.valueOf()
  }

  /**
   * 编辑祷告场景下, 存在 prayerId 数据, 保存提醒信息到祷告中
   * 否则退出
   */
  const saveIfNecessary = async () => {
    if (!props.prayerId) return
    const params = {
      prayerId: props.prayerId,
      remindType: reminder.remindType,
      startTime: reminder.nextReminderTime,
    }
    await apiEditReminder(params)
  }

  const shouldSaveReminder = computed(() => {
    return !!props.prayerId
  })

  /**
   * remove reminder
   */
  const rollback = () => {
    Object.assign(reminder, props.reminder)
  }

  return {
    reminder,
    reminderDisplay,
    reminderTimeLabel,
    remindTypeLabel,
    saveIfNecessary,
    shouldSaveReminder,
    rollback,
    fixNextReminderTime,
  }
}
