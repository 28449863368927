import {AccountDelete} from '@/i18n/model/zh/page-content/account-delete'
import {AccountInfo} from '@/i18n/model/zh/page-content/account-info'
import {AppSetting} from '@/i18n/model/zh/page-content/app-setting'
import {ChangeUserNickname} from '@/i18n/model/zh/page-content/change-user-nickname'
import {CompleteUserInfo} from '@/i18n/model/zh/page-content/complete-user-info'
import {EmailValidate} from '@/i18n/model/zh/page-content/email-validate'
import {Home} from '@/i18n/model/zh/page-content/home'
import {NoticeCenter} from '@/i18n/model/zh/page-content/notice-center'
import {PersonCenter} from '@/i18n/model/zh/page-content/person-center'
import {Prayer} from '@/i18n/model/zh/page-content/prayer'
import {PrayerGroup} from '@/i18n/model/zh/page-content/prayer-group'
import {Privacy} from '@/i18n/model/zh/page-content/privacy'
import {SignIn} from '@/i18n/model/zh/page-content/sign-in'
import {SignUp} from '@/i18n/model/zh/page-content/sign-up'
import {Start} from '@/i18n/model/zh/page-content/start'
import {Terms} from '@/i18n/model/zh/page-content/terms'


export const PageContent = {
  accountInfo: AccountInfo,
  accountDelete: AccountDelete,
  appSetting: AppSetting,
  changeUserNickname: ChangeUserNickname,
  completeUserInfo: CompleteUserInfo,
  emailValidate: EmailValidate,
  home: Home,
  noticeCenter: NoticeCenter,
  personCenter: PersonCenter,
  prayer: Prayer,
  prayerGroup: PrayerGroup,
  privacy: Privacy,
  signUp: SignUp,
  signIn: SignIn,
  start: Start,
  terms: Terms,
}
