import {computed} from 'vue'

import defaultSvg from '@/assets/svgs/shielding-reminder.svg'
import {useI18n} from '@/i18n/use-i18n'
import {Notice} from '@/stores/database/database-schema-v1'
import {Reactive} from '@/typings/commen'
import {UseNoticeReturn} from '@/uses/notice-processor/type.d'
import {getTimeTips} from '@/utils/util'
import PrayerDetail from '@/views/prayer/prayer-detail/PrayerDetail.vue'

const {t} = useI18n()

export function useNoticePublicAnsweredFailed(notice: Reactive<Notice>): UseNoticeReturn {
  const onClick = async () => {
    const ionNav = document.querySelector('ion-nav') as any
    ionNav.push(PrayerDetail, {
      prayerId: notice.prayerId,
      groupId: notice.groupId,
    })
  }

  const componentData = computed(() => {
    return {
      url: notice.groupImage,
      icon: defaultSvg,
      title: t('pageContent.noticeCenter.prayerPublicAnsweredFailed'),
      description: `${notice.prayerTitle}`,
      time: getTimeTips(notice.stamp),
      isReaded: !!notice.isRead,
    }
  })

  return {
    componentData,
    onClick,
  }
}