/* Set sqlite */
import {IonicVue, isPlatform} from '@ionic/vue'
import {createApp} from 'vue'

// 后期的数据迁移大致应该会有 2 种方案：
// 1. 进入 app 后后台静默迁移
// 2. 在 某个页面 (如: Splash Screen) 中升级
// import {run} from '@/stores/autoMigrator'
// run()
import App from './App.vue'
import {i18n} from './i18n'

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import {loadingDirective} from '@/directive/v-loading'
// eslint-disable-next-line import/order
import {databaseInit} from '@/stores/database'

/* Core CSS required for Ionic components to work properly aabc */
import '@ionic/vue/css/core.css'
import '../src/assets/fonts/fonts.css'
import '../src/assets/iconfont/iconfont.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/common.css'

import './registerServiceWorker'

import {sentryInit} from '@/utils/sentry'
import {setupDeeplinks} from '@/views/entry/launch-command'
import {storeLaunchCommand} from '@/views/entry/launch-command/use-launch-command'


if (isPlatform('hybrid')) {
  // deeplinks: 点击链接 https://befruitful.goodnewslabs.com/scheme
  // 进入 app 后, window.location.href = localhost, 没有参数, storeLaunchCommand 没有用
  setupDeeplinks()
} else {
  storeLaunchCommand(window.location.href)
}


window.addEventListener('DOMContentLoaded', async () => {
  // 数据库初始化
  await databaseInit()

  const app = createApp(App)
    .use(i18n)
    .use(IonicVue, {
      mode: 'ios',
    })
  app.directive('loading', loadingDirective)
  sentryInit(app)
  app.mount('#app')

})