import { InjectionKey } from 'vue'

export interface Callback {
  (validateMessage: string): void
}

export interface InputContext {
  prop?: string
  $el: HTMLDivElement | undefined
  validate(trigger: string, callback?: Callback): void
}

export interface FormContext {
  addField: (field: InputContext) => void
  removeField: (field: InputContext) => void
}


export const hxxFormKey: InjectionKey<FormContext> = Symbol('elForm')
