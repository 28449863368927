import {Contacts} from '@capacitor-community/contacts'
import {Capacitor} from '@capacitor/core'
import {InputCustomEvent, toastController} from '@ionic/vue'
import {computed, onMounted, reactive, ref} from 'vue'

import {sendPrayerGroupInvitationEmailApi} from '@/apis/prayer-group'
import {useI18n} from '@/i18n/use-i18n'

const {t} = useI18n()

export interface SharingTarget {
    name?: string
    email: string
    invited?: boolean
    timeOut?: number
}

export function useContactBook(props: { invitationUrl: string }) {
  const contactList = reactive<SharingTarget[]>([])
  const searchText = ref('')
  const permissionDenied = ref(true)
  const emailByUserInput = ref('')

  const getContactList = async () => {
    try {
      const {granted} = await Contacts.getPermissions()
      if (granted) {
        permissionDenied.value = false
        const result = await Contacts.getContacts()
        for (const contact of result.contacts) {
          const list = contact.emails
            .filter(email => !!email.address)
            .map(email => {
              return {
                name: contact.displayName?.trim(),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                email: email.address!,
              }
            })
          contactList.push(...list)
        }
      }
    } catch (e) {
      console.log(e)
    }

  }


  const sendInvitationUrl = async (index: number) => {
    const target = contactList[index]
    target && (target.invited = true)

    try {
      await sendPrayerGroupInvitationEmailApi({
        email: target.email,
        invitationUrl: props.invitationUrl,
      })
      target && (target.timeOut = 60)
      const timer = setInterval(() => {
        if (target && target.timeOut && target.timeOut !== 0) {
          target.timeOut -= 1
        } else {
          target && (target.invited = false)
          clearInterval(timer)
        }
      }, 1000)
    } catch (e) {
      target && (target.timeOut = 0)
      target && (target.invited = false)
      throw e
    }

    const toast = await toastController.create({
      message: t('toast.groupInviteSuccessful', {email: target.name || target.email}),
      duration: 1500,
    })
    await toast.present()
  }

  const sendInvitationUrlWithEmail = async (email: string, url: string) => {
    await sendPrayerGroupInvitationEmailApi({
      email,
      invitationUrl: url,
    })

    const toast = await toastController.create({
      message: t('toast.groupInviteSuccessful', {email}),
      duration: 1500,
    })
    await toast.present()
  }

  const searchedList = computed(() => {
    return contactList.filter(item => item.name?.includes(searchText.value))
  })

  const setSearchText = (event: InputCustomEvent) => {
    searchText.value = event.detail.value || ''
  }

  onMounted(async () => {
    const platform = Capacitor.getPlatform()
    if (platform !== 'web') {
      await getContactList()
    }

  })

  return {
    contactList: searchedList,
    permissionDenied,
    emailByUserInput,
    sendInvitationUrl,
    sendInvitationUrlWithEmail,
    setSearchText,
  }
}
