import {ViewController} from '@ionic/core'
import {toastController} from '@ionic/vue'
import {stringify} from 'qs'

import {CreateAxios, CreateAxiosOptions} from './createAxios'

import {i18n} from '@/i18n'
import {ErrorCode} from '@/protobuf/pg'
import {useAccountStore} from '@/stores/account'
import {clearAllDataInDB} from '@/stores/database'
import {useNav} from '@/use-nav'
import Start from '@/views/entry/start/Start.vue'

const {token, clear: accountClear} = useAccountStore()
const options: CreateAxiosOptions = {
  // 携带token、get请求params数组处理
  interceptorsRequestSuccessFunc: (config) => {
    const headers: any = {}
    if (token.value) {
      headers.Authorization = 'Bearer ' + token.value
    }
    return {
      ...config,
      headers,
      //形式： ids=1&ids=2&id=3
      paramsSerializer: (params) => stringify(params, {arrayFormat: 'repeat'}),
    }
  },
  /**
     * 业务代码处理
     * @param response
     */
  responseHandleFunc: async (response) => {
    const {data} = response
    const ionNav = useNav()
    let message = ''
    switch (data.code) {
    case ErrorCode.SUCCESS:
      return data.data
    case ErrorCode.TOKEN_REDIS_NOT_FOUND:
    case ErrorCode.TOKEN_INVALID:
    case ErrorCode.NO_TOKEN:
      ionNav.getActive().then((viewController: ViewController | undefined) => {
        if (!viewController || !/SignIn.vue$/.test(viewController.component['__file']) && token.value) {
          // 清理数据
          accountClear()
          // fixme pushToRoot 会导致当前页面生命周期onmount执行两次
          ionNav.push(Start)
        }
      })
      // 登录态丢失, 把本地数据库的数据清理掉
      // 偷懒的解决方案, 为了解决更换账号后, 旧数据遗留影响到新账号的数据展示
      clearAllDataInDB()
      break
    default:
      message = i18n.global.t(`errorCode.${data.code}`)
      break
    }
    if (message) {
      const toast = await toastController
        .create({
          color: 'warning',
          duration: 2000,
          message: data.message,
        })
      await toast.present()
    }

    if (data.code !== ErrorCode.SUCCESS){
      throw new Error(JSON.stringify(data))
    }
  },
  /**
     * 请求状态码处理
     */
  errorHandleFunc: (async (error: any) => {
    const {status, code} = error
    let message = error.message
    // 处理请求超时
    if (!status && code === 'ECONNABORTED') {
      message = 'timeout error'
    }

    if (message) {
      const toast = await toastController
        .create({
          color: 'warning',
          duration: 2000,
          message,
        })
      await toast.present()
    }
  }),
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 8000,
}

export const http = new CreateAxios(options)
