import {BrowserTracing} from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import _ from 'lodash'

import * as preventReport from './prevent-error-report'

import {useAccountStore} from '@/stores/account'

const {accountInfo} = useAccountStore()
const isProduction = process.env.VUE_APP_IS_PRODUCTION
const evnCode = {
  DEVELOPMENT: '0',
  PRODUCTION: '1',
}
const SentryEnv = new Map([
  [evnCode.DEVELOPMENT, 'development'],
  [evnCode.PRODUCTION, 'production'],
])

export const sentryInit = (app: any) => {
  if (isProduction === evnCode.PRODUCTION) {
    Sentry.init({
      app,
      dsn: 'https://5553a4c1cfa049deaa7ef4cab5f362ef@o1171066.ingest.sentry.io/6265131',
      integrations: [
        new BrowserTracing({
          // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          // tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
        }),
      ],
      tracingOptions: {
        trackComponents: true,
      },
      // 在移动端测试发现，每次跳转页面都会上报两次 session，增加了请求数并且当前没有实际用处，所以禁止 session 上报
      autoSessionTracking: false,
      // sendDefaultPii: true,
      environment: SentryEnv.get(isProduction),
      release: `${process.env.VUE_APP_VERSION}`,
      attachStacktrace: true,
      // If you set it to false, Sentry will suppress sending all Vue components' props for logging
      attachProps: true,
      // Please note that if you enable this integration, by default Vue will not call its `logError` internally.
      // This means that errors occurring in the Vue renderer will not show up in the developer console.
      // If you want to preserve this functionality, make sure to pass the `logErrors: true` option.
      logErrors: true,
      ignoreErrors: preventReport.ignoreErrors,
      initialScope: {
        user: {uin: accountInfo.value?.uin, email: accountInfo.value?.email},
      },
      beforeSend(event, hint) {
        // 过滤不发送的错误信息
        if (preventReport.shouldIgnoreReport(hint)) {
          console.info('ignore error report', hint)
          return null
        }
        return event
      },
    })
  } else {
    console.info('开发环境，已禁用 Sentry')
  }
}