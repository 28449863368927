import {Button} from '@/i18n/model/zh/button'
import {ErrorTips} from '@/i18n/model/zh/error-tips'
import {Input} from '@/i18n/model/zh/input'
import {Loading} from '@/i18n/model/zh/loading'
import {PageContent} from '@/i18n/model/zh/page-content/page-content'
import {Toast} from '@/i18n/model/zh/toast'

export const zh = {
  button: Button,
  input: Input,
  loading: Loading,
  pageContent: PageContent,
  toast: Toast,
  errorCode: ErrorTips,
}
