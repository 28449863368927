/** AccountState enum. */
export enum AccountState {
    INITIAL = 0,
    VERIFIED = 1,
    NORMAL = 2,
    DISABLE = 3,
    DEACTIVATE = 4,
    LATTER = 5
}

/** PrayerState enum. */
export enum PrayerState {
    PRAYER_NORMAL = 0,
    PRAYER_LOCK = 1,
    PRAYER_DEACTIVATE = 2,
    PRAYER_ANSWERED = 3
}

/** AvatarVerifyStatus enum. */
export enum AvatarVerifyStatus {
    AUTO_PASS = 0,
    AUTO_FAIL = 1,
    MANUAL_PASS = 10,
    MANUAL_FAIL = 11,
    WAITING = 20
}

/** Devices enum. */
export enum Devices {
    ANDROID = 0,
    IOS = 1,
    WEB = 2
}

/** GroupState enum. */
export enum GroupState {
    GROUP_NORMAL = 0,
    GROUP_DEACTIVATE = 1
}

/** GroupType enum. */
export enum GroupType {
    GROUP_TYPE_NORMAL = 0,
    GROUP_TYPE_PUBLIC = 1,
    GROUP_TYPE_PRIVATE = 2
}

/** GroupAccountRole enum. */
export enum GroupAccountRole {
    GROUP_ROLE_MANAGER = 0,
    GROUP_ROLE_NORMAL = 1
}

/** CommentType enum. */
export enum CommentType {
    COMMENT_TYPE_COMMENT = 0,
    COMMENT_TYPE_LIKE = 1,
    COMMENT_TYPE_ANSWER_COMMENT = 2
}

/** CommentState enum. */
export enum CommentState {
    COMMENT_STATE_NORMAL = 0,
    COMMENT_STATE_DELETE = 1,
    COMMENT_STATE_BLOCK = 2
}

/** NoticeReadState enum. */
export enum NoticeReadState {
    NOTICE_READ_STATE_UNREAD = 0,
    NOTICE_READ_STATE_READ = 1
}

/** NoticeType enum. */
export enum NoticeType {
    NOTICE_TYPE_LIKE = 0,
    NOTICE_TYPE_COMMENT = 1,
    NOTICE_TYPE_REPLY_LIKE = 2,
    NOTICE_TYPE_REPLY_COMMENT = 3,
    NOTICE_TYPE_PRAYER_REMINDER = 4,
    NOTICE_TYPE_PRAYER_BANNED = 5,
    NOTICE_TYPE_GROUP_DISSOLVE = 6,
    NOTICE_TYPE_COMMENT_BANNED = 7,
    NOTICE_TYPE_USER_BANNED = 8,
    NOTICE_TYPE_PUBLIC_ANSWERED_FAIL = 9,
    NOTICE_TYPE_GROUP_JOIN = 10
}

/** StatisticType enum. */
export enum StatisticType {
    STATISTIC_TYPE_PRAYER_PRAYED_NUM = 0
}

/** OssOpLogTypeEnum enum. */
export enum OssOpLogTypeEnum {
    OSS_OP_LOG_TYPE_LOGIN = 0,
    OSS_OP_LOG_TYPE_LOGOUT = 1,
    OSS_OP_LOG_TYPE_CHANGE_REPORT_STATE = 2,
    OSS_OP_LOG_TYPE_CHANGE_USER_STATE = 3,
    OSS_OP_LOG_TYPE_CHANGE_USER_RESOURCE_STATE = 4,
    OSS_OP_LOG_TYPE_CHANGE_PUBLIC_ANSWERED_STATE = 5
}

/** ReportState enum. */
export enum ReportState {
    REPORT_UNDONE = 0,
    REPORT_DONE = 1
}

/** ReportDimension enum. */
export enum ReportDimension {
    REPORT_RESOURCE = 0, // 祷告
    REPORT_USER = 1, // 用户
}

/** TimingTaskLoopType enum. */
export enum TimingTaskLoopType {
    EVERY_DAY = 0,
    WEEKDAY = 1,
    EVER_WEEK = 2,
    EVER_MOUTH_DATE = 3,
    EVER_MOUTH_WEEK = 4,
    EVER_YEAR = 5,
    THREE_MINUTES = 6,
    NEVER = 7
}

/** TimingTaskToDoType enum. */
export enum TimingTaskToDoType {
    REMIND_PRAYER = 0
}

/** TimingTaskState enum. */
export enum TimingTaskState {
    TIMING_TASK_STATE_NORMAL = 0,
    TIMING_TASK_STATE_DEACTIVATE = 1
}

/** IsReported enum. */
export enum IsReported {
    NOT_REPORT = 0,
    REPORTED = 1
}

/** ResourceType enum. */
export enum ResourceType {
    COMMENT = 0,
    PRAYER = 1
}

/** ReviewResult enum. */
export enum ReviewResult {
    REVIEW_PASS = 0,
    REVIEW_PASS_FAIL = 1
}

/** ReviewState enum. */
export enum ReviewState {
    REVIEW_PENDING = 0,
    REVIEW_SOLVED = 1
}

/** ReviewType enum. */
export enum ReviewType {
    REVIEW_PUBLIC_ANSWERED = 0
}

/** ReadRecordType enum. */
export enum ReadRecordType {
    READ_RECORD_TYPE_GROUP_PRAYER = 0,
    READ_RECORD_TYPE_GROUP_COMMENT = 1,
    READ_RECORD_TYPE_PRAYER_OWN_COMMENT = 2,
    READ_RECORD_TYPE_OWN_NEW_ANSWERED = 3
}

/** SettingState enum. */
export enum SettingState {
    SETTING_CLOSE = 0,
    SETTING_OPEN = 1
}

/** ErrorCode enum. */
export enum ErrorCode {
    SUCCESS = 0,
    FAIL = 1,
    UNKNOWN_ERROR = 2,
    PARAMS_ERROR = 3,
    NO_ACCOUNT = 4,
    NOT_LOGIN = 5,
    NOT_FOUND_IN_DB = 6,
    INTERNAL_ERROR = 7,
    NO_TOKEN = 8,
    TOKEN_INVALID = 9,
    TOKEN_REDIS_NOT_FOUND = 10,
    TOKEN_EXPIRED = 11,
    LOGIN_FAIL = 1001,
    EMAIL_ERROR = 1002,
    VERIFYCODE_ERROR = 1003,
    ACCOUNT_DISABLED = 1004,
    PERMISSION_TOKEN_EXPIRED = 1005,
    ACCOUNT_EXIST = 1006,
    PASSWORD_ERROR = 1007,
    ACCOUNT_VERIFIED = 1008,
    ACCOUNT_VERIFY_ERROR = 1009,
    ACCOUNT_DELETED = 1010,
    IMAGE_AUTO_VERIFY_OVERDUE = 2001,
    IMAGE_INDEX_INVALID = 2002,
    DB_INSERT_PROFILE = 2003,
    DB_UPDATE_PROFILE = 2004,
    DB_PROFILE_NOT_FOUND = 2005,
    AMAZON_S3_UPLOAD = 3001,
    SEND_EMAIL_ERROR = 4001,
    NO_EDIT_PRAYER_PERMISSION = 5001,
    EDIT_PRAYER_ERROR = 5002,
    DELETE_PRAYER_ERROR = 5003,
    PUBLIC_PRAYER_ERROR = 5004,
    PRAYER_NOT_IN_GROUP = 5005,
    NO_GET_PRAYER_PERMISSION = 5006,
    PRAYER_NOT_EXIST = 5007,
    DB_INSERT_COMMENT = 6001,
    DB_UPDATE_COMMENT = 6002,
    DB_GET_COMMENT = 6003,
    DB_NO_COMMENT = 6004,
    REPEAT_LIKE = 6005,
    GROUP_EDIT_NOT_FOUND = 7001,
    DB_UPDATE_GROUP = 7002,
    DELETE_GROUP_ERROR = 7003,
    NOT_FOUND_GROUP = 7004,
    USER_ALREADY_IN_GROUP = 7005,
    USER_IN_GROUP_FAIL = 7006,
    NOT_GROUP_MANAGER = 7007,
    GROUP_REMOVE_USER_FAIL = 7008,
    USER_GROUP_NOT_EXIST = 7009,
    CAN_NOT_REMOVE_OWN = 7010,
    NOT_FOUND_OWN_GROUP = 7011,
    CREATORS_CANNOT_REMOVE_THEMSELVES = 7012,
    DB_UPDATE_SETTING = 8001,
    DB_INSERT_REPORT = 9001,
    DB_REPORT_NOT_FOUND = 9002,
    BLOCK_USER_ERROR = 9003,
    DB_UPDATE_ERROR = 9004,
    DB_NO_NOTICE = 10000,
    DB_GET_NOTICE = 10001,
    DB_UPDATE_NOTICE = 10002,
    DB_CLEAR_UNREAD_NOTICE = 10003,
    DB_NO_TIMING_TASK = 11000
}
