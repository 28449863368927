import {computed} from 'vue'

import defaultSvg from '@/assets/svgs/shielding-reminder.svg'
import {useI18n} from '@/i18n/use-i18n'
import {Notice} from '@/stores/database/database-schema-v1'
import {Reactive} from '@/typings/commen'
import {UseNoticeReturn} from '@/uses/notice-processor/type.d'
import {getTimeTips} from '@/utils/util'
const {t} = useI18n()

export function useNoticeCommentBanned(notice: Reactive<Notice>): UseNoticeReturn {

  const componentData = computed(() => {
    return {
      url: '',
      icon: defaultSvg,
      title: `${t('pageContent.noticeCenter.prayerBanned',{title:notice.commentContent})}`,
      description: `${t('pageContent.noticeCenter.prayerBannedDes')}`,
      time: getTimeTips(notice.stamp),
      isReaded: !!notice.isRead,
    }
  })

  return {
    componentData,
    onClick: null,
  }
}